import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { TOGGLE_TERMINAL } from '../pages/PosScreen/mutation'
import { CURRENT_TERMINAL } from '../pages/PosScreen/queries'
import { QUERY_TERMINAL } from '../pages/queries'


const SelectTerminal = () => {

  const { data: terminalData } = useQuery(QUERY_TERMINAL, {
    variables: {
      first: 100,
      offset: 0,
      name_Icontains: ""
    },
    fetchPolicy: "network-only"
  })

  const { data: currentTerminaldata } = useQuery(CURRENT_TERMINAL);


  const [terminalValue, setTerminalValue] = useState<string>("DEFAULT");

  useEffect(() => {
    setTerminalValue(currentTerminaldata?.currentTerminal?.terminal?.pk)
    return (() => {
      setTerminalValue("")
    })
  }, [currentTerminaldata])


  const [toggleTerminal] = useMutation(TOGGLE_TERMINAL);


  const handleTerminalChange = async (e: any) => {
    setTerminalValue(e.target.value)
    const response = await toggleTerminal({
      variables: {
        terminalId: e.target.value
      }
    })
    if (response.data?.toggleTerminal?.success) {
      toast.success(response.data?.toggleTerminal?.message)
    }
    else {
      toast.error(response.data?.toggleTerminal?.message)
    }
  }


  return (
    <div className=" w-full mr-2">
      {terminalData &&
        <select
          className="block w-full h-[32px] bg-[#FFFFFF2D] px-4 py-2 m-1 text-center rounded-[20px] border-transparent text-white placeholder-white focus:outline-none focus:placeholder-white focus:ring-0 focus:border-transparent sm:text-sm"
          value={terminalValue ?? "DEFAULT"}
          onChange={(e) => handleTerminalChange(e)}
        >
          <option disabled={true} value={"DEFAULT"}>
            Select Terminal
          </option>
          {terminalData?.terminals?.edges?.map(
            (item: any, index: number) => (
              <React.Fragment key={index}>
                <option className="bg-[#FFFFFF2D] text-gray-600 p-2" value={item?.node?.pk}>{item?.node?.name}</option>
              </React.Fragment>
            ),
          )}
        </select>
      }
    </div>
  )
}

export default SelectTerminal