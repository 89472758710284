import { useMutation, useReactiveVar } from "@apollo/client";
import React, { useState } from "react";
import {
  cache,
  cartItemsVar,
  disableFocusCache,
  disableFocusVar,
  searchCache,
  searchItemsVar,
  searchSelectedItemsVar,
} from "../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_DISABLE_FOCUS,
  GET_SEARCH_ITEMS,
} from "../../localstate/Cart";
import { toast } from "react-toastify";
import Modal from "../../reusable/Modal";
import PosScreenProWithModCat from "../PosScreenProWithModCat";
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";
import WeightableProductQuantityForm from "./WeightableProductQuantityForm";
import { POS_SCREEN_PRODUCT } from "../../../pages/Product/queries";
import { getLocalKey, setLocalKey } from "../../../helpers/sessionKey";
import OpenPriceProductPriceForm from "./OpenPriceProductPriceForm";
import { POS_SCREEN_CREATE_PRODUCT } from "../../../pages/Product/mutation";
import PosScreenProductForm from "./PosScreenCartSection/PosScreenTableSection/PosScreenProductForm";
import { useForm } from "react-hook-form";
import TextField from "../../reusable/TextField";
import CheckBoxField from "../../reusable/CheckBoxField";
import MultipleSelectField from "../../reusable/MultipleSelectField";
import DynamicDropDown from "../../reusable/DynamicDropDown";

const PosScreenSearchProductTab = (props: any) => {
  const {
    searchProductData,
    categoryDropdown,
    taxTypeDropdown,
    unitDropdown,
    printerListsDropdown,
    modifierCategoryDropdown,
    setPromProducts,
    setPromotionInfo,
    noOfColumns,
  } = props;

  const cartItems: [] = useReactiveVar(cartItemsVar);
  const [productWithModCategoryList, setProductWithModCategoryList] = useState(
    [],
  );
  const [scaleValues, setScaleValues] = useState();
  const [openProductWithModCategory, setOpenProductWithModCategory] =
    useState<boolean>(false);
  const enableWightScale: any = getLocalKey("enableWightScale");
  const [isWeightableProduct, setIsWeightableProduct] =
    useState<boolean>(false);
  const [isOpenPriceProducts, setIsOpenPriceProducts] =
    useState<boolean>(false);

  const [item, setItem] = useState<any>();

  const handleClick = (item: any) => {
    if (item) {
      item?.compound?.length > 0 &&
        item?.compound?.map((promoItem: any, promoIndex: number) => {
          const nestedTitle = promoItem?.compound.map(
            (nestedItem: any, nestedIndex: number) => nestedItem?.title,
          );
          setPromProducts(nestedTitle);
          setPromotionInfo(promoItem);
        });
      if (item?.isWeightable) {
        if (enableWightScale === "false") {
          setItem(item);
          setIsWeightableProduct(true);
        } else {
          setItem(item);

        }
        setLocalKey("disableRemotePrint", "false");
      } else if (item?.isOpenPriceProduct) {
        setIsOpenPriceProducts(true);
        setItem(item);
      } else {
        let productInCartList = {
          id: item?.id,
          pk: item?.pk,
          contentTypeId: item?.contentTypeId,
          title: item?.title,
          quantity: 1,
          price: item?.finalPrice ?? 0,
          total: item?.finalPrice ?? 0,
          tax: item?.taxAmount,
          taxRate: {
            rate: item?.tax?.rate ?? 0,
            isIncluded: item?.tax?.isIncluded,
          },
          isSelected: true,
          isDeleted: false,
          modifier: [],
          isModified: true,
        };
        let previousObject: any = cartItems.find(
          (x: any) => x.id === item?.id && x.isSelected === true,
        );

        if (previousObject && previousObject.isSelected) {
          let newItemList: any = cartItems;

          let foundIndex: any = cartItems.findIndex(
            (x: any) => x.id === item?.id && x.isSelected === true,
          );

          newItemList[foundIndex].quantity =
            previousObject.quantity >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          newItemList[foundIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) *
              parseFloat(previousObject.quantity)
              : 0;
          newItemList[foundIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);
          newItemList[foundIndex].isDeleted = false;
          newItemList[foundIndex].isSelected = true;
          newItemList[foundIndex].isModified = true;

          let updateNewItemList: any = newItemList.map(
            (item: any, index: number) =>
              !index === foundIndex
                ? {
                  ...item,
                  isSelected: false,
                }
                : item,
          );

          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...updateNewItemList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        } else {
          let nonPreviousItemList = cartItems?.map(
            (item: any, index: number) => ({
              ...item,
              isSelected: false,
            }),
          );
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([
                ...nonPreviousItemList,
                productInCartList,
              ]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        }
      }
    }
  };

  const handleModifierClick = (item: any) => {
    if (!item) {
      toast.info("Please select non-empty modifiers");
    } else {
      let selectedItem: any = cartItems?.find(
        (x: any) => x?.isSelected === true,
      );
      let prevModifierList: any = selectedItem?.modifier;
      const previousObject: any = prevModifierList?.find(
        (x: any) => x?.id === item?.id,
      );
      if (selectedItem) {
        if (previousObject) {
          let productInCartList: any = cartItems;
          let perviousModifierItemList: any = prevModifierList;
          let itemIndex: any = prevModifierList.findIndex(
            (obj: any) => obj.id === item?.id,
          );
          perviousModifierItemList[itemIndex].quantity =
            parseInt(previousObject.quantity) >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          perviousModifierItemList[itemIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) + parseFloat(previousObject.total)
              : 0;
          perviousModifierItemList[itemIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

          perviousModifierItemList[itemIndex].isDeleted = false;

          selectedItem.modifier = perviousModifierItemList;
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true,
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        } else {
          let productInCartList: any = cartItems;
          prevModifierList.push({
            id: item?.id,
            pk: item?.pk,
            contentTypeId: item?.contentTypeId,
            title: item?.title,
            price: item?.finalPrice ?? 0,
            quantity: 1,
            total: item?.finalPrice ?? 0,
            tax: item?.taxAmount,
            taxRate: {
              rate: item?.tax?.rate ?? 0,
              isIncluded: item?.tax?.isIncluded,
            },
            isDeleted: false,
            randId: Math.random().toString(36).substr(2, 5),
          });
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true,
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
        }
      } else {
        if (item) {
          toast.info("Please select product");
        } else {
          toast.info("Please select non-empty modifiers");
        }
      }
    }
  };

  const handleProductWithModCategory = (item: any) => {
    setOpenProductWithModCategory(true);
    setProductWithModCategoryList(item);
    item?.compound?.length > 0 &&
      item?.compound?.map((promoItem: any, promoIndex: number) => {
        const nestedTitle = promoItem?.compound.map(
          (nestedItem: any, nestedIndex: number) => nestedItem?.title,
        );
        setPromProducts(nestedTitle);
        setPromotionInfo(promoItem);
      });
  };
  const searchSelectedItems: any = useReactiveVar(searchSelectedItemsVar);

  const handleProductClick = async (item: any) => {
    if (item)
      if (!item?.isModifier) {
        if (item?.modifierCategory?.edges?.length) {
          handleProductWithModCategory(item);
        } else {
          handleClick(item);
        }
      } else {
        handleModifierClick(item);
      }
  };

  //Add quick Product
  const [addProductPopup, setAddProductPopup] = useState<boolean>(false);
  const productInitialValues = {
    title: "",
    category: [],
    unit: unitDropdown[0]?.id,
    isActive: true,
    costPrice: "",
    sellingPrice: "",
    taxType: taxTypeDropdown[0]?.id,
    barcode: "",
    plu: "",
    printer: [],
    isWeightable: false,
    modifierCategories: [],
    isOpenPriceProduct: false,
    isModifierCategoryGrid: false,
  };
  const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm({})
  //Handle Quick Add Product
  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT,

  );
  const onSubmit = (value: any) => {
  }
  const handleCreateProduct = async (values: any, props: any) => {
    const response = await productCreate({
      variables: {
        // posScreenProductId: productId.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType,
        isModifier: false,
        isWeightable: values?.isWeightable,
        printOrder: null,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer ?? [],
        modifierCategories: values?.modifierCategory ?? [],
        image: null,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcodeContent,
        plu: values?.plu,
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Product Created successfully");
      setAddProductPopup(false);
      let productInCartList = {
        id: response?.data?.createProduct?.product?.id,
        pk: response?.data?.createProduct?.product?.pk,
        contentTypeId: response?.data?.createProduct?.product?.contentTypeId,
        title: response?.data?.createProduct?.product?.title,
        quantity: 1,
        price: response?.data?.createProduct?.product?.finalPrice ?? 0,
        total: response?.data?.createProduct?.product?.finalPrice ?? 0,
        tax: response?.data?.createProduct?.product?.taxAmount,
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
      };

      let nonPreviousItemList = cartItems?.map((item: any, index: number) => ({
        ...item,
        isSelected: false,
      }));
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...nonPreviousItemList, productInCartList]),
        },
      });
      const searchList = {
        searchInput: "",
        name: "",
      };
      searchCache.writeQuery({
        query: GET_SEARCH_ITEMS,
        data: {
          searchItems: searchItemsVar(searchList),
        },
      });
      disableFocusCache.writeQuery({
        query: GET_DISABLE_FOCUS,
        data: {
          disableFocus: disableFocusVar(true),
        },
      });
    } else {

    }
  };

  const handleNavigate = () => {
    const searchList = {
      searchInput: "",
      name: "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(true),
      },
    });
  };

  return (
    <>
      {searchProductData?.products?.edges?.length ? (
        <div
          className={`grid w-full grid-cols-${noOfColumns} p-4 grid-rows-7 gap-2 border-2`}
        >
          {searchProductData?.products?.edges?.map(
            (item: any, index: number) => (
              <React.Fragment key={index}>
                <div
                  id={`selectedItem_${index}`}
                  className={`relative h-[80px] cursor-pointer border-2 border-solid border-gray-300 rounded-lg
                  ${searchSelectedItems?.index === index
                      ? "border-red-600"
                      : "border-black-500"
                    }
                  `}
                  // style={{ background: item?.node?.title ? item?.node?.color ?? "#E9B634"  : "#F3F4F6"}}
                  onClick={() => handleProductClick(item?.node)}
                >
                  {item?.node?.image ? (
                    <>
                      <img
                        src={item?.node?.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <span
                        title={item?.node?.title}
                        className={`${!item?.node?.isModifier ? "" : "text-red-500"
                          } textWithTwoLine  absolute bottom-0 w-full bg-gray-200 text-xs  text-left px-1`}
                      >
                        {item?.node?.title}
                      </span>
                    </>
                  ) : (
                    <div>
                      <img
                        src={item?.node?.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                      <span
                        title={item?.node?.title}
                        className={`${!item?.node?.isModifier ? "" : "text-red-500"
                          } textWithTwoLine absolute top-[20%] w-full bg-gray-200  text-left font-bold px-1`}
                      >
                        {item?.node?.title}
                      </span>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ),
          )}
        </div>
      ) : (
        <div className="px-6 shadow bg-[#ffffff]">
          <div className="text-lg font-semibold text-black text-center p-4">
            Add Product
          </div>
          <div className="w-full flex flex-wrap justify-between mb-4 p-4">
            <div className="w-full flex justify-between gap-10">
              <TextField register={register} name={"title"} label={"Title"} />
              <MultipleSelectField
                name={"category"}
                label={"Select on or more category"}
                titleLabel={"Category"}
                values={getValues("category")}
                errors={errors}
                setFieldValue={setValue}
                options={categoryDropdown}
              />
            </div>
            <div className="w-full flex justify-between gap-10">
              <DynamicDropDown name={"unit"} label={"Unit"} setFieldValue={setValue} values={getValues("unit")} list={unitDropdown} />
              <DynamicDropDown name={"taxableType"} label={"Taxable Type"} setFieldValue={setValue} values={getValues("taxableType")} list={taxTypeDropdown} />
            </div>
            <div className="w-full flex justify-between mt-4">
              <CheckBoxField register={register} name={"isActive"} label={"Active"} />
              <CheckBoxField register={register} name={"isWeightable"} label={"Weightable"} />
              <CheckBoxField register={register} name={"isOpenPriceProduct"} label={"OpenPriceProduct"} />
              <CheckBoxField register={register} name={"isModifierCategoryGrid"} label={"ModifierCategoryGrid"} />
            </div>
            <div className="w-full flex justify-between gap-10">
              <TextField register={register} name={"costPrice"} label={"Cost Price"} />
              <TextField register={register} name={"sellingPrice"} label={"Selling Price"} />
            </div>
            <MultipleSelectField
              name="printer"
              label={"Select on or more Printer"}
              titleLabel={"Printer"}
              values={getValues("printer")}
              errors={errors}
              setFieldValue={setValue}
              options={printerListsDropdown}
            />
            <MultipleSelectField
              name={"modifierCategories"}
              titleLabel={"Modifier Category"}
              label={"Select on or more category"}
              values={getValues("modifierCategories")}
              errors={errors}
              setFieldValue={setValue}
              options={modifierCategoryDropdown}
            />
            <div className="w-full flex justify-between gap-10">
              <TextField register={register} name={"barcode"} label={"BARCODE"} />
              <TextField register={register} name={"plu"} label={"PLU"} />
            </div>
          </div>

          <div className="flex flex-wrap justify-between mb-4 p-4">
            <PrimaryButton
              type={"button"}
              onClick={handleNavigate}
              text={"Back"}
              loading={false}
              disable={false}
            />
            <PrimaryButton
              text={"Submit"}
              onClick={handleSubmit(onSubmit)}
              loading={loadingCreateProduct ? true : false}
              disable={loadingCreateProduct}
            />
          </div>
        </div>
      )}
      <Modal
        title={"Select Modifier"}
        open={openProductWithModCategory}
        setOpen={setOpenProductWithModCategory}
        size="max-w-7xl"
        children={
          <PosScreenProWithModCat
            item={productWithModCategoryList}
            setOpen={setOpenProductWithModCategory}
            open={openProductWithModCategory}
          />
        }
      />

      <Modal
        title={"Add Quantity"}
        open={isWeightableProduct}
        setOpen={setIsWeightableProduct}
        size="max-w-sm"
      >
        <WeightableProductQuantityForm
          item={item}
          cartItems={cartItems}
          setIsWeightableProduct={setIsWeightableProduct}
        />
      </Modal>
      <Modal
        title={"Add Price"}
        open={isOpenPriceProducts}
        setOpen={setIsOpenPriceProducts}
        size="max-w-sm"
      >
        <OpenPriceProductPriceForm
          item={item}
          cartItems={cartItems}
          setIsOpenPriceProducts={setIsOpenPriceProducts}
          scaleValues={scaleValues}
        />
      </Modal>
    </>
  );
};

export default PosScreenSearchProductTab;
