import "./app.css"

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";


export default function Root() {
  return (
    <>
      <BrowserRouter basename={"/pos-screen"}>
        <App />
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}
