import { useEffect, useRef, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";

import {
  CURRENT_TERMINAL,
  POS_SCREEN_BASIC_SETTING_CONFIGURATION,
} from "../../../../pages/PosScreen/queries";
import {
  totalCalculationCache,
  totalCalculationVar,
} from "../../../localstate/Cache";
import { GET_TOTAL_CALCULATION } from "../../../localstate/Cart";
import { toast } from "react-toastify";
import { QUERY_TAX } from "../../../../pages/queries";
import { getLocalKey } from "../../../../helpers/sessionKey";

type Props = {
  cartItems?: any;
  selectDiscountType?: any;
  setSelectDiscountType?: any;
  customerItems?: any;
  availablePointsCheckboxRef?: any;
  availableRewardPoints?: any;
  rewardPointSettingPoint?: any;
  isRewardPointEnabled?: any;
  rewardPointSettingData?: any;
  setRewardPointAmount?: any;
  selectSuchargeType?: any;
  selectedTerminal?: any;
  setChecked?: any;
  chectked?: any;

};
const PosScreenTotalSection = (props: Props) => {
  const {
    cartItems,
    selectedTerminal,
    selectDiscountType,
    selectSuchargeType,
    customerItems,
    availablePointsCheckboxRef,
    setRewardPointAmount,
    rewardPointSettingData,
    availableRewardPoints,
    rewardPointSettingPoint,
    isRewardPointEnabled,
    chectked,
    setChecked
  } = props;
  const { data } = useQuery(POS_SCREEN_BASIC_SETTING_CONFIGURATION);
  const [serviceCheckboxRef, setServiceCheckboxRef] = useState(false);

  const openServiceChargeToggleCheckbox =
    selectedTerminal?.terminal?.terminalsetting?.serviceChargeToggleCheckbox;
  const netDiscount = cartItems.map((cartItem: any, index: number) => {
    return cartItem.discount ? cartItem.discount : 0;
  });
  const netSurcharge = cartItems.map((cartItem: any, index: number) => {
    return cartItem.surcharge ? cartItem.surcharge : 0;
  });
  const handleServiceChargeClick = (e: any) => {
    setServiceCheckboxRef(e.target.checked);
  };
  const netSurchargePer = netSurcharge.reduce(
    (acc: any, num: any) => parseFloat(acc) + parseFloat(num),
    0,
  );
  const netDiscountPer = netDiscount.reduce(
    (acc: any, num: any) => parseFloat(acc) + parseFloat(num),
    0,
  );
  const rewardAmount: any = parseFloat(availableRewardPoints ?? 0);
  //Product List
  let productListToBeCalculated = cartItems?.filter(
    (item: any) => item.isDeleted === false,
  );
  //Modifier List
  let modifiersList = cartItems?.map((item: any) => item?.modifier);
  let modifierListToBeCalculated = modifiersList?.flat(1);
  //Products Total Price
  let productPriceList = productListToBeCalculated?.map(
    (item: any) => parseFloat(item?.price) * parseFloat(item?.quantity),
  );
  let totalProductPrice = productPriceList?.reduce(
    (a: any, b: any) => a + b,
    0,
  );
  //Modifiers Total Price
  let modifierPriceList = modifierListToBeCalculated
    ?.filter((item: any) => item?.quantity > 0)
    .map((item: any) => parseFloat(item?.price) * parseFloat(item?.quantity));

  let totalModifierPrice = modifierPriceList?.reduce(
    (a: any, b: any) => a + b,
    0,
  );
  //Gross Amount
  let netInitialAmount = totalProductPrice + totalModifierPrice;
  const value = netDiscountPer
    ? netInitialAmount - netDiscountPer
    : netInitialAmount || netSurchargePer
      ? netInitialAmount + netSurchargePer
      : netInitialAmount || (netSurchargePer && netDiscountPer)
        ? netInitialAmount - netDiscountPer + netSurchargePer
        : netInitialAmount;
  const [netAmount, setNetAmount] = useState<any>(value);
  const handleCheckboxClick = () => {
    if (
      rewardPointSettingData?.rewardPointSetting?.minRequiredPoint >=
      rewardAmount
    ) {
      toast.error("not satisfied");
      return;
    }
    setChecked(availablePointsCheckboxRef?.current?.checked);
  };
  const rewardValueSet =
    value < availableRewardPoints ? value : availableRewardPoints;
  setRewardPointAmount(rewardValueSet);
  useEffect(() => {
    if (
      chectked &&
      parseFloat(
        rewardPointSettingData?.rewardPointSetting?.minRequiredPoint,
      ) <= rewardAmount
    ) {
      setNetAmount(
        netDiscountPer
          ? netInitialAmount -
          netDiscountPer -
          rewardAmount +
          (netSurchargePer ?? 0)
          : netInitialAmount - rewardAmount,
      );
    } else {
      setNetAmount(
        netDiscountPer
          ? netInitialAmount - netDiscountPer + (netSurchargePer ?? 0)
          : netInitialAmount - rewardAmount,
      );
    }
  }, [chectked, value, netAmount]);
  //Service Charge
  let serviceRate =
    openServiceChargeToggleCheckbox === true
      ? serviceCheckboxRef
        ? data?.basicWebsiteConfigurations?.serviceCharge?.rate ?? 0
        : 0
      : data?.basicWebsiteConfigurations?.serviceCharge?.rate ?? 0;

  let totalAmtAfterServiceCharge: any =
    serviceRate && value > 0 ? (serviceRate / 100) * value : 0;
  //Service Charge GST
  const taxRate = 0.1 ?? 0;
  const serviceChargeGST =
    (
      totalAmtAfterServiceCharge -
      totalAmtAfterServiceCharge / (1 + taxRate)
    ).toFixed(2) ?? 0;
  //Sucharge Amount
  let surchargeAmount =
    (selectSuchargeType?.surchargeAmount !== 0
      ? selectSuchargeType?.surchargeAmount
      : (selectSuchargeType?.surchargePercentage * value) / 100) ?? "0";
  let surChargeGST =
    (surchargeAmount - surchargeAmount / (1 + taxRate)).toFixed(2) ?? 0;
  //Tax Amount
  function flatten(arr: any) {
    if (!arr) {
      return [];
    }
    return arr.reduce(function (r: any, i: any) {
      return r.concat([i]).concat(flatten(i.modifier));
    }, []);
  }
  let taxAmountList = flatten(productListToBeCalculated)?.map((item: any) => {
    if (typeof item?.tax === "object") {
      const totalAfter =
        (parseFloat(item?.tax?.rate) / 100) * parseFloat(item.price);
      return totalAfter ?? 0;
    } else {
      return parseFloat(item?.tax ?? 0);
    }
  });
  let totalTaxAmount: any = taxAmountList?.reduce((a: any, b: any) => a + b, 0);
  let totalTaxAmountCharges = 0
  let totalTaxAmountFinal = totalTaxAmount + totalTaxAmountCharges ?? 0;
  //Total after taxes
  let totalAfterTaxes =
    parseFloat(value) +
    (parseFloat(surchargeAmount) ?? 0) +
    (parseFloat(totalAmtAfterServiceCharge) ?? 0);
  //Discount Amount
  let discountAmount =
    (selectDiscountType?.discountAmount !== 0
      ? selectDiscountType?.discountAmount
      : selectDiscountType?.discountPercentage) ?? 0;

  let totalDiscountAmount =
    (selectDiscountType?.discountAmount !== 0
      ? discountAmount
      : (discountAmount / 100) * totalAfterTaxes) ?? 0;

  let totalAmtAfterDiscount: any =
    (selectDiscountType?.discountPercentage !== 0
      ? totalAfterTaxes - (discountAmount / 100) * totalAfterTaxes
      : totalAfterTaxes > 0
        ? totalAfterTaxes - discountAmount
        : 0) ?? 0;
  let grossAmount = parseFloat(totalAmtAfterDiscount).toFixed(2);
  useEffect(() => {
    if (cartItems?.length) {
      let totalCalculationAmt = {
        netAmount: value.toFixed(2),
        discountAmount: totalDiscountAmount.toFixed(2),
        surChargeAmount: surchargeAmount,
        discountType: selectDiscountType,
        totalAmtAfterDiscount: totalAmtAfterDiscount
          ? parseFloat(totalAmtAfterDiscount).toFixed(2)
          : "0.00",
        totalAmtAfterTax: totalAfterTaxes.toFixed(2),
        totalIndividualDiscount: netDiscountPer,
        totalAmtAfterServiceCharge: totalAmtAfterServiceCharge
          ? parseFloat(totalAmtAfterServiceCharge).toFixed(2)
          : "0.00",
        grossAmount:
          chectked &&
            parseFloat(
              rewardPointSettingData?.rewardPointSetting?.minRequiredPoint,
            ) <= rewardAmount
            ? parseFloat(grossAmount) - parseFloat(rewardAmount) > 0
              ? (parseFloat(grossAmount) - parseFloat(rewardAmount)).toFixed(2)
              : "0.00 "
            : grossAmount,
        taxAmount: parseFloat(totalTaxAmountFinal).toFixed(2),
        serviceChargeAmount: totalAmtAfterServiceCharge ?? 0,
      };
      totalCalculationCache.writeQuery({
        query: GET_TOTAL_CALCULATION,
        data: {
          totalCalculationItems: totalCalculationVar(totalCalculationAmt),
        },
      });
    }
  }, [
    cartItems,
    netAmount,
    discountAmount,
    serviceCheckboxRef,
    totalTaxAmount,
    totalAmtAfterServiceCharge,
    serviceRate,
    surchargeAmount,
    chectked,
  ]);
  return (
    <>
      <table style={{ width: "100%", fontSize: "13px", fontWeight: 500 }}>
        <tbody>
          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>Sub Total</td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {totalCalculationVar()?.netAmount ?? 0}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>
              {openServiceChargeToggleCheckbox === true ? (
                <input
                  type="checkbox"
                  onChange={(e) => handleServiceChargeClick(e)}
                />
              ) : (
                ""
              )}
              ServiceCharge[{serviceRate}%]
            </td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {totalCalculationVar()?.totalAmtAfterServiceCharge ?? 0}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>Surcharge</td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {surchargeAmount > 0 ? parseFloat(surchargeAmount).toFixed(2) : 0}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>GST</td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {totalCalculationVar()?.taxAmount ?? 0}
            </td>
          </tr>
          {customerItems?.id && isRewardPointEnabled === true ? (
            <tr>
              <td style={{ textAlign: "left", fontWeight: "bold" }}>
                <input
                  type="checkbox"
                  checked={chectked}
                  ref={availablePointsCheckboxRef}
                  onChange={handleCheckboxClick}
                />
                Available Points
              </td>
              <td style={{ textAlign: "right", fontWeight: "bolder" }}>
                {parseFloat(availableRewardPoints).toFixed(2)}
              </td>
            </tr>
          ) : null}
          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>Total</td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {totalCalculationVar().totalAmtAfterTax ?? 0}
            </td>
          </tr>
          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>
              Discount
              {selectDiscountType?.discountAmount !== 0
                ? `[${netAmount > 0 ? discountAmount : 0}Amt]`
                : `[${netAmount > 0 ? discountAmount : 0}%]`}
            </td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {totalCalculationVar()?.discountAmount ?? 0}
            </td>
          </tr>

          <tr>
            <td style={{ textAlign: "left", fontWeight: "bold" }}>
              Grand Total
            </td>
            <td style={{ textAlign: "right", fontWeight: "bolder" }}>
              {data?.basicWebsiteConfigurations?.currency}&nbsp;{" "}
              {totalCalculationVar()?.grossAmount ?? 0}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default PosScreenTotalSection;
