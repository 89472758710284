import { useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  POS_SCREEN_TABLE_ITEM_BY_ID_WITH_CURRENT_KITCHEN_ORDER,
} from "../../../pages/PosScreen/queries";
import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar,
  kitchenOrderItemId,
  kitchenOrdreCache,
  modifiedKitchenOrderCache,
  modifiedKitchenOrderItemVar,
  orderTypeCache,
  orderTypeVar,
  tableCache,
  tableItemsVar,
} from "../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
  GET_KITCHEN_CARD_ID,
  GET_MODIFIED_KITCHEN_CARD_ID,
  GET_ORDER_TYPE,
  GET_TABLE_ITEMS,
} from "../../localstate/Cart";
import Modal from "../../reusable/Modal";
import DataLoadSpinner from "../../reusable/Spinner/DataLoadSpinner";
import PosScreenMergeTable from "./PosScreenMergeTable";
import PosScreenSwapTable from "./PosScreenSwapTable";
import randomString from "../../../utils/randomString";
import { GET_RESTAURANT_FLOOR } from "../../../pages/queries";
import PosScreenSplitTable from "./PosScreenSplitTable";
import PosScreenSplitSubTableList from "./PosScreenSplitSubTableList";
import DropDrag from "./DynamicLayout";
import { setLocalKey } from "../../../helpers/sessionKey";
import { TOGGLE_IS_SELECTED_TABLE } from "../../../pages/mutations";
import { toast } from "react-toastify";
import useHandleToggleSelectedTable from "../../../customhooks/useHandleToggleSelectedTable";


type Props = {
  setOpenTableList?: any;
  openSplitTable: boolean;
  setOpenSplitTable: any;
  openMergeTable: boolean
  setOpenMergeTable: any
  selectedSwapTable: string
  setSelectedSwapTable: any
  openSwapTable: any
  setOpenSwapTable: any
  setSelectedTableArray: any
  selectedTableArray: any
  setTableId: any
  tableId: string
  handleMergeTable: any
  handleSwapTable: any
  handleSplitTable: any
  handleSplitSubTable: any
  quantity: number
  setQuantity: any
  handleSplitDisableSubTable: any
  openSplitSubTableList: boolean
  setOpenSplitSubTableList: any
  floorId: string
  setFloorId: any
  setFloorPk: any
  floorPk: string
  isFloorLoading: boolean
  data: any
}

const PosScreenTableList = (props: Props) => {
  const {
    setOpenTableList,
    openSplitTable,
    openSplitSubTableList,
    setOpenSplitSubTableList,
    setOpenSplitTable,
    setOpenMergeTable,
    openMergeTable,
    selectedSwapTable,
    setSelectedSwapTable,
    setSelectedTableArray,
    selectedTableArray,
    setTableId,
    tableId,
    handleMergeTable,
    handleSplitTable,
    handleSwapTable,
    openSwapTable,
    setOpenSwapTable,
    handleSplitSubTable,
    quantity,
    setQuantity,
    handleSplitDisableSubTable,
    floorId,
    setFloorId,
    setFloorPk,
    floorPk,
    isFloorLoading,
    data,
  } = props;

  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  const { handleSelectTableToggle } = useHandleToggleSelectedTable();
  const [getOrderedItem, { data: tableWiseData }] = useLazyQuery(
    POS_SCREEN_TABLE_ITEM_BY_ID_WITH_CURRENT_KITCHEN_ORDER,
    {
      fetchPolicy: "no-cache",
    },
  );


  const handleSplitOption = (e: any) => {
    setTableId(e.target.dataset.tableId);
    setOpenSplitTable(true);
  };
  const handleDisableSplitOption = (e: any) => {
    handleSplitDisableSubTable(e.target.dataset.tableId);
  };

  const handleMergeOption = (e: any) => {
    setTableId(e.target.dataset.tableId);
    setSelectedTableArray((prev: any) => [...prev, e.target.dataset.tableId]);
    setOpenMergeTable(true);
  };

  const handleSwapOption = (e: any) => {
    setTableId(e.target.dataset.tableId);
    setOpenSwapTable(true);
  };

  const handleUnmergeOption = (e: any) => {
    handleSplitTable(e.target.dataset.tableId);
  };

  const handleSelectTable = async (item: any) => {
    setLocalKey("disableRemotePrint", "true");
    let tableItems = {
      id: item?.node?.id,
      status: item?.node?.status,
      pk: item?.node?.pk,
      title: item?.node?.title,
      currentKitchenOrder: item.node.currentKitchenOrder ?? "",
    };

    if (item?.node?.status === "OCCUPIED") {
      (await getOrderedItem({
        variables: {
          id: item?.node?.id,
        },
      }));
    } else if (item?.node?.status === "VACANT" && kitchenOrdreItemId?.kitchenCardItemId?.id) {
    } else {
      setLocalKey("disableRemotePrint", "false");
      kitchenOrdreCache.writeQuery({
        query: GET_KITCHEN_CARD_ID,
        data: {
          kitchenOrderItemId: kitchenOrderItemId({}),
        },
      });
      modifiedKitchenOrderCache.writeQuery({
        query: GET_MODIFIED_KITCHEN_CARD_ID,
        data: {
          modifiedKitchenOrderId:
            modifiedKitchenOrderItemVar({}),
        },
      });

      orderTypeCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          orderType: orderTypeVar({}),
        },
      });
    }

    tableCache.writeQuery({
      query: GET_TABLE_ITEMS,
      data: {
        tableItems: tableItemsVar(tableItems),
      },
    });
    setOpenSplitSubTableList(false);
    setOpenTableList();
  };

  const handleTable = (item: any) => {
    if (item?.node?.isSelected) {
      toast.error("Table is already selected")
      return;
    }
    if (item?.node?.isSubTableVisible) {
      setTableId(item?.node?.pk);
      setOpenSplitSubTableList(true);
    } else {
      handleSelectTable(item);
      handleSelectTableToggle(true, item?.node?.pk)
    };
  }
  useEffect(() => {
    if (tableWiseData) {
      let itemToCart: any[] = [];

      let previousOrderItemsList =
        tableWiseData?.restaurantTable?.currentKitchenOrder ?? [];

      const ordeTypeList = {
        pk: previousOrderItemsList?.orderTypePk,
        title: previousOrderItemsList?.orderType?.deliveryMethod,
      };
      orderTypeCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          orderType: orderTypeVar(ordeTypeList),
        },
      });

      previousOrderItemsList?.kitchenOrderItems?.edges?.map(
        (item: any) => {
          let modItems: any = [];
          item?.node?.modifier?.map((modItem: any) => {
            modItems.push({
              id: modItem?.product?.id,
              pk: modItem?.product?.pk,
              contentTypeId: modItem?.product?.contentTypeId,
              title: modItem?.product?.title,
              quantity: modItem?.quantity,
              price: modItem?.product?.finalPrice ?? 0,
              total:
                parseFloat(modItem?.quantity) *
                parseFloat(modItem?.product?.finalPrice),
              tax: parseFloat(modItem?.quantity) * parseFloat(modItem?.product?.taxAmount),
              taxRate: {
                rate: modItem?.product?.tax?.rate,
                isIncluded: modItem?.product?.tax?.isIncluded,
              },
              isSelected: false,
              isDeleted: false,
              discount: modItem?.product?.discount
            });
          });
          itemToCart.push({
            id: item?.node?.product?.id,
            pk: item?.node?.product?.pk,
            contentTypeId: item?.node?.product?.contentTypeId,
            title: item?.node?.product?.title,
            quantity: item?.node?.quantity,
            price: item?.node?.product?.finalPrice,
            total:
              parseFloat(item?.node?.quantity) *
              parseFloat(item?.node?.product?.finalPrice),
            tax: parseFloat(item?.node?.quantity) * parseFloat(item?.node?.product?.taxAmount),
            taxRate: {
              rate: item?.node?.product?.tax?.rate,
              isIncluded: item?.node?.product?.tax?.isIncluded,
            },
            isSelected: false,
            isDeleted: false,
            idToModifyKitchenOrder: item?.node?.pk,
            modifier: modItems,
          });


          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar(itemToCart),
            },
          });
          let kitchenOrderItems = {
            kitchenOrderPk: previousOrderItemsList,
          };
          modifiedKitchenOrderCache.writeQuery({
            query: GET_MODIFIED_KITCHEN_CARD_ID,
            data: {
              modifiedKitchenOrderId:
                modifiedKitchenOrderItemVar(kitchenOrderItems),
            },
          });
          const kitchenCardItemId: any = {
            id: previousOrderItemsList?.id,
            pk: previousOrderItemsList?.pk,
          };
          kitchenOrdreCache.writeQuery({
            query: GET_KITCHEN_CARD_ID,
            data: {
              kitchenOrderItemId: kitchenOrderItemId({ kitchenCardItemId }),
            },
          });
          let customerItems = {
            id: previousOrderItemsList?.customer?.id ?? "",
            name: `${previousOrderItemsList?.customer?.user?.fullName ?? ""}`,
            pk: previousOrderItemsList?.customer?.pk ?? "",
          };
          customerCache.writeQuery({
            query: GET_CUSTOMER_ITEMS,
            data: {
              customerItems: CustomerItemsVar(customerItems),
            },
          });

        },
      );
    }
  }, [tableWiseData?.restaurantTable]);


  const [getTableDataByRes, { data: selectedRestaurantFloor }] = useLazyQuery(GET_RESTAURANT_FLOOR, {
    fetchPolicy: "network-only",
    pollInterval: 2000,
  });

  useEffect(() => {
    if (floorId?.length) {
      getTableDataByRes({
        variables: {
          id: floorId,
        },
        fetchPolicy: 'network-only'
      });
    }
  }, [floorId]);

  const [updateTablePopup, setUpdateTablePopup] = useState(false);

  return (
    <div>
      {isFloorLoading ? (
        <DataLoadSpinner />
      ) : (
        <>
          <div key={randomString(20)} className="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <div className="grid grid-rows-1 grid-cols-7 gap-2 p-2">
              {data?.floors?.edges?.map((item: any) => (
                <div
                  key={item?.node?.id}
                  className={`flex text-center min-h-full max-h-[60px] cursor-pointer rounded-lg items-center shadow-sm 
                           ${item.node.id === floorId ? "bg-[#BABABB] text-white" : "bg-[#efefef] text-gray-800"}`}
                >
                  <div className="flex py-3 w-full justify-center"
                    onClick={() => {
                      setFloorId(item?.node?.id);
                      setFloorPk(item?.node?.pk);
                    }}
                  >
                    <p className="">
                      {item?.node?.title}
                    </p>
                  </div>
                </div>
              ))}
            </div>

          </div>
          <br />
          <div className="h-[520px] overflow-y-scroll">
            <DropDrag
              tables={selectedRestaurantFloor?.floor?.tablesByFloor?.edges || []}
              layout={selectedRestaurantFloor?.floor?.layout?.length === 0 ? null : selectedRestaurantFloor?.floor?.layout}
              floorPk={floorPk}
              floorID={floorId}
              handleTable={handleTable}
              handleUnmergeOption={handleUnmergeOption}
              handleSwapOption={handleSwapOption}
              handleMergeOption={handleMergeOption}
              handleSplitOption={handleSplitOption}
              handleDisableSplitOption={handleDisableSplitOption}
              updateTablePopup={updateTablePopup}
              setUpdateTablePopup={setUpdateTablePopup}
            />
          </div>
        </>

      )}

      {/* openMergeTable */}
      <Modal
        open={openMergeTable}
        setOpen={setOpenMergeTable}
        title=
        {selectedRestaurantFloor?.floor?.tablesByFloor?.edges?.filter((item: any) => {
          return ((item?.node?.pk).toString() === tableId);
        }).map((elem: any) =>
        (
          <p className="flex items-center justify-center text-sm font-semibold">
            Merge
            <span className="text-[#ED4C5C] mx-1">{elem?.node?.title} (0/{elem?.node?.capacity})</span>
            With
          </p>
        ))}
        size={"max-w-7xl"}
        onCloseModal={() => setSelectedTableArray([])}
      >
        <PosScreenMergeTable
          setSelectedTableArray={setSelectedTableArray}
          selectedTableArray={selectedTableArray}
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleMergeTable={handleMergeTable}
        />
      </Modal>

      {/* openSwapTable */}
      <Modal
        open={openSwapTable}
        setOpen={setOpenSwapTable}
        title={
          selectedRestaurantFloor?.floor?.tablesByFloor?.edges?.filter((item: any) => {
            return ((item?.node?.pk).toString() === tableId);
          }).map((elem: any) =>
          (
            <p className="flex items-center justify-center text-sm font-semibold"
            >Swap <span className="text-[#ED4C5C] mx-1">{elem?.node?.title} (0/{elem?.node?.capacity})</span> With
            </p>
          ))
        }
        size={"max-w-7xl"}
        onCloseModal={() => setSelectedSwapTable("")}
      >
        <PosScreenSwapTable
          selectedSwapTable={selectedSwapTable}
          setSelectedSwapTable={setSelectedSwapTable}
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleSwapTable={handleSwapTable}
        />
      </Modal>

      {/* openSplitTable */}
      <Modal
        open={openSplitTable}
        setOpen={setOpenSplitTable}
        title={
          selectedRestaurantFloor?.floor?.tablesByFloor?.edges?.filter((item: any) => {
            return ((item?.node?.pk).toString() === tableId);
          }).map((elem: any) =>
          (
            <p className="flex items-center justify-center text-sm font-semibold"
            >Split <span className="text-[#ED4C5C] mx-1">{elem?.node?.title} (0/{elem?.node?.capacity})</span> In
            </p>
          ))
        }
        size={"max-w-md"}
        onCloseModal={() => setOpenSplitTable("")}
      >
        <PosScreenSplitTable
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleSplitTable={handleSplitSubTable}
          quantity={quantity}
          setQuantity={setQuantity}
        />
      </Modal>

      {/*openSplitSubTableList  */}
      <Modal
        open={openSplitSubTableList}
        setOpen={setOpenSplitSubTableList}
        title={
          selectedRestaurantFloor?.floor?.tablesByFloor?.edges?.filter((item: any) => {
            return ((item?.node?.pk).toString() === tableId);
          }).map((elem: any) =>
          (
            <p className="flex items-center justify-center text-sm font-semibold"
            >Sub List of <span className="text-[#ED4C5C] mx-1">{elem?.node?.title} (0/{elem?.node?.capacity})</span>
            </p>
          ))
        }
        size={"max-w-7xl"}
        onCloseModal={() => setOpenSplitSubTableList("")}
      >
        <PosScreenSplitSubTableList
          tableId={tableId}
          tableData={selectedRestaurantFloor}
          isTableLoading={isFloorLoading}
          handleSelectTable={handleSelectTable}
        />
      </Modal>
    </div>
  );
};
export default PosScreenTableList;


