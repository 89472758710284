import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
import Cleave from 'cleave.js/react';
import { useEffect, useState } from "react";
import { cache, cartItemsVar, kitchenOrderItemId, permissionTypeVar } from "../../../../localstate/Cache";
import { GET_CART_ITEMS } from "../../../../localstate/Cart";
import { setLocalKey } from "../../../../../helpers/sessionKey";
import { PROFILE } from "../../../../../pages/queries";
import { useQuery, useReactiveVar } from "@apollo/client";
import Modal from "../../../../reusable/Modal";
import AccessControl from "../../../../../helpers/accessControl";

type Props = {
  cartItem?: any;
  setOpen?: any;
  itemToDeleteIndex?: any;
  handleRemoveProductFromCart?: any;
};

const PosScreenProductForm = (props: Props) => {
  const { cartItem, setOpen, itemToDeleteIndex, handleRemoveProductFromCart } = props;
  const [permissionType, setPermissionType] = useState("");
  const [permPopup, setPermPopup] = useState(false);
  const permissionTypes = useReactiveVar(permissionTypeVar);
  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);

  const [productQuantity, setProductQuantity] = useState<string>(
    cartItem?.quantity,
  );
  const [priceAmt, setPriceAmt] = useState<any>(cartItem?.price);
  const [sellingPriceAmt, setSellingPriceAmt] = useState<any>(cartItem?.price);

  useEffect(() => {
    if (cartItem?.taxRate?.isIncluded) {
      let calculatedPrice = parseFloat(cartItem?.price) / (1 + parseFloat(cartItem?.taxRate?.rate) / 100)
      setPriceAmt(calculatedPrice.toFixed(2))
    } else {
      const priceWithoutGST: any = sellingPriceAmt / (1 + parseFloat(cartItem?.taxRate?.rate) / 100)
      setPriceAmt(parseFloat((priceWithoutGST).toFixed(2)))
    }
  }, [])

  useEffect(() => {
    if (cartItem?.taxRate?.isIncluded) {
      setSellingPriceAmt(sellingPriceAmt)
    } else {
      const priceWithoutGST: any = sellingPriceAmt / (1 + parseFloat(cartItem?.taxRate?.rate) / 100)
      setSellingPriceAmt(parseFloat((priceWithoutGST).toFixed(2)))
    }
  }, [])

  const handleSellingPriceChange = (value: any) => {
    setSellingPriceAmt(value)
    if (cartItem?.taxRate?.isIncluded) {
      const amt: any = (parseFloat(value) / (1 + parseFloat(cartItem?.taxRate?.rate) / 100))
      setPriceAmt(parseFloat((amt).toFixed(2)))
    } else {
      setPriceAmt(value)
    }
  }


  const getProductPrice = (value: any) => {
    if (cartItem?.taxRate?.isIncluded) {
      return value
    } else {
      let finalPrice = parseFloat(value) + parseFloat(value) * parseFloat(cartItem?.taxRate?.rate) / 100
      return finalPrice
    }
  }

  const getTotalProductPrice = (value: any, quantity: any) => {
    if (cartItem?.taxRate?.isIncluded) {
      return value * quantity
    } else {
      let finalPrice = parseFloat(value) + parseFloat(value) * parseFloat(cartItem?.taxRate?.rate) / 100
      return finalPrice * quantity
    }
  }

  const getTotalTax = (value: any, quantity: any, sellingPriceAmt: any) => {
    if (parseFloat(value) > parseFloat("0.00")) {
      let totalProductPrice: any = getTotalProductPrice(sellingPriceAmt, quantity)
      let taxAmount = totalProductPrice - totalProductPrice / (1 + value) * 100
      return taxAmount
    }
    return 0
  }

  const [note, setNote] = useState<string>(cartItem?.note);
  let item = [...cartItemsVar()];
  const handleEditProduct = () => {
    setLocalKey("disableRemotePrint", "false");
    let selectedProduct = item?.filter(
      (item: any) => item?.isSelected === true,
    );
    let previousObject: any = item.find(
      (x: any) => x.id === cartItem?.id && x.isSelected === true,
    );
    if (selectedProduct && previousObject) {
      let newItemList: any = item;

      let foundIndex: any = item.findIndex(
        (x: any) => x.id === cartItem?.id && x.isSelected === true,
      );

      newItemList[foundIndex].quantity =
        previousObject.quantity >= 0 ? parseFloat(productQuantity) : 1;
      newItemList[foundIndex].price = getProductPrice(sellingPriceAmt);
      newItemList[foundIndex].total = getTotalProductPrice(sellingPriceAmt, productQuantity);
      newItemList[foundIndex].note = note ? note : "";
      newItemList[foundIndex].isModified = true;
      newItemList[foundIndex].tax = getTotalTax(cartItem?.taxRate?.rate, productQuantity, sellingPriceAmt);
      let updateNewItemList: any = newItemList.map((item: any, index: number) =>
        !index === foundIndex
          ? {
            ...item,
            isSelected: false,
          }
          : item,
      );

      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...updateNewItemList]),
        },
      });
      setOpen(false);
    }
  };
  const handleOnIncreasingCounter = () => {
    const quantityInNumber = parseInt(productQuantity);
    let increaseValue = quantityInNumber + 1;
    if (increaseValue > quantityInNumber) {
      setProductQuantity((quantityInNumber + 1).toString());
    }
    setProductQuantity(increaseValue.toString());
  };


  const handleOnDecreasingCounter = () => {
    const quantityInNumber = parseInt(productQuantity);
    let decreaseValue = quantityInNumber - 1;
    if (decreaseValue < quantityInNumber) {
      setProductQuantity((quantityInNumber - 1).toString());
    }
    setProductQuantity(decreaseValue.toString());
  };
  return (
    <>
      <div className="grid grid-cols-3 gap-8">
        <div className="container">
          <label className="mb-25">Quantity</label>
          <div className="flex w-full h-10 items-center justify-between mt-[15px]">
            <div>
              <button
                disabled={productQuantity === "0"}
                className="flex text-white text-sm
                 bg-[#4338CA] rounded p-[10px]"
                onClick={handleOnDecreasingCounter}
              >
                <MinusIcon className="h-5 w-5" aria-hidden="true" />1
              </button>
            </div>
            <div className="w-full border-[5px] border-solid border-black-800">
              <Cleave
                className="w-full h-[32px] text-center border-none"
                value={productQuantity}
                onChange={(e) => setProductQuantity(e.target.value)}
                options={{
                  numeral: true,
                  numeralDecimalMark: ".",
                  numeralDecimalScale: 2,
                  numeralPositiveOnly: true,
                  delimiter: ""
                }}
              />
            </div>

            <div>
              <button
                className="flex text-white text-sm 
                bg-[#4338CA] rounded p-[10px]"
                onClick={handleOnIncreasingCounter}
              >
                <PlusIcon className="h-5 w-5" aria-hidden="true" />1
              </button>
            </div>
          </div>
        </div>
        <div className="container">
          <label className="mb-25"> Selling Price</label>
          <div className="flex flex-end w-full h-10 items-center justify-between mt-[15px]">
            <div className="w-full border-[5px] border-solid border-black-800">
              <input
                className="w-full h-[32px] text-center"
                type="number"
                value={sellingPriceAmt}
                onChange={(e: any) => handleSellingPriceChange(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <label className="mb-25"> SP without GST</label>
          <div className="flex flex-end w-full h-10 items-center justify-between mt-[15px]">
            <div className="w-full border-[5px] border-solid border-black-800">
              <input
                className="w-full h-[32px] text-center"
                type="text"
                value={priceAmt}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <br />
        <label className="mb-25">Note</label>
        <div className="flex flex-end w-full h-10 items-center justify-between mt-[15px]">
          <div className="w-full border-[5px] border-solid border-black-800">
            <textarea
              onChange={(e: any) => setNote(e.target.value)}
              className="w-full h-[auto]"
              value={note}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <span className="flex justify-between gap-4">

        {kitchenOrdreItemId?.kitchenCardItemId?.id ?
          <>
            {permissionTypes === "can_delete_product_after_kitchen_print" ?
              <button
                type="submit"
                onClick={(e) => handleRemoveProductFromCart(e, itemToDeleteIndex, cartItem)}
                className={`w-[30%] h-[50px] text-white  bg-[#D32F2F] rounded`}
              >
                Delete
              </button> :
              <>
                <AccessControl
                  allowedPermissions={["can_delete_product_after_kitchen_print"]}
                  renderNoAccess={
                    <button
                      type="submit"
                      onClick={() => {
                        setPermPopup(true);
                        setPermissionType("can_delete_product_after_kitchen_print");
                      }}
                      className={`w-[30%] h-[50px] text-white  bg-[#D32F2F] rounded`}
                    >
                      Delete
                    </button>
                  }
                  children={
                    <button
                      type="submit"
                      onClick={(e) => handleRemoveProductFromCart(e, itemToDeleteIndex, cartItem)}
                      className={`w-[30%] h-[50px] text-white  bg-[#D32F2F] rounded`}
                    >
                      Delete
                    </button>
                  }
                />
              </>
            }
          </>

          :
          <button
            type="submit"
            onClick={(e) => handleRemoveProductFromCart(e, itemToDeleteIndex, cartItem)}
            className={`w-[30%] h-[50px] text-white  bg-[#D32F2F] rounded`}
          >
            Delete
          </button>

        }

        <button
          type="submit"
          onClick={handleEditProduct}
          className={`w-[30%] h-[50px] text-white  bg-[#4338CA] rounded`}
        >
          Update
        </button>
      </span>
   
    </>
  );
};

export default PosScreenProductForm;
