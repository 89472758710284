import classNames from "../../../utils/classNames";

function PosScreenButton(props: any) {
  const { disabled, onClick, buttonTitle } = props;

  return (
    <>
      <button
        className={classNames(!disabled ? "bg-[#EFEFEF]" : "bg-[#4338CA] text-white", "flex  items-center w-100% h-30px cursor-pointer rounded-lg p-8 mb-6px text-[12px]  justify-center")}
        onClick={onClick}
        disabled={!disabled}
      >
        <span className="absolute z-100 w-[40px]">{buttonTitle}</span>
      </button>
    </>
  );
}

export default PosScreenButton;
