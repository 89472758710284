import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useRef } from "react";
import CrossIcon from "../../../assets/cross.svg";
import classNames from "../../../utils/classNames";
import DIcon from "../../../assets/d.svg";
import { disableFocusCache, disableFocusVar } from "../../localstate/Cache";
import { GET_DISABLE_FOCUS } from "../../localstate/Cart";

interface IModal {
  open: boolean | undefined;
  setOpen: any;
  children: React.ReactNode;
  title: string;
  size?: string;   // USE TAILWIND SIZE
  onCloseModal?: any;
  picture?: any;
  closeModal?: any;
}

const Modal = (props: IModal) => {
  const { open, setOpen, children, title, onCloseModal = false, size = "max-w-xl", picture, closeModal = true } = props;

  const cancelButtonRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    if (onCloseModal) {
      onCloseModal()
    }
    disableFocusCache.writeQuery({
      query: GET_DISABLE_FOCUS,
      data: {
        disableFocus: disableFocusVar(true),
      },
    });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto w-full"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={() => { }}
        >
          <div className="w-full flex items-end justify-center min-h-screen pt-2 px-4 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className={classNames(size, "inline-block relative w-full overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-md")}>
                <div className="absolute w-full bg-primary rounded-t-sm p-1">
                  <div className="w-full flex justify-between ml-4 mr-2 ">
                    <div className="w-full flex gap-3">
                      <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800" src={DIcon} alt="" />
                      <Dialog.Title as="h4" className="w-full text-lg font-semibold text-white text-center">
                        {title}
                      </Dialog.Title>
                    </div>
                    {closeModal ?
                      < img src={CrossIcon} alt="" onClick={handleClose} className="mr-6 cursor-pointer" />
                      : ""}
                  </div></div>
                <div className="p-6 mt-7">
                  {/* PASS CHILDREN HERE */}
                  {children}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default Modal;
