import { gql } from "@apollo/client";

const POS_SCREEN_CREATE_CUSTOMER = gql`
  mutation (
    $fullName: String
    $email: String
    $mobileNumber: String
    $fullAddress: String
  ) {
    createCustomer(
      input: {
        fullName: $fullName
        email: $email
        mobileNumber: $mobileNumber
        fullAddress: $fullAddress
      }
    ) {
      success
      errors
      customer {
        user {
          email
          mobileNumber
          fullName
          profile {
            email
            contactNumber
            pk
          }
        }
        id
        pk
      }
    }
  }
`;
const POS_SCREEN_CREATE_CUSTOMER_ADDRESS = gql`
  mutation (
    $contentType: String
    $objectId: String
    $lineAddressOne: String
    $addressName: String
  ) {
    createAddress(
      input: {
        contentType: $contentType
        objectId: $objectId
        lineAddressOne: $lineAddressOne
        addressName: $addressName
      }
    ) {
      errors
      success
    }
  }
`;
const POS_SCREEN_CREATE_KITCHEN_ORDER = gql`
  mutation (
    $orderInfo: KitchenOrderInput!
    $orderItems: [KitchenOrderItemInput]
  ) {
    createKitchenOrder(
      input: { orderInfo: $orderInfo, orderItems: $orderItems }
    ) {
      errors
      success
      kitchenOrder {
        pk
        id
        orderStatus
        customer {
          pk
          id
          user {
            id
            pk
            mobileNumber
            email
            fullName
          }
        }
        table {
          pk
          id
          status
          capacity
          title
        }
        kitchenOrderItems {
          id
          pk
          modifiedKitchenOrderItem {
            id
          }
          quantity
          note
          product {
            id
            pk
            title
            finalPrice
            costPrice
            sellingPrice
            printer {
              edges {
                node {
                  id
                  pk
                  location
                  port
                  department
                }
              }
            }
            printOrder {
              productOrder
            }
          }
          modifiers {
            edges {
              node {
                id
                pk
                quantity
                note
                product {
                  id
                  pk
                  title
                  finalPrice
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
    }
  }
`;
const POS_SCREEN_CREATE_MODIFIED_KITCHEN_ORDER = gql`
  mutation (
    $orderInfo: ModifiedKitchenOrderInput!
    $orderItems: [ModifiedKitchenOrderItemInput]
  ) {
    topUpKitchenOrder(
      input: { orderInfo: $orderInfo, orderItems: $orderItems }
    ) {
      errors
      success
      modifiedKitchenOrderItems {
        pk
        id
        quantity
        modifiedQuantity
        note
        product {
          id
          pk
          title
          printer {
            edges {
              node {
                id
                pk
                location
                port
                department
              }
            }
          }
          printOrder {
            productOrder
          }
        }
        modifiers {
          edges {
            node {
              id
              pk
              modifiedQuantity
              note
              product {
                id
                pk
                title
              }
            }
          }
        }
      }
      kitchenOrder {
        pk
        id
        orderStatus
        orderMethod {
          pk
        }
        customer {
          pk
          id
          user {
            id
            pk
            mobileNumber
            email
            fullName
          }
        }
        table {
          pk
          id
          status
          capacity
          title
        }
        kitchenOrderItems {
          id
          pk
          modifiedKitchenOrderItem {
            id
          }
          quantity
          modifiedQuantity
          product {
            id
            pk
            title
            finalPrice
            costPrice
            sellingPrice
            printer {
              edges {
                node {
                  id
                  pk
                  location
                  port
                  department
                }
              }
            }
          }
          modifiers {
            edges {
              node {
                id
                pk
                quantity
                modifiedQuantity
                product {
                  id
                  pk
                  title
                  finalPrice
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
    }
  }
`;
const POS_SCREEN_HOLD_KITCHEN_ORDER_LIST = gql`
  mutation (
    $id: String!
    $orderStatus: String!
    $reason: String
    $amount: Decimal
  ) {
    modifyKitchenOrderStatus(
      input: {
        id: $id
        orderStatus: $orderStatus
        reason: $reason
        amount: $amount
      }
    ) {
      success
      errors
      kitchenOrder {
        id
        pk
        table {
          id
          pk
          status
          title
        }
        kitchenOrderItems {
          id
          pk
          quantity
          product {
            id
            pk
            title
            finalPrice
            sellingPrice
            costPrice
          }
          modifier {
            quantity
            product {
              id
              pk
              title
              finalPrice
              sellingPrice
              costPrice
            }
          }
        }
      }
    }
  }
`;
const POS_SCREEN_PAY = gql`
  mutation (
    $orderInfo: POSOrderInput!
    $orderItems: [POSOrderItemInput]
    $redeemPoint: Decimal
  ) {
    createPosOrder(
      input: {
        orderInfo: $orderInfo
        orderItems: $orderItems
        redeemPoint: $redeemPoint
      }
    ) {
      errors
      success
      message
      order {
        id
        pk
        createdBy {
          username
        }
        customer {
          pk
          id
          user {
            id
            pk
            mobileNumber
            email
            fullName
          }
        }
        table {
          id
          pk
          status
          title
        }
        kitchenOrder {
          id
          pk
          createdBy {
            username
          }
        }
        orderItems {
          edges {
            node {
              id
              pk
              totalPrice
              discount
              surCharge
              grossAmount
              tax
              quantity
              product {
                id
                pk
                title
                finalPrice
                sellingPrice
                costPrice
              }
              modifier {
                id
                pk
                title
                totalPrice
                discount
                grossAmount
                tax
                quantity
                product {
                  id
                  pk
                  title
                  finalPrice
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_SPLIT_PAY = gql`
  mutation (
    $orderInfo: SplitPaymentInfoInput!
    $orderItems: [POSOrderItemInput]
  ) {
    makeSplitPayment(
      input: { orderInfo: $orderInfo, orderItems: $orderItems }
    ) {
      errors
      success
      message
      order {
        id
        pk
        createdBy {
          username
        }
        customer {
          pk
          id
          user {
            id
            pk
            mobileNumber
            email
            fullName
          }
        }
        table {
          id
          pk
          status
          title
        }
        kitchenOrder {
          id
          pk
          createdBy {
            username
          }
        }
        orderItems {
          edges {
            node {
              id
              pk
              totalPrice
              discount
              surCharge
              grossAmount
              tax
              quantity
              product {
                id
                pk
                title
                finalPrice
                sellingPrice
                costPrice
              }
              modifier {
                id
                pk
                title
                totalPrice
                discount
                grossAmount
                tax
                quantity
                product {
                  id
                  pk
                  title
                  finalPrice
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_ADD_TO_CREDIT = gql`
  mutation (
    $orderInfo: POSAddToCreditInput!
    $orderItems: [POSOrderItemInput]
  ) {
    addToCredit(input: { orderInfo: $orderInfo, orderItems: $orderItems }) {
      errors
      success
      order {
        id
        pk
        paymentStatus
        totalPrice
        tax
        serviceCharge
        grossAmount
        table {
          id
          pk
          title
          capacity
        }
        customer {
          id
          pk
          user {
            fullName
          }
        }
        orderItems {
          edges {
            node {
              id
              pk
              totalPrice
              discount
              grossAmount
              tax
              quantity
              product {
                id
                pk
                title
                finalPrice
                sellingPrice
                costPrice
              }
              modifier {
                id
                pk
                title
                totalPrice
                discount
                grossAmount
                tax
                quantity
                product {
                  id
                  pk
                  title
                  finalPrice
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
    }
  }
`;
const POS_SCREEN_CLOCK_IN_CLOCK_OUT = gql`
  mutation {
    toggleClockInClockOut(input: {}) {
      errors
      success
      message
      clockInClockOut {
        id
        clockIn
        clockOut
      }
    }
  }
`;

const MODIFY_ORDER_STATUS = gql`
  mutation MODIFY_ORDER_STATUS($id: String!, $orderStatus: String) {
    modifyOrderStatus(input: { id: $id, orderStatus: $orderStatus }) {
      success
      errors
    }
  }
`;
const ACCEPT_POS_PAYMENT = gql`
  mutation ($orderId: String!, $account: String!, $amount: String!) {
    acceptPosPayment(
      input: { orderId: $orderId, account: $account, amount: $amount }
    ) {
      success
      errors
      orderInfo {
        paymentStatus
      }
    }
  }
`;
const TOGGLE_TERMINAL = gql`
  mutation ($terminalId: String!) {
    toggleTerminal(input: { terminalId: $terminalId }) {
      errors
      success
      message
    }
  }
`;

export const updataPosScreenCategory = gql`
  mutation ($id: String!, $category: String!) {
    assignCategory(input: { id: $id, category: $category }) {
      posScreenCategory {
        id
        pk
        category {
          name
          id
          pk
        }
      }
      errors
      success
    }
  }
`;
export const UPDATE_POS_SCREEN_PRODUCT = gql`
  mutation ($id: String!, $product: String!) {
    assignProduct(input: { id: $id, product: $product }) {
      errors
      success
      posScreenProduct {
        id
        pk
        product {
          id
          pk
          title
        }
        posScreenCategory {
          id
          pk
          category {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const REMOVE_ASSIGNED_PRODUCT = gql`
  mutation ($id: String!) {
    removeAssignedProduct(input: { id: $id }) {
      errors
      success
    }
  }
`;

export const REMOVE_ASSIGNED_CATEGORY = gql`
  mutation ($id: String!) {
    removeAssignedCategory(input: { id: $id }) {
      errors
      success
    }
  }
`;

export const ASSIGN_COLOR = gql`
  mutation ($type: String!, $colorCode: String!, $id: String!) {
    assignColorCode(input: { type: $type, colorCode: $colorCode, id: $id }) {
      errors
      success
      message
    }
  }
`;
export const UPDATE_MODIFIER_PLACEHOLDER = gql`
  mutation ($id: String!, $modifierProduct: String!, $categoryId: String!) {
    assignModifier(
      input: {
        id: $id
        modifierProduct: $modifierProduct
        categoryId: $categoryId
      }
    ) {
      posScreenModifier {
        id
        color
        pk
      }
      errors
      success
    }
  }
`;

export const REMOVE_ASSIGNED_MODIFIER = gql`
  mutation ($id: String!, $categoryId: String!) {
    removeAssignedModifiers(input: { id: $id, categoryId: $categoryId }) {
      errors
      success
    }
  }
`;
export {
  POS_SCREEN_CREATE_CUSTOMER,
  POS_SCREEN_CREATE_CUSTOMER_ADDRESS,
  POS_SCREEN_CREATE_KITCHEN_ORDER,
  POS_SCREEN_HOLD_KITCHEN_ORDER_LIST,
  POS_SCREEN_PAY,
  POS_SCREEN_SPLIT_PAY,
  POS_SCREEN_ADD_TO_CREDIT,
  POS_SCREEN_CREATE_MODIFIED_KITCHEN_ORDER,
  POS_SCREEN_CLOCK_IN_CLOCK_OUT,
  MODIFY_ORDER_STATUS,
  ACCEPT_POS_PAYMENT,
  TOGGLE_TERMINAL,
};
