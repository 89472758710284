import { gql } from "@apollo/client";
const POS_SCREEN_CLOCK_IN_OUT = gql`
  query {
    clockInStatus
  }
`;
const POS_SCREEN_QUERY_DASHBOARD = gql`
  query {
    dashboardReportObjectCount {
      customers
      orders
      categories
      users
      kitchenOrders
      products
      modifierMaterial
      income
      expense
      profitLoss
      customerCredit
      todayExpense
      todayIncome
      todayProfitLoss
      todayCredit
    }
  }
`;
const POS_SCREEN_ORDER_HISTORY = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardReportOrderHistory(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        label
        orders
      }
    }
  }
`;
const POS_SCREEN_TRANSACTION_HISTORY = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardReportTransactionHistory(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      data {
        label
        expense
        income
      }
    }
  }
`;

const SEARCH_PRODUCT_ITEMS = gql`
  query ($search: String, $barcode: String, $plu: String) {
    products(
      search: $search
      barcodeContent: $barcode
      plu: $plu
      isOpenProduct: false
    ) {
      edges {
        node {
          pk
          id
          title
          isWeightable
          isModifier
          isOpenPriceProduct
          image
          taxAmount
          tax {
            id
            pk
            rate
            isIncluded
          }
          finalPrice
          sellingPrice
          costPrice
          compound {
            id
            pk
            title
            sellingPrice
          }
          modifierCategories {
            edges {
              node {
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;

const ROOT_CATEGORIES_QUERY = gql`
  query ($first: Int, $offset: Int, $category_Name_Icontains: String) {
    posScreenCategories(
      first: $first
      offset: $offset
      category_Name_Icontains: $category_Name_Icontains
    ) {
      edges {
        node {
          id
          pk
          color
          category {
            id
            pk
            name
            image
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

const POS_SCREEN_PRODUCT_BY_ID = gql`
  query ($id: ID!) {
    posScreenProduct(id: $id) {
      product {
        id
        pk
        title
        finalPrice
        sellingPrice
        costPrice
      }
    }
  }
`;
const POS_SCREEN_CUSTOMER_LIST = gql`
  query customers(
    $first: Int
    $offset: Int
    $after: String
    $before: String
    $search: String
  ) {
    customers(
      first: $first
      offset: $offset
      after: $after
      before: $before
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          outstandingBalance
          todayCredit
          user {
            id
            pk
            email
            mobileNumber
            fullName
          }
        }
      }
    }
  }
`;

const CUSTOMER_REWARD_POINTS_BY_ID = gql`
  query ($user: ID!, $first: Int, $offset: Int) {
    rewardPoints(user: $user, first: $first, offset: $offset) {
      edges {
        node {
          purchaseAmount
          rewardPoint
          perAmount
          perAmountPoint
          order {
            id
            pk
            getInvoiceNumber
          }
        }
      }
    }
  }
`;

const POS_SCREEN_CUSTOMER_LIST_BY_ID = gql`
  query ($id: ID!) {
    customer(id: $id) {
      outstandingBalance
      creditAmount
      rewardPoints
      user {
        id
        pk
        email
        mobileNumber
        fullName
      }
      id
      pk
    }
  }
`;
const POS_SCREEN_GET_QUERY_CUSTOMER_TRANSACTIONS_VIEW = gql`
  query (
    $first: Int
    $offset: Int
    $transaction_User: ID!
    $transaction_Created_Gte: DateTime
    $transaction_Created_Lte: DateTime
  ) {
    customerTransactionsView(
      first: $first
      offset: $offset
      transaction_User: $transaction_User
      transaction_Created_Gte: $transaction_Created_Gte
      transaction_Created_Lte: $transaction_Created_Lte
    ) {
      edges {
        node {
          id
          pk
          balance
          transaction {
            created
            id
            pk
            objectId
            debit
            credit
            note
            order {
              id
              pk
              paymentStatus
              getInvoiceNumber
              discount
              totalPrice
              grossAmount
              serviceCharge
              tax
              orderItems {
                edges {
                  node {
                    id
                    pk
                    title
                    quantity
                    price
                    totalPrice
                  }
                }
              }
            }
            user {
              id
              pk
              mobileNumber
              email
              fullName
              profile {
                id
                pk
                email
                contactNumber
                image
                dobNepali
                dobEnglish
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;
const POS_SCREEN_TABLE_ITEM_BY_WITH_NO_TABLE_ORDER_ITEMS = gql`
  query ($first: Int, $offset: Int, $isVisible: Boolean) {
    restaurantTables(first: $first, offset: $offset, isVisible: $isVisible) {
      edges {
        node {
          pk
          id
          title
          status
          capacity
          note
          isMerged
          isMergedTable
          isVisible
          currentKitchenOrder {
            id
            pk
          }
        }
      }
    }
  }
`;
const POS_SCREEN_TABLE_ITEM = gql`
  query ($first: Int, $offset: Int, $isVisible: Boolean) {
    restaurantTables(first: $first, offset: $offset, isVisible: $isVisible) {
      edges {
        node {
          pk
          id
          title
          status
          capacity
          note
          isMerged
          isMergedTable
          isVisible
          currentKitchenOrder {
            id
            pk
          }
          tableOrdersKitchen {
            edges {
              node {
                id
                pk
                kitchenOrderItems {
                  modifiedKitchenOrderItems {
                    edges {
                      node {
                        id
                        pk
                      }
                    }
                  }
                  kitchenOrder {
                    id
                    pk
                    customer {
                      pk
                      id
                      user {
                        id
                        pk
                        mobileNumber
                        email
                        fullName
                      }
                    }
                  }
                  id
                  pk
                  quantity
                  product {
                    id
                    pk
                    title
                    finalPrice
                    sellingPrice
                    costPrice
                  }
                  modifier {
                    id
                    pk
                    quantity
                    product {
                      id
                      pk
                      title
                      finalPrice
                      sellingPrice
                      costPrice
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_TABLE_ITEM_BY_ID_WITH_CURRENT_KITCHEN_ORDER = gql`
  query ($id: ID!) {
    restaurantTable(id: $id) {
      id
      pk
      title
      status
      capacity
      note
      isSelected
      currentKitchenOrder {
        pk
        id
        orderMethod {
          title
        }
        customer {
          pk
          id
          user {
            id
            pk
            mobileNumber
            email
            fullName
          }
        }
        kitchenOrderItems {
          edges {
            node {
              id
              pk
              quantity
              product {
                id
                pk
                title
                taxAmount
                finalPrice
                sellingPrice
                costPrice
                tax {
                  isIncluded
                  rate
                }
                image
              }
              modifier {
                id
                pk
                quantity
                product {
                  id
                  pk
                  title
                  taxAmount
                  finalPrice
                  sellingPrice
                  costPrice
                  tax {
                    isIncluded
                    rate
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_TABLE_ITEM_BY_ID = gql`
  query ($id: ID!) {
    restaurantTable(id: $id) {
      id
      pk
      title
      status
      capacity
      note
      currentKitchenOrder {
        pk
        id
        kitchenOrderItems {
          edges {
            node {
              id
              pk
              quantity
              product {
                id
                pk
                title
                taxAmount
                finalPrice
                sellingPrice
                costPrice
                image
              }
              modifier {
                id
                pk
                quantity
                product {
                  id
                  pk
                  title
                  taxAmount
                  finalPrice
                  sellingPrice
                  costPrice
                }
              }
            }
          }
        }
      }
      tableOrdersKitchen {
        edges {
          node {
            id
            pk
            kitchenOrderItems {
              edges {
                node {
                  modifiedKitchenOrderItems {
                    edges {
                      node {
                        id
                        pk
                      }
                    }
                  }
                  kitchenOrder {
                    id
                    pk
                    customer {
                      pk
                      id
                      user {
                        id
                        pk
                        mobileNumber
                        email
                        fullName
                      }
                    }
                  }
                  id
                  pk
                  quantity
                  product {
                    id
                    pk
                    title
                    taxAmount
                    finalPrice
                    sellingPrice
                    costPrice
                  }
                  modifier {
                    id
                    pk
                    quantity
                    product {
                      id
                      pk
                      title
                      taxAmount
                      finalPrice
                      sellingPrice
                      costPrice
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_TABLE_UPDATE_FLOOR_LAYOUT = gql`
  mutation ($id: String!, $layout: JSONString) {
    updateFloorLayout(input: { id: $id, layout: $layout }) {
      errors
      success
      messages
    }
  }
`;

const POS_SCREEN_KITCHEN_ORDER_ITEMS = gql`
  query kitchenOrder($id: ID!) {
    kitchenOrder(id: $id) {
      id
      pk
      getInvoiceNumber
      orderStatus
      orderMethod {
        pk
        title
      }
      note
      createdBy {
        username
        fullName
      }
      customer {
        pk
        id
        user {
          id
          pk
          mobileNumber
          email
          fullName
          profile {
            fullAddress
          }
        }
      }
      table {
        pk
        id
        status
        title
        currentKitchenOrder {
          id
        }
      }
      kitchenOrderItems {
        id
        pk
        quantity
        note
        modifiers {
          edges {
            node {
              id
              pk
              quantity
              note
              product {
                id
                pk
                title
                taxAmount
                tax {
                  rate
                  isIncluded
                }
                finalPrice
                sellingPrice
              }
            }
          }
        }
        product {
          id
          pk
          title
          taxAmount
          tax {
            rate
            isIncluded
          }
          finalPrice
          sellingPrice
        }
      }
    }
  }
`;
const POS_SCREEN_BASIC_SETTING_CONFIGURATION = gql`
  query {
    basicWebsiteConfigurations {
      logo
      pk
      currency
      serviceCharge {
        id
        pk
        rate
        title
      }
    }
  }
`;

const POS_SCREEN_ORDERED_ITEMS = gql`
  query ($id: ID!) {
    order(id: $id) {
      id
      pk
      getInvoiceNumber
      accountFee
      authorizedBy
      created
      note
      terminal
      reason
      orderMethod {
        title
      }
      kitchenOrder {
        id
        pk
        getInvoiceNumber
      }
      createdBy {
        id
        pk
        username
        fullName
      }

      note
      totalPrice
      discount
      surCharge
      tax
      serviceCharge
      grossAmount
      paymentStatus
      paymentMethod
      orderSource
      orderStatus
      changeAmount
      paidAmount
      orderTransactions {
        edgeCount
        totalCount
      }

      customer {
        id
        pk
        user {
          id
          pk
          username
          isStaff
          mobileNumber
          fullName
          profile {
            id
            pk
            email
          }
        }
      }
      table {
        id
        pk
        title
        status
      }
      orderMethod {
        id
        title
      }
      orderItems {
        edges {
          node {
            id
            pk
            quantity
            discount
            totalPrice
            grossAmount
            price
            title
            product {
              id
              pk
              title
              finalPrice
              sellingPrice
              costPrice
              taxAmount
              tax {
                id
                pk
                rate
                isIncluded
              }
            }
            modifier {
              id
              pk
              title
              quantity
              totalPrice
              discount
              product {
                id
                pk
                finalPrice
                sellingPrice
                costPrice
                taxAmount
                tax {
                  id
                  pk
                  rate
                  isIncluded
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_CUSTOMER_ORDERED_ITEMS = gql`
  query (
    $first: Int
    $offset: Int
    $paymentStatus_Icontains: String
    $orderSource: String
    $customer_Id: String
  ) {
    orders(
      first: $first
      offset: $offset
      search: $paymentStatus_Icontains
      orderSource: $orderSource
      customer_Id: $customer_Id
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          created
          customer {
            id
            pk
            user {
              id
              pk
              email
              mobileNumber
              fullName
            }
          }
          orderItems {
            edges {
              node {
                id
                pk
                totalPrice
                discount
                grossAmount
                tax
                quantity
                product {
                  id
                  pk
                  title
                  finalPrice
                  sellingPrice
                  costPrice
                }
                modifier {
                  id
                  pk
                  title
                  totalPrice
                  discount
                  grossAmount
                  tax
                  quantity
                  product {
                    id
                    pk
                    title
                    finalPrice
                    sellingPrice
                    costPrice
                  }
                }
              }
            }
          }
          kitchenOrder {
            id
            pk
            getInvoiceNumber
          }
          paymentStatus
          totalPrice
          grossAmount
          orderSource
          orderStatus
          orderMethod {
            title
          }
        }
      }
    }
  }
`;
const POS_SCREEN_ORDERED_ITEMS_LIST = gql`
  query (
    $first: Int
    $offset: Int
    $paymentStatus_Icontains: String
    $orderSource: String
    $customerId: ID
  ) {
    orders(
      first: $first
      offset: $offset
      search: $paymentStatus_Icontains
      orderSource: $orderSource
      customer: $customerId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          created
          createdBy {
            id
            pk
            fullName
          }
          table {
            title
          }
          customer {
            id
            pk
            user {
              id
              pk
              email
              mobileNumber
              fullName
            }
          }
          kitchenOrder {
            id
            pk
            getInvoiceNumber
          }
          paymentStatus
          totalPrice
          grossAmount
          orderSource
          orderStatus
          orderMethod {
            title
          }
        }
      }
    }
  }
`;
const POS_SCREEN_TRANSACTONS = gql`
  query (
    $first: Int
    $offset: Int
    $after: String
    $before: String
    $created_Icontains: DateTime
  ) {
    transactions(
      first: $first
      offset: $offset
      after: $after
      before: $before
      created_Icontains: $created_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          created
          credit
          debit
          balance
          note
          order {
            id
            pk
          }
          order {
            customer {
              id
              pk
              user {
                id
                pk
                email
                username
                fullName
              }
            }
          }
        }
      }
    }
  }
`;

const POS_SCREEN_LAST_DOCKET = gql`
  query {
    printLastDocket {
      id
      pk
      getInvoiceNumber
      accountFee
      kitchenOrder {
        getInvoiceNumber
      }
      createdBy {
        id
        pk
        username
        fullName
      }
      totalPrice
      discount
      amountAfterDiscount
      grossAmount
      tax
      serviceCharge
      surCharge
      accountFee
      paidAmount
      changeAmount
      paymentMethod
      note
      table {
        id
        pk
        title
        capacity
      }
      customer {
        id
        pk
        user {
          fullName
        }
      }
      orderItems {
        edges {
          node {
            id
            pk
            totalPrice
            discount
            grossAmount
            tax
            quantity
            product {
              id
              pk
              title
              finalPrice
              sellingPrice
              costPrice
            }
            modifier {
              id
              pk
              title
              totalPrice
              discount
              grossAmount
              tax
              quantity
              product {
                id
                pk
                title
                finalPrice
                sellingPrice
                costPrice
              }
            }
          }
        }
      }
    }
  }
`;
const POS_SCREEN_PRINTERS_LIST = gql`
  query {
    printers {
      edges {
        node {
          id
          pk
          name
          department
          location
          port
          type
        }
      }
    }
  }
`;
const CURRENT_TERMINAL = gql`
  query {
    currentTerminal {
      id
      pk
      terminal {
        id
        pk
        printer {
          location
          name
          type
        }
        terminalsetting {
          id
          isActive
          isScreenSaveOnly
          slideDuration
          isOpenPopupAfterPayment
          netAmount
          amountAfterDiscount
          gst
          surCharge
          serviceCharge
          eftposCharge
          grossAmount
          openPopupAfterRemotePrint
          serviceChargeToggleCheckbox
          compulsoryKitchenPrint
          scalePort
          scaleBaudRate
          scaleDateBits
          scaleParity
          scaleStopBits
          isActiveScale
          pk
          screensaverimageSet {
            edges {
              node {
                pk
                image
              }
            }
          }
        }
      }
    }
  }
`;
const CURRENT_TERMINAL_FIRST = gql`
  query {
    currentTerminal {
      id
      pk
      terminal {
        id
        pk
        terminalsetting {
          id
          isActive
          isScreenSaveOnly
          slideDuration
          isOpenPopupAfterPayment
          netAmount
          amountAfterDiscount
          gst
          surCharge
          serviceCharge
          eftposCharge
          grossAmount
          openPopupAfterRemotePrint
          serviceChargeToggleCheckbox
          compulsoryKitchenPrint
          scalePort
          scaleBaudRate
          scaleDateBits
          scaleParity
          scaleStopBits
          isActiveScale
          pk
          screensaverimageSet {
            edges {
              node {
                pk
                image
              }
            }
          }
        }
      }
    }
  }
`;

export const useRootCatagories = gql`
  query ($name_Icontains: String) {
    categories(first: 10, name_Icontains: $name_Icontains) {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
`;

export const usePosScreenCategories = gql`
  query PosScreenCategories($after: String, $before: String) {
    posScreenCategories(first: 14, after: $after, before: $before) {
      edges {
        node {
          pk
          id
          order
          color
          category {
            pk
            id
            name
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
export const usePosScreenProductByCategory = gql`
  query ($posScreenCategory: ID, $offset: Int) {
    posScreenProducts(
      first: 98
      offset: $offset
      posScreenCategory: $posScreenCategory
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          color
          product {
            id
            pk
            isActive
            isModifier
            barcodeContent
            plu
            title
            image
            costPrice
            sellingPrice
            unit {
              title
              pk
              id
            }
            isOpenPriceProduct
            tax {
              id
              pk
              title
            }
            isModifierCategoryGrid
            isWeightable
            modifierCategories {
              edges {
                node {
                  id
                  pk
                  product {
                    id
                    pk
                    title
                  }
                }
              }
            }
            category {
              edges {
                node {
                  id
                  pk
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const usePosScreenProductByCategoryWithIds = gql`
  query ($id: ID!) {
    posScreenProduct(id: $id) {
      pk
      id
      product {
        pk
        id
        title
        productStocks {
          threshold
          availableQuantity
        }
        isWeightable
        isModifierCategoryGrid
        isOpenPriceProduct
        isActive
        isModifier
        image
        taxAmount
        promotionInfo {
          id
          pk
          title
          sellingPrice
          modifierCategories {
            edges {
              node {
                id
                pk
              }
            }
          }
          taxAmount
          tax {
            id
            pk
            title
            rate
            isIncluded
          }
          compound {
            id
            pk
            title
            sellingPrice
          }
        }
        tax {
          id
          pk
          title
          rate
          isIncluded
        }
        finalPrice
        sellingPrice
        costPrice
        modifierCategories {
          edges {
            node {
              id
              pk
              category {
                pk
                name
                productsByCategory {
                  edges {
                    node {
                      title
                      id
                      pk
                      sellingPrice
                      tax {
                        rate
                        isIncluded
                      }
                      taxAmount
                      image
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const usePosScreenProductByCategoryWithId = gql`
  query ($posScreenCategory: ID, $offset: Int) {
    posScreenProducts(
      first: 98
      offset: $offset
      posScreenCategory: $posScreenCategory
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          product {
            pk
            id
            title
            isModifier
            image
            taxAmount
            tax {
              id
              pk
              title
              rate
              isIncluded
            }
            finalPrice
            sellingPrice
            costPrice
            modifierCategory {
              edges {
                node {
                  id
                  pk
                  name
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const PRODUCTS_BY_CATEGORY = gql`
  query ($category: [ID], $search: String) {
    products(category: $category, search: $search) {
      edges {
        node {
          id
          pk
          title
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_BY_MODIFIERCATEGORY = gql`
  query PRODUCTS_BY_MODIFIERCATEGORY(
    $modifierCategoryId: String
    $categoryId: String
  ) {
    modifierPlaceHolders {
      edges {
        node {
          id
          pk
          modifierOrderPlaceholder(
            modifierCategoryId: $modifierCategoryId
            categoryId: $categoryId
          ) {
            id
            pk
            modifierPlaceholder {
              order
            }
            color
            modifierProduct {
              title
              pk
              id
              image
            }
          }
        }
      }
    }
  }
`;

export {
  POS_SCREEN_CLOCK_IN_OUT,
  POS_SCREEN_QUERY_DASHBOARD,
  POS_SCREEN_ORDER_HISTORY,
  POS_SCREEN_TRANSACTION_HISTORY,
  SEARCH_PRODUCT_ITEMS,
  ROOT_CATEGORIES_QUERY,
  POS_SCREEN_PRODUCT_BY_ID,
  POS_SCREEN_CUSTOMER_LIST,
  CUSTOMER_REWARD_POINTS_BY_ID,
  POS_SCREEN_CUSTOMER_LIST_BY_ID,
  POS_SCREEN_GET_QUERY_CUSTOMER_TRANSACTIONS_VIEW,
  POS_SCREEN_TABLE_ITEM,
  POS_SCREEN_TABLE_ITEM_BY_ID,
  POS_SCREEN_TABLE_UPDATE_FLOOR_LAYOUT,
  POS_SCREEN_KITCHEN_ORDER_ITEMS,
  POS_SCREEN_BASIC_SETTING_CONFIGURATION,
  POS_SCREEN_CUSTOMER_ORDERED_ITEMS,
  POS_SCREEN_ORDERED_ITEMS,
  POS_SCREEN_ORDERED_ITEMS_LIST,
  POS_SCREEN_TRANSACTONS,
  POS_SCREEN_LAST_DOCKET,
  POS_SCREEN_PRINTERS_LIST,
  POS_SCREEN_TABLE_ITEM_BY_WITH_NO_TABLE_ORDER_ITEMS,
  POS_SCREEN_TABLE_ITEM_BY_ID_WITH_CURRENT_KITCHEN_ORDER,
  CURRENT_TERMINAL,
  CURRENT_TERMINAL_FIRST,
};
