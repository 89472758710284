import React from 'react'
import eftpos from '../../../../../assets/eftPosImage.png'
const DeviceNotConnected = () => {
  return (
    <div className="w-full mx-auto">
      <div className="py-4 w-full mx-auto text-center">
        <h1 className="text-3xl w-full font-medium my-2">
          EFTPOS Pinpad is not connected
        </h1>
        <p className="text-lg w-full my-3 font-medium leading-6">
          Please ensure that the EFTPOS pinpad is properly connected by
          checking the cables. If there are any connectivity issues, resolve
          them before proceeding.
        </p>
        <img src={eftpos} alt="eptosimg" className="mx-auto w-full" />
        <p className="text-lg my-3 w-full font-medium leading-6">
          To restart the EFTPOS terminal, press and hold the 'Function' and
          'Clear' buttons simultaneously for 3 to 5 seconds. After the
          restart, attempt the transaction again. If the issue persists,
          kindly seek further assistance for support.
        </p>
      </div>
    </div>
  )
}

export default DeviceNotConnected