import React, { useState } from 'react'
import TextField from '../reusable/TextField'
import { useForm } from 'react-hook-form'
import PrimaryButton from '../reusable/Buttons/PrimaryButton'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { ADD_CATEGORY } from '../../pages/mutations'
import { ROOT_CATEGORIES_QUERY } from '../../pages/PosScreen/queries'
import { toast } from 'react-toastify'
import { QUERY_SETTINGS } from '../../pages/Settings/graphql'

type Props = {
  categoryId?: string;
  categoryLoading?: boolean;
  handleCreateCategory?: any;
}

const CategoryForm = (props: Props) => {
  const { categoryLoading, handleCreateCategory } = props
  const { register, setValue, getValues, handleSubmit } = useForm({})
  return (
    <>
      <div className="w-full flex justify-between items-center mb-4">
        <TextField register={register} name={"name"} label={"Title"} values={getValues("name")} />
        <div className='w-full'>
          <input
            type="checkbox"
            name="status"
            value={getValues("status")}
            onChange={(e: any) =>
              setValue("status", e.target.checked)
            }
            checked={getValues("status")}
          />
          <label htmlFor="status" className="ml-2">
            Is Modifier?
          </label>
        </div>
      </div>
      <PrimaryButton
        text={"Save"}
        onClick={handleSubmit(handleCreateCategory)}
        loading={categoryLoading}
        disable={categoryLoading}
      />

    </>
  )
}

export default CategoryForm