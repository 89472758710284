import { useQuery } from "@apollo/client";
import { useState } from "react";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationState from "../../../customhooks/usePaginationHook";
import { POS_KITCHEN_ORDER_LISTS } from "../../Orders/queries";

const PosScreenKitchenOrderComponent: any = Loadable({
  loader: () =>
    import("../../../components/Orders/KitchenOrdersList/KitchenOrderList"),
  loading: DataLoadSpinner,
});

const PosScreenKitchenOrder = () => {
  const { pageSize, setPageSize, offset, setOffset } = usePaginationState();

  const [state, setState] = useState("");
  const { loading, data } = useQuery(POS_KITCHEN_ORDER_LISTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      orderStatus: state,
      first: pageSize,
      offset: offset,
    },
  });
  return (
    <div className="w-full">
      <PosScreenKitchenOrderComponent
        isLoading={loading}
        data={data?.kitchenOrders ?? []}
        isPosScreenKitchenOrder={true}
        state={state}
        setState={setState}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
};

export default PosScreenKitchenOrder;
