import { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { QUERY_SETTINGS } from "../Settings/graphql";
import { ROOT_CATEGORIES_QUERY, usePosScreenProductByCategory } from "./queries";
import PosScreenComponent from "../../components/PosScreenType/AustraliaPosScreen/PosScreen";

const PosTypeScreen = () => {
  const [offsetCat, setOffsetCat] = useState<number>(0);
  const { data: basicSetting, loading } = useQuery(QUERY_SETTINGS);
  const [posScreenLoading, setPosScreenLoading] = useState<boolean>(false);
  const [productByCatId, setProductByCatId] = useState<any>(null)
  const [getCategory, { data: categoryData }] = useLazyQuery(ROOT_CATEGORIES_QUERY)
  const [getProduct, { data: posScreenProductByCategoryData }] = useLazyQuery(usePosScreenProductByCategory)
  const handleCategoryFetch = async () => {
    setPosScreenLoading(true)
    const categoryResponse = await getCategory({
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions,
      variables: {
        first: parseInt(basicSetting?.basicWebsiteConfigurations?.noOfColumns || 7) * 2 ?? 0,
        offset: offsetCat,
        category_Name_Icontains: "",
      },
    })

    await getProduct({
      variables: {
        offset: 0,
        posScreenCategory: categoryResponse?.data?.posScreenCategories?.edges?.[0]?.node?.id,
      },
    })
    setPosScreenLoading(false)
  }

  const handleFetchproductByCategory = async (categoryID: string | null = null) => {
    if (!categoryID) {
      handleCategoryFetch()
    } else {
      setProductByCatId(categoryID)
      await getProduct({
        variables: {
          offset: 0,
          posScreenCategory: categoryID,
          isModifier: false,
        },
      })
    }
  }

  useEffect(() => {
    handleFetchproductByCategory()
    setProductByCatId(categoryData?.posScreenCategories?.edges?.[0]?.node?.id)
  }, [basicSetting?.basicWebsiteConfigurations?.noOfColumns, categoryData?.posScreenCategories?.edges?.[0]?.node?.id])
  const handleNextPage = async () => {
    const categorySet = offsetCat + (parseInt(basicSetting?.basicWebsiteConfigurations?.noOfColumns) * 2)
    setOffsetCat(categorySet);
    await getCategory({
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions,
      variables: {
        first: parseInt(basicSetting?.basicWebsiteConfigurations?.noOfColumns || 7) * 2 ?? 0,
        offset: categorySet,
        category_Name_Icontains: "",
      },
    })
  };
  const handlePreviousPage = async () => {
    const categorySet = offsetCat > 0 ? offsetCat - (parseInt(basicSetting?.basicWebsiteConfigurations?.noOfColumns) * 2) : 0
    setOffsetCat(categorySet);
    await getCategory({
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions,
      variables: {
        first: parseInt(basicSetting?.basicWebsiteConfigurations?.noOfColumns || 7) * 2 ?? 0,
        offset: categorySet,
        category_Name_Icontains: "",
      },
    })
  };

  const handleCategoryRefetch = async () => {
    await getCategory({
      fetchPolicy: "network-only", // Used for first execution
      nextFetchPolicy: "cache-first", // Used for subsequent executions,
      variables: {
        first: parseInt(basicSetting?.basicWebsiteConfigurations?.noOfColumns || 7) * 2 ?? 0,
        offset: offsetCat,
        category_Name_Icontains: "",
      },
    })
  }

  const handleProductRefetch = async () => {
    await getProduct({
      variables: {
        offset: 0,
        posScreenCategory: productByCatId,
      },
    })
  }

  return (
    <div className="w-full h-full">
      {loading ? "" :
        <PosScreenComponent
          isLoading={loading}
          categoriesData={categoryData}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          handleCategoryClick={handleFetchproductByCategory}
          handleCategoryRefetch={handleCategoryRefetch}
          posScreenProductByCategoryDataLoading={
            posScreenLoading
          }
          handleProductRefetch={handleProductRefetch}
          productsData={posScreenProductByCategoryData}
          productByCatId={productByCatId}
          noOfColumns={basicSetting?.basicWebsiteConfigurations?.noOfColumns || 7}
        />
      }
    </div>
  );
};

export default PosTypeScreen;
