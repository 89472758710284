import { gql } from "@apollo/client";

export const PROFILE = gql`
  query PROFILE {
    me {
      id
      username
      email
      mobileNumber
      dateJoined
      assignPermissions {
        id
        pk
        name
        codename
      }
    }
  }
`;

export const LISCENSE_KEY = gql`
  query {
    initialLicenseKey
  }
`;
export const ACTIVATION_INFO = gql`
  query {
    activationInfo {
      id
      licenseKey
      expireDate
      isOnTrial
      activationDate
      isActive
      clientName
    }
  }
`;
export const CUSTOM_PERMISSIONS = gql`
  query {
    customPermissions
  }
`;

export const QUERY_UNIT = gql`
  query {
    units(first: 100) {
      edges {
        node {
          id
          title
          pk
        }
      }
    }
  }
`;

export const QUERY_CATEGORY = gql`
  query QUERY_CATEGORY(
    $first: Int
    $level: Int
    $offset: Int
    $name_Icontains: String
  ) {
    categories(
      first: $first
      level: $level
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          id
          pk
          name
          status
          totalItems
          image
        }
      }
    }
  }
`;

export const GET_CATEGORY = gql`
  query GET_CATEGORY($id: ID!) {
    category(id: $id) {
      id
      name
      pk
      status
      image
      posscreencategorySet {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const QUERY_TAX = gql`
  query QUERY_TAX(
    $first: Int
    $after: String
    $before: String
    $title_Icontains: String
  ) {
    taxes(
      first: $first
      after: $after
      before: $before
      title_Icontains: $title_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          title
          rate
          isIncluded
        }
      }
    }
  }
`;

export const GET_TAX = gql`
  query GET_TAX($id: ID!) {
    tax(id: $id) {
      id
      title
      rate
      pk
      isIncluded
    }
  }
`;

export const QUERY_PRINTER = gql`
  query QUERY_PRINTER(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $name_Icontains: String
  ) {
    printers(
      first: $first
      after: $after
      before: $before
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          name
          department
          type
          location
        }
      }
    }
  }
`;

export const GET_PRINTER = gql`
  query GET_PRINTER($id: ID!) {
    printer(id: $id) {
      id
      name
      department
      type
      location
      pk
    }
  }
`;

export const QUERY_SERVICE_CHARGES = gql`
  query QUERY_SERVICE_CHARGE(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
  ) {
    serviceCharges(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          title
          rate
        }
      }
    }
  }
`;

export const GET_SERVICE_CHARGE = gql`
  query GET_SERVICE_CHARGE($id: ID!) {
    serviceCharge(id: $id) {
      id
      title
      rate
      pk
    }
  }
`;

export const QUERY_POS_DISCOUNT = gql`
  query QUERY_POS_DISCOUNT(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $discountType: String
    $title: String
  ) {
    discounts(
      first: $first
      after: $after
      before: $before
      offset: $offset
      discountType: $discountType
      title: $title
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          discountType
          title
          rateAmount
        }
      }
    }
  }
`;

export const GET_POS_DISCOUNT = gql`
  query GET_POS_DISCOUNT($id: ID!) {
    discount(id: $id) {
      title
      discountType
      rateAmount
      pk
      id
    }
  }
`;

export const QUERY_RESTAURANT_TABLE = gql`
  query QUERY_RESTAURANT_TABLE(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
    $subTable_Isnull: Boolean
    $isVisible: Boolean
  ) {
    restaurantTables(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
      subTable_Isnull: $subTable_Isnull
      isVisible: $isVisible
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          capacity
          floor {
            title
          }
          status
          note
        }
      }
    }
  }
`;

export const GET_RESTAURANT_TABLE = gql`
  query GET_RESTAURANT_TABLE($id: ID!) {
    restaurantTable(id: $id) {
      title
      capacity
      floor {
        pk
        title
      }
      note
      pk
      id
    }
  }
`;
export const QUERY_RESTAURANT_FLOOR = gql`
  query QUERY_RESTAURANT_FLOOR(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
  ) {
    floors(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          note
        }
      }
    }
  }
`;
export const GET_RESTAURANT_FLOOR = gql`
  query GET_RESTAURANT_FLOOR($id: ID!) {
    floor(id: $id) {
      title
      note
      pk
      id
      layout

      tablesByFloor(isVisible: true, subTable_Isnull: true) {
        edges {
          node {
            pk
            id
            title
            status
            capacity
            note
            isMerged
            isMergedTable
            isVisible
            isSelected
            isSubTableVisible
            subTables(isVisible: true) {
              edges {
                node {
                  pk
                  id
                  title
                  status
                  capacity
                  note
                  isMerged
                  isMergedTable
                  isVisible
                  isSubTableVisible
                  currentKitchenOrder {
                    id
                    pk
                  }
                }
              }
            }
            currentKitchenOrder {
              id
              pk
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TERMINAL = gql`
  query QUERY_TERMINAL(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $name_Icontains: String
  ) {
    terminals(
      first: $first
      after: $after
      before: $before
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      edges {
        node {
          id
          pk
          name
          note
          printer {
            name
          }
        }
      }
    }
  }
`;
export const GET_TERMINAL = gql`
  query GET_TERMINAL($id: ID!) {
    terminal(id: $id) {
      id
      name
      printer {
        id
        name
        pk
      }
      note
      terminalsetting {
        id
        isActive
        isScreenSaveOnly
        slideDuration
        isOpenPopupAfterPayment
        netAmount
        amountAfterDiscount
        gst
        surCharge
        serviceCharge
        eftposCharge
        grossAmount
        openPopupAfterRemotePrint
        serviceChargeToggleCheckbox
        compulsoryKitchenPrint
        scalePort
        scaleBaudRate
        scaleDateBits
        scaleParity
        scaleStopBits
        isActiveScale
        pk
        screensaverimageSet {
          edges {
            node {
              pk
              image
            }
          }
        }
      }
      pk
    }
  }
`;

export const QUERY_USERS = gql`
  query QUERY_USERS(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $email_Icontains: String
    $isStaff: Boolean
    $isActive: Boolean
  ) {
    users(
      first: $first
      after: $after
      before: $before
      offset: $offset
      email_Icontains: $email_Icontains
      isStaff: $isStaff
      isActive: $isActive
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          fullName
          username
          attendancePin
          pk
          email
          mobileNumber
          isStaff
          isSuperuser
          isActive
          isCustomer
          groups {
            edges {
              node {
                name
              }
            }
          }
          fullName
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($id: ID!) {
    user(id: $id) {
      username
      email
      mobileNumber
      isStaff
      isSuperuser
      fullName
      pk
      assignPermissions {
        id
        name
        codename
        pk
      }
      groups {
        edges {
          node {
            id
            pk
          }
        }
      }
      profile {
        id
        pk
        firstName
        lastName
        middleName
        email
        contactNumber
        image
        dobNepali
        dobEnglish
        fullAddress
      }
    }
  }
`;
export const TOGGLE_USER_STATUS = gql`
  mutation ($userId: String, $status: String) {
    toggleUserStatus(input: { userId: $userId, status: $status }) {
      errors
      success
    }
  }
`;
export const CHECK_DATA_SYNC = gql`
  mutation {
    checkDataIsRequired(input: {}) {
      errors
      success
      message
    }
  }
`;
export const START_DATA_SYNC = gql`
  mutation {
    startDataSync(input: {}) {
      errors
      success
      message
    }
  }
`;

export const QUERY_ROLES = gql`
  query QUERY_ROLES(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $name_Icontains: String
  ) {
    roles(
      first: $first
      after: $after
      before: $before
      offset: $offset
      name_Icontains: $name_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          name
          userSet {
            edges {
              node {
                username
              }
            }
          }
          permissions {
            edges {
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ROLE = gql`
  query GET_ROLE($id: ID!) {
    role(id: $id) {
      name
      pk
      id
      userSet {
        edges {
          node {
            username
            email
            mobileNumber
          }
        }
      }
      permissions {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const QUERY_PERMISSIONS = gql`
  query QUERY_PERMISSIONS($first: Int, $after: String, $before: String) {
    permissions(first: $first, after: $after, before: $before) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          codename
          pk
          id
        }
      }
    }
  }
`;

export const QUERY_SMS_NOTIFICATION = gql`
  query QUERY_SMS_NOTIFICATION(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $mobileNumber_Icontains: String
  ) {
    smsNotifications(
      first: $first
      after: $after
      before: $before
      offset: $offset
      mobileNumber_Icontains: $mobileNumber_Icontains
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          user {
            id
            pk
            username
          }
          mobileNumber
          isActive
          id
          pk
        }
      }
    }
  }
`;

export const QUERY_SMS_PROVIDER = gql`
  query {
    dynamicSmsConfiguration {
      provider
      otherData
    }
  }
`;

export const QUERY_DASHBOARD = gql`
  query QUERY_DASHBOARD {
    dashboardReportObjectCount {
      customers
      orders
      categories
      users
      kitchenOrders
      products
      modifierMaterial
      income
      expense
      todaySale
      todayOrders
      profitLoss
      customerCredit
      todayExpense
      todayIncome
      todayProfitLoss
      todayCredit
    }
  }
`;

export const QUERY_SUPPLIER = gql`
  query QUERY_SUPPLIER(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
  ) {
    suppliers(
      first: $first
      after: $after
      before: $before
      offset: $offset
      search: $search
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          pk
          outstandingBalance
          supplierPurchaseHistory {
            edges {
              node {
                id
                pk
                created
                totalCostPrice
                cpAfterTax
                discount
                amountAfterDiscount
                taxAmount
                cpAfterTax
                product {
                  title
                }
              }
            }
          }
          user {
            id
            pk
            mobileNumber
            email
          }
        }
      }
    }
  }
`;

export const GET_SUPPLIER = gql`
  query GET_SUPPLIER(
    $id: ID!
    $first: Int
    $after: String
    $before: String
    $offset: Int
  ) {
    supplier(id: $id) {
      id
      supplierJournal(
        first: $first
        after: $after
        before: $before
        offset: $offset
      ) {
        edges {
          node {
            id
            pk
            created
            note
            balance
            credit
            debit
          }
        }
      }
      user {
        pk
        mobileNumber
        email
        profile {
          id
          pk
          firstName
          lastName
          middleName
          email
          contactNumber
          image
          dobNepali
          dobEnglish
          fullAddress
        }
      }
    }
  }
`;

export const QUERY_STOCK_HISTORIES = gql`
  query ($product: ID!, $offset: Int, $first: Int) {
    purchaseHistories(product: $product, offset: $offset, first: $first) {
      edges {
        node {
          created
          id
          pk
          costPrice
          quantity
          totalCostPrice
          discount
          amountAfterDiscount
          taxAmount
          cpAfterTax
          product {
            id
            pk
            title
          }
          supplier {
            id
            pk
            user {
              id
              pk
              email
              username
              profile {
                firstName
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TRANSACTIONS_VIEW = gql`
  query transactions(
    $first: Int
    $offset: Int
    $before: String
    $after: String
    $account: ID
    $createdGte: DateTime
    $createdLte: DateTime
    $account_IsVisible: Boolean
  ) {
    transactions(
      first: $first
      after: $after
      before: $before
      offset: $offset
      account: $account
      created_Gte: $createdGte
      created_Lte: $createdLte
      account_IsVisible: $account_IsVisible
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalBalance
      edges {
        node {
          id
          pk
          created
          debit
          credit
          note

          transactionSource
          createdBy {
            username
            fullName
          }
          terminal
          account {
            id
            pk
            name
            accountBalance
          }
          order {
            id
            pk
            getInvoiceNumber
            paymentStatus
            receiptId
            totalPrice
            paymentMethod
            tax
            serviceCharge
            discount
            grossAmount
            kitchenOrder {
              getInvoiceNumber
              receiptId
            }
            table {
              id
              pk
              title
              capacity
            }
            customer {
              id
              pk
            }
            orderItems {
              edges {
                node {
                  id
                  pk
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_OBJECT_COUNT = gql`
  query {
    dashboardReportObjectCount {
      todaySaleCount
      todaySaleIncrement
      todayProductSaleCount
      todayProductSaleIncrement
      todayModifierSaleCount
      todayModifierSaleIncrement
      todayCustomerCount
      todayCustomerIncrement
      overAllSaleCount
      overAllSaleAmount
      overAllProductCount
      overAllProductAmount
      overAllModifierCount
      overAllModifierAmount
      overAllSaleAverageCount
      overAllSaleAverageAmount
    }
  }
`;

export const DASHBOARD_SALES_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardSalesGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      salesCount
      salesAmount
      productsCount
      productsAmount
      graphData {
        label
        salesCount
        salesAmount
        productsCount
        productsAmount
      }
    }
  }
`;
export const DASHBOARD_ORDER_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardOrderGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      totalOrderAmount
      totalOrderCount
      graphData {
        label
        count
        amount
      }
    }
  }
`;
export const DASHBOARD_EXPENSES_GRAPH = gql`
  query ($onBasis: Int, $fromDate: Date, $toDate: Date) {
    dashboardExpensesGraph(
      onBasis: $onBasis
      fromDate: $fromDate
      toDate: $toDate
    ) {
      refundOrderCount
      refundOrderAmount
      voidOrderCount
      voidOrderAmount
      discountOrderCount
      discountOrderAmount
      rewardProgramOrderCount
      rewardProgramOrderAmount
      graphData {
        label
        discountCount
        discountAmount
        refundCount
        refundAmount
        voidCount
        voidAmount
        rewardProgramCount
        rewardProgramAmount
      }
    }
  }
`;
export const QUERY_TRANSACTION_ACCOUNTS = gql`
  query ($first: Int, $offset: Int, $isVisible: Boolean) {
    transactionAccounts(first: $first, offset: $offset, isVisible: $isVisible) {
      edges {
        node {
          id
          pk
          name
          slug
          isDefault
          isRequiredTender
          transactionCost
        }
      }
    }
  }
`;

export const GET_TRANSACTION_ACCOUNT = gql`
  query ($id: ID!) {
    transactionAccount(id: $id) {
      id
      pk
      name
      transactionCost
    }
  }
`;
export const GET_TRANSACTION_ACCOUNTS = gql`
  query (
    $transaction_Account: ID
    $first: Int
    $offset: Int
    $transaction_Created_Gte: DateTime
    $transaction_Created_Lte: DateTime
  ) {
    accountTransactionsView(
      transaction_Account: $transaction_Account
      first: $first
      offset: $offset
      transaction_Created_Gte: $transaction_Created_Gte
      transaction_Created_Lte: $transaction_Created_Lte
    ) {
      edges {
        node {
          id
          pk
          balance
          transaction {
            id
            pk
            created
            order {
              getInvoiceNumber
            }
            debit
            credit
            note
            account {
              id
              pk
              name
            }
          }
        }
      }
    }
  }
`;

export const QUERY_SUPPLIER_TRANSACTIONS_VIEW = gql`
  query ($first: Int, $offset: Int) {
    supplierTransactionsView(first: $first, offset: $offset) {
      edges {
        node {
          id
          pk
          balance
          transaction {
            id
            pk
            user {
              pk
              mobileNumber
              email
              profile {
                id
                pk
                firstName
                lastName
                middleName
                email
                contactNumber
                image
                dobNepali
                dobEnglish
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;
export const DELIVER_METHODS = gql`
  query (
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title_Icontains: String
  ) {
    deliveryMethods(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title_Icontains: $title_Icontains
    ) {
      edges {
        node {
          id
          pk
          createdBy {
            id
            username
          }
          title
          amount
          description
          isRedirectToCustomers
        }
      }
    }
  }
`;

export const GET_QUERY_SUPPLIER_TRANSACTIONS_VIEW = gql`
  query ($first: Int, $offset: Int, $transaction_User: ID!) {
    supplierTransactionsView(
      first: $first
      offset: $offset
      transaction_User: $transaction_User
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          pk
          balance
          transaction {
            created
            id
            pk
            debit
            credit
            note
            user {
              id
              pk
              mobileNumber
              email
              profile {
                id
                pk
                firstName
                lastName
                middleName
                email
                contactNumber
                image
                dobNepali
                dobEnglish
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;

export const PURCHASE_HISTORIES = gql`
  query PURCHASE_HISTORIES($first: Int, $offset: Int, $transaction_User: ID) {
    purchaseHistories(
      first: $first
      offset: $offset
      supplier: $transaction_User
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          created
          id
          pk
          costPrice
          quantity
          totalCostPrice
          discount
          amountAfterDiscount
          taxAmount
          cpAfterTax
          product {
            id
            title
          }
          supplier {
            id
          }
        }
      }
    }
  }
`;

export const QUERY_CUSTOMER_TRANSACTIONS_VIEW = gql`
  query ($first: Int, $offset: Int) {
    customerTransactionsView(first: $first, offset: $offset) {
      edges {
        node {
          id
          pk
          balance
          transaction {
            id
            pk
            user {
              pk
              mobileNumber
              email
              profile {
                id
                pk
                firstName
                lastName
                middleName
                email
                contactNumber
                image
                dobNepali
                dobEnglish
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_QUERY_CUSTOMER_TRANSACTIONS_VIEW = gql`
  query (
    $first: Int
    $offset: Int
    $transaction_User: ID!
    $transaction_Created_Gte: DateTime
    $transaction_Created_Lte: DateTime
  ) {
    customerTransactionsView(
      first: $first
      offset: $offset
      transaction_User: $transaction_User
      transaction_Created_Gte: $transaction_Created_Gte
      transaction_Created_Lte: $transaction_Created_Lte
    ) {
      edges {
        node {
          id
          pk
          balance
          transaction {
            created
            id
            pk
            debit
            credit
            note
            order {
              id
              pk
              getInvoiceNumber
              paymentStatus
              orderItems {
                edges {
                  node {
                    id
                    pk
                    title
                    quantity
                    price
                    totalPrice
                  }
                }
              }
            }
            user {
              id
              pk
              mobileNumber
              email
              profile {
                id
                pk
                firstName
                lastName
                middleName
                email
                contactNumber
                image
                dobNepali
                dobEnglish
                fullAddress
              }
            }
          }
        }
      }
    }
  }
`;

export const HOURLY_SALES_REPORT = gql`
  query HOURLY_SALES_REPORT($fromDate: Date, $toDate: Date) {
    hourlyTransactionHistory(fromDate: $fromDate, toDate: $toDate) {
      date {
        name
        count
        amount
      }
      fromDate
      toDate
    }
  }
`;
export const DETAIL_SALES_REPORT = gql`
  query DETAIL_SALES_REPORT(
    $user: [String]
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $table: [String]
    $paymentMethods: [String]
    $isTaxable: Boolean
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    detailSaleReport(
      user: $user
      category: $category
      item: $items
      table: $table
      paymentMethods: $paymentMethods
      isTaxable: $isTaxable
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      createdBy
      createdOn
      grossSale
      refunds {
        count
        amount
      }
      discounts {
        count
        amount
      }
      cashOut {
        count
        amount
      }
      rewardsReedem {
        count
        amount
      }
      accountTransaction {
        name
        amount
        count
      }
      staffPerformance {
        name
        amount
        count
      }
      orderStatistics {
        name
        amount
        count
      }
      totalTransaction
      netSales
      gst
      surCharge
      netBalance
      serCharge
      eftposCharge
      gstSale
      gstFreeSale
      cashBalance
      totalCashOut
      totalOrder
      floatAmount
    }
  }
`;
export const GET_FLOAT_AMOUNT = gql`
  query {
    todayFloatAmount {
      pk
      amount
      notesReconciliation {
        hundred
        fifty
        twenty
        ten
        five
        two
        one
      }
      coinsReconciliation {
        fifty
        twenty
        ten
        five
      }
    }
  }
`;

export const CREATE_FLOAT_AMOUNT = gql`
  mutation CREATE_FLOAT_AMOUNT(
    $floatAmount: Decimal!
    $coinsReconciliation: CoinsReconciliationInputType
    $notesReconciliation: NotesReconciliationInputType
    $terminal: [TerminalInputType]
  ) {
    createFloatAmount(
      input: {
        floatAmount: $floatAmount
        coinsReconciliation: $coinsReconciliation
        notesReconciliation: $notesReconciliation
        terminal: $terminal
      }
    ) {
      success
      message
      floatAmount {
        pk
        amount
        terminals {
          name
        }
        notesReconciliation {
          hundred
          fifty
          twenty
          ten
          five
          two
          one
        }
        coinsReconciliation {
          fifty
          twenty
          ten
          five
        }
      }
    }
  }
`;

export const QUICK_SALE_REPORT = gql`
  query QUICK_SALES_REPORT(
    $paymentMethods: [String]
    $taxType: String!
    $fromDateTime: DateTime!
    $toDateTime: DateTime!
  ) {
    quickSaleReport(
      paymentMethods: $paymentMethods
      taxType: $taxType
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      modeOfPayment {
        name
        price
        quantity
      }
      gstItem
      gstFreeItem
      totalSales
      totalAmountInTill
      float
      createdBy
      createdOn
    }
  }
`;

export const EMPLOYEE_WORKING_HOUR_REPORT = gql`
  query EMPLOYEE_WORKING_HOUR_REPORT(
    $user: [String]
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    employeeWorkingHourReport(
      user: $user
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      createdBy
      createdOn
      data {
        name
        clockInOut {
          clockIn
          clockOut
          difference
        }
      }
    }
  }
`;

export const STOCK_REPORT = gql`
  query STOCK_REPORT(
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $isTaxable: Boolean
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    productStockHistoryReport(
      category: $category
      item: $items
      isTaxable: $isTaxable
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      fromDate
      toDate
      category {
        name
        quantity
      }
      items {
        name
        quantity
      }
      gstItem
      gstFreeItem
      totalStock
      createdBy
      createdOn
    }
  }
`;

export const MEMBERS_REPORT = gql`
  query MEMBERS_REPORT($fromDateTime: DateTime, $toDateTime: DateTime) {
    membersReport(fromDateTime: $fromDateTime, toDateTime: $toDateTime) {
      fromDate
      toDate
      name
      members {
        name
        joinedOn
      }
      joinedOn
      createdBy
      createdOn
    }
  }
`;

export const NO_SALES_REPORT = gql`
  query NO_SALES_REPORT(
    $user: [String]
    $items: [SaleItemInputTypeNode]
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    noSaleReport(
      user: $user
      item: $items
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      createdOn
      createdBy
      fromDate
      toDate
      noSale
    }
  }
`;

export const WASTAGE_AND_LOSS_REPORT = gql`
  query WASTAGE_AND_LOSS_REPORT(
    $user: [String]
    $category: [String]
    $items: [SaleItemInputTypeNode]
    $fromDateTime: DateTime
    $toDateTime: DateTime
  ) {
    wastageAndLossReport(
      user: $user
      category: $category
      item: $items
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
    ) {
      createdOn
      createdBy
      fromDate
      toDate
      user {
        username
        fullName
      }
      category {
        name
        price
        quantity
      }
      items {
        name
        price
        quantity
      }
      totalAmount
    }
  }
`;

export const PRODUCTWISE_REPORT = gql`
  query PRODUCTWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailProductWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      product
      quantity
      totalCost
      totalSales
      profitLoss
      subRows {
        product
        quantity
        totalCost
        totalSales
        profitLoss
      }
    }
  }
`;
export const CANCELLED_PRODUCTWISE_REPORT = gql`
  query CANCELLED_PRODUCTWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $product: [String]
  ) {
    detailProductCancelReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      product: $product
    ) {
      name
      quantity
      table
      operator
      dateTime
      totalPrice
    }
  }
`;

export const CATEGORYWISE_REPORT = gql`
  query CATEGORYWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailCategoriesWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      category
      quantity
      totalCost
      totalSales
      profitLoss
    }
  }
`;

export const USERWISE_REPORT = gql`
  query USERWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailUserWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      totalCost
      totalTransaction
    }
  }
`;

export const FLOORWISE_REPORT = gql`
  query FLOORWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailFloorWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      tableCount
      totalSales
      totalTransaction
      profitLoss
    }
  }
`;
export const TABLEWISE_REPORT = gql`
  query TABLEWISE_REPORT(
    $fromDateTime: DateTime
    $toDateTime: DateTime
    $terminal: [String]
  ) {
    detailTableWiseReport(
      fromDateTime: $fromDateTime
      toDateTime: $toDateTime
      terminal: $terminal
    ) {
      name
      itemCount
      totalSales
      totalTransaction
      profitLoss
    }
  }
`;

export const PRODUCT_PRINT_ORDERS = gql`
  query PRODUCT_PRINT_ORDERS {
    productPrintOrders {
      edges {
        node {
          id
          name
          productOrder
          pk
        }
      }
    }
  }
`;

export const PRODUCT_PRINT_ORDER = gql`
  query ($id: ID!) {
    productPrintOrder(id: $id) {
      id
      name
      pk
      productOrder
    }
  }
`;

export const REWARD_SETTING_POINT = gql`
  query {
    rewardPointSetting {
      amount
      point
      minRequiredPoint
      isActive
    }
  }
`;

export const ACCOUNT_TRANSACTION = gql`
  query ACCOUNT($slug: String) {
    transactionAccounts(slug: $slug) {
      edges {
        node {
          accountBalance
          name
          id
          pk
          children {
            edges {
              node {
                name
                accountBalance
                id
                pk
              }
            }
          }
        }
      }
    }
  }
`;
