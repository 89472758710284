import { useQuery, useReactiveVar } from "@apollo/client";
import { BackspaceIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { QUERY_POS_DISCOUNT } from "../../../../../pages/queries";
import { cache, cartItemsVar, permissionTypeCache, permissionTypeVar } from "../../../../localstate/Cache";
import { GET_CART_ITEMS, GET_PERMISSION_TYPE } from "../../../../localstate/Cart";

export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  {
    id: Math.random().toString(36).substr(2, 5),
    label: "Clear",
    value: "clear",
  },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  {
    id: Math.random().toString(36).substr(2, 5),
    label: <BackspaceIcon className="w-5 h-5 m-auto" />,
    value: "backspace",
  },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];
const numberWithDot = /^[0-9]*\.?[0-9]*$/; //regx for only number with one dot

type Props = {
  setSelectSuchargeType?: any;
  open?: any;
  setOpen?: any;
  totalAmtCalculations?: any;
  selectedtitle?: any;
  selectSuchargeType?: any;
};

const OverAllSurchargeForm = (props: Props) => {
  const { selectSuchargeType, setSelectSuchargeType, setOpen, totalAmtCalculations } = props;
  const [enteredNumber, setEnteredNumber] = useState<any>("");
  const [discountPercentageList, setDiscountPercentageList] = useState<any>([]);
  // const { data: discountListData } = useQuery(QUERY_POS_DISCOUNT, {
  //   variables: {
  //     first: 4,
  //   },
  // });
  const cartItems: any = useReactiveVar(cartItemsVar);

  let cartValue: any = cartItems?.findIndex((cartItem: any, index: number) => cartItem?.isSelected === true);
  // useEffect(() => {
  //   if (discountListData?.posDiscounts?.edges.length) {
  //     const discountPercentageItem = discountListData?.posDiscounts?.edges?.map(
  //       (item: any) => item?.node,
  //     );
  //     setDiscountPercentageList(discountPercentageItem);
  //   }
  // }, [discountListData]);

  const handleDiscountType = (selectSuchargeType: string) => {
    if (selectSuchargeType === "surchargePer") {
      if (enteredNumber > 100) {
        toast.info("Discount Percentage cannot be more than 100")
      } else {
        setSelectSuchargeType("surchargePer");
      }
    }
    if (selectSuchargeType === "surchargeAmt") {
      setSelectSuchargeType("surchargeAmt");
    }
  };
  const handleDiscountPercentageType = (discountPercentageItem: any) => {

    discountPercentageItem.discountType === "PERCENTAGE"
      ? setSelectSuchargeType({
        surchargePercentage: discountPercentageItem.rateAmount,
        surchargeAmount: 0,
      })
      : setSelectSuchargeType({
        surchargePercentage: 0,
        surchargeAmount: discountPercentageItem.rateAmount,
      });

    setOpen(false);
  };

  const handleEnteredNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e?.target;
    if (numberWithDot.test(value)) {
      if (selectSuchargeType === "surchargePer" && parseFloat(value) > 100) {
        setEnteredNumber(100)
      } else {
        setEnteredNumber(value)
      }
    }
  };

  const handleCalculate = (data: string) => {

    switch (data) {
      case "backspace":
        setEnteredNumber(
          enteredNumber?.substring(0, enteredNumber?.length - 1),
        );
        break;
      case "clear":
        setEnteredNumber("");
        break;
      case "calculate":

        if (selectSuchargeType === "surchargePer") {
          setSelectSuchargeType({
            surchargePercentage: parseFloat(enteredNumber),
            surchargeAmount: 0,
          });
          setOpen(false);
          permissionTypeCache.writeQuery({
            query: GET_PERMISSION_TYPE,
            data: {
              permissionTypes: permissionTypeVar(''),
            },
          });
        }
        else if (selectSuchargeType === "surchargeAmt") {
          setSelectSuchargeType({
            surchargeAmount: parseFloat(enteredNumber),
            surchargePercentage: 0,
          });
          setOpen(false);
          permissionTypeCache.writeQuery({
            query: GET_PERMISSION_TYPE,
            data: {
              permissionTypes: permissionTypeVar(''),
            },
          });
        } else {
          toast.info("Discount Amount cannot be more than Gross Amount")
        }

        break;
      default:
        if (numberWithDot.test(enteredNumber)) {
          if (selectSuchargeType === "surchargePer" && parseFloat(enteredNumber + data) > 100) {
            setEnteredNumber(100)
          } else {
            setEnteredNumber(enteredNumber + data)
          }
          // selectDiscountType === "discountPer" && parseFloat(enteredNumber + data) < 100 ? setEnteredNumber(enteredNumber + data) : setEnteredNumber(100);
        }
    }

  };
  return (
    <>
      <h3 className="text-left mb-1">Enter amount</h3>
      <div className="grid grid-cols-3 gap-1 mb-2">
        <input
          className="p-2 items-center border-2 border-solid border-black-800 rounded-md"
          autoFocus
          type="text"
          value={enteredNumber}
          onChange={(e) => handleEnteredNumber(e)}
          name={"enteredNumber"}
        />
        <span
          className={`col-span-1 w-full p-2 cursor-pointer text-md items-center justify-center rounded-md ${selectSuchargeType === "surchargeAmt"
            ? "bg-[#4338CA] text-white"
            : "bg-[#E0E0E0]"
            }`}
          onClick={() => handleDiscountType("surchargeAmt")}
        >Surcharge (Amt.)</span>
        <span
          className={`col-span-1 w-full p-2 cursor-pointer text-md items-center justify-center rounded-md ${selectSuchargeType === "surchargePer"
            ? "bg-[#4338CA] text-white"
            : "bg-[#E0E0E0]"
            }`}
          onClick={() => handleDiscountType("surchargePer")}
        >Surcharge (%)</span>
      </div>

      <div className="w-[100%] flex text-center">
        <div className="col-span-4 w-[80%]">
          <div className="grid grid-cols-4 gap-1 mr-2">
            {numberPad.map((item) => (
              <div
                key={item.id}
                className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
                onClick={() => handleCalculate(item.value)}
              >
                <span>{item.label}</span>
              </div>
            ))}
            <div
              className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate("0")}
            >
              <span>0</span>
            </div>
            <div
              className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate(".")}
            >
              <span>.</span>
            </div>
            <div
              className="col-span-1 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate("00")}
            >
              <span>00</span>
            </div>
            <div
              className="col-span-2 w-[100%] p-2 cursor-pointer bg-[#e0e0e0] text-[18px] items-center justify-center"
              onClick={() => handleCalculate("calculate")}
            >
              <span>Enter</span>
            </div>
          </div>
        </div>
        <div className=" w-[20%]">
          <div className="grid grid-cols-1 grid-rows-4 gap-1">
            {discountPercentageList?.length === 0 ? (
              <>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : discountPercentageList?.length <= 1 ? (
              <>
                {discountPercentageList?.map((item: any) => (
                  <div
                    key={item.id}
                    className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                    onClick={() => handleDiscountPercentageType(item)}
                  >
                    <span>
                      {item?.discountType === "PERCENTAGE"
                        ? `${item.rateAmount}%`
                        : item.rateAmount}
                    </span>
                  </div>
                ))}
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : discountPercentageList?.length <= 2 ? (
              <>
                {discountPercentageList?.map((item: any) => (
                  <div
                    key={item.id}
                    className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                    onClick={() => handleDiscountPercentageType(item)}
                  >
                    <span>
                      {item?.discountType === "PERCENTAGE"
                        ? `${item.rateAmount}%`
                        : item.rateAmount}
                    </span>
                  </div>
                ))}

                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : discountPercentageList?.length <= 3 ? (
              <>
                {discountPercentageList?.map((item: any) => (
                  <div
                    key={item.id}
                    className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                    onClick={() => handleDiscountPercentageType(item)}
                  >
                    <span>
                      {item?.discountType === "PERCENTAGE"
                        ? `${item.rateAmount}%`
                        : item.rateAmount}
                    </span>
                  </div>
                ))}
                <div className="col-span-1 w-[100%] p-2 cursor-not-allowed bg-[#e0e0e0] text-[18px] items-center justify-center">
                  <span>{"N/A"}</span>
                </div>
              </>
            ) : (
              discountPercentageList?.length <= 4 && (
                <>
                  {discountPercentageList?.map((item: any) => (
                    <div
                      key={item.id}
                      className="col-span-1 w-[100%] p-2 bg-[#e0e0e0] text-[18px] items-center justify-center"
                      onClick={() => handleDiscountPercentageType(item)}
                    >
                      <span>
                        {item?.discountType === "PERCENTAGE"
                          ? `${item.rateAmount}%`
                          : item.rateAmount}
                      </span>
                    </div>
                  ))}
                </>
              )
            )}
          </div>
        </div>
      </div>

    </>
  );
};

export default OverAllSurchargeForm;
