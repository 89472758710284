import { useMutation } from "@apollo/client";
import { useState, Fragment } from "react";
import { toast } from "react-toastify";
import { ROOT_CATEGORIES_QUERY } from "../../../pages/PosScreen/queries";
import { ASSIGN_COLOR } from "../../../pages/PosScreen/mutation";
import {
  PRODUCTS_BY_MODIFIERCATEGORY,
  usePosScreenProductByCategory,
} from "../../../pages/PosScreen/queries";
import { PhotoshopPicker } from "react-color";
import { Dialog, Transition } from "@headlessui/react";
export default function ColorPicker(props) {
  const {
    type,
    id,
    productByCatId,
    setDisplayColorPicker,
    displayColorPicker,
  } = props;

  const [color, setColor] = useState("#F3F4F6");

  const [addQuickColor] = useMutation(ASSIGN_COLOR, {
    refetchQueries: [
      type === "category"
        ? {
            query: ROOT_CATEGORIES_QUERY,
            variables: {
              offset: 0,
              category_Name_Icontains: "",
            },
          }
        : type === "modifier"
        ? {
            query: PRODUCTS_BY_MODIFIERCATEGORY,
          }
        : {
            query: usePosScreenProductByCategory,
            variables: {
              offset: 0,
              posScreenCategory: productByCatId,
            },
          },
    ],
  });

  const handleAddColor = async () => {
    const response = await addQuickColor({
      variables: {
        type: type,
        colorCode: color,
        id: id,
      },
    });

    if (response?.data?.assignColorCode?.success) {
      toast.success(response?.data?.assignColorCode?.message);
    } else {
      toast.error(response?.data?.assignColorCode?.message);
    }
    setDisplayColorPicker(false);
  };

  function closeModal() {
    setDisplayColorPicker(false);
  }
  return (
    <div>
      <Transition appear show={displayColorPicker} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden text-left align-middle">
                  <PhotoshopPicker
                    color={color}
                    onChange={(color) => setColor(color.hex)}
                    onAccept={handleAddColor}
                    onCancel={closeModal}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
