import React, { useEffect, useState } from "react";
import PosScreenPurchaseList from "../PosScreenPurchaseList";
import {
  cache,
  cartItemsVar,
  customerCache,
  CustomerItemsVar, kitchenOrderItemId, kitchenOrdreCache,
  modifiedKitchenOrderCache,
  modifiedKitchenOrderItemVar,
  orderTypeCache,
  orderTypeVar,
  tableCache,
  tableItemsVar,
} from "../../../../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_CUSTOMER_ITEMS,
  GET_MODIFIED_KITCHEN_CARD_ID,
  GET_ORDER_TYPE,
  GET_TABLE_ITEMS,
} from "../../../../localstate/Cart";
import { toast } from "react-toastify";
import DeleteIcon from "../../../../../assets/components/Delete";
import { setLocalKey } from "../../../../../helpers/sessionKey";
import Modal from "../../../../reusable/Modal";
import { useLazyQuery } from "@apollo/client";
import UpdatePosScreenProWithModCat from "../../../UpdatePosScreenProWithModCat";
import { usePosScreenProductByCategoryWithIds } from "../../../../../pages/PosScreen/queries";

type Props = {
  cartItems?: any;
  KitchenCardItemData?: any;
  holdCardItemData?: any;
  setDisableRemotePrint?: any
};
const PosScreenTable = (props: Props) => {
  const { cartItems, KitchenCardItemData, holdCardItemData } = props;
  const [openProductWithModCategory, setOpenProductWithModCategory] = useState(false)
  const [productSingle, setProductSingle] = useState({})
  const [productSingleItems, setProductSingleItems] = useState({})
  const [getSingleProduct] = useLazyQuery(usePosScreenProductByCategoryWithIds);
  const onRightClick = async (item: any) => {
    // if (item?.productId && item?.isSelected) {
    //   setOpenProductWithModCategory(true);
    //   const response = await getSingleProduct({
    //     variables: ({
    //       id: item?.productId,
    //     }),
    //   });
    //   const product = response?.data?.posScreenProduct?.product;
    //   setProductSingle(product)
    //   setProductSingleItems(item)
    // }
  }

  //display Hold Kitchen Order Lists
  useEffect(() => {
    if (holdCardItemData) {
      const itemToCart: any[] = [];

      holdCardItemData?.kitchenOrder?.kitchenOrderItems?.edges?.map(
        (item: any) => {
          let modItems: any = [];
          item?.node?.modifiers?.edges?.map((modItem: any) => {
            modItems.push({
              id: modItem?.node?.product?.id,
              pk: modItem?.node?.product?.pk,
              title: modItem?.node?.product?.title,
              quantity: modItem?.node?.quantity,
              price: modItem?.node?.product?.finalPrice ?? 0,
              total: parseFloat(modItem?.node?.quantity) * parseFloat(modItem?.node?.product?.finalPrice),
              tax: parseFloat(modItem?.node?.quantity) * parseFloat(modItem?.node?.product?.taxAmount),
              taxRate: { rate: item?.tax?.rate ?? 0, isIncluded: item?.tax?.isIncluded },
              isSelected: false,
              isDeleted: false,
              note: modItem?.node?.note,
            });
          });

          itemToCart.push({
            id: item?.node?.product?.id,
            pk: item?.node?.product?.pk,
            title: item?.node?.product?.title,
            quantity: item?.node?.quantity,
            price: item?.node?.product?.finalPrice ?? 0,
            total: parseFloat(item?.node?.quantity) * parseFloat(item?.node?.product?.finalPrice ?? 0) ?? 0,
            tax: parseFloat(item?.node?.quantity) * parseFloat(item?.node?.product?.taxAmount),
            taxRate: { rate: item?.tax?.rate ?? 0, isIncluded: item?.tax?.isIncluded },
            isSelected: false,
            isDeleted: false,
            modifier: modItems,
            note: item?.node?.note,
            idToModifyKitchenOrder: item?.node?.pk,
          });
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar(itemToCart),
            },
          });
        },
      );

    }
  }, [holdCardItemData]);

  const handleKitchenCardItem = () => {
    const itemToCart: any[] = [];
    KitchenCardItemData?.kitchenOrder?.kitchenOrderItems?.edges?.map(
      (item: any) => {
        let modItems: any = [];
        if (item?.node?.modifiers) {
          item?.node?.modifiers?.edges?.map((modItem: any) =>
            modItems.push({
              id: modItem?.node?.product?.id,
              pk: modItem?.node?.product?.pk,
              title: modItem?.node?.product?.title,
              quantity: modItem?.node?.quantity,
              price: modItem?.node?.product?.finalPrice ?? 0,
              total: parseFloat(modItem?.node?.quantity) * parseFloat(modItem?.node?.product?.finalPrice ?? 0) ?? 0,
              tax: parseFloat(modItem?.node?.quantity) * parseFloat(modItem?.node?.product?.taxAmount),
              taxRate: { rate: modItem?.node?.product?.tax?.rate ?? 0, isIncluded: modItem?.node?.product?.tax?.isIncluded },
              isSelected: false,
              isDeleted: false,
              isModified: false,
              note: modItem?.node?.note,
            }),
          );
        }
        itemToCart.push({
          id: item?.node?.product?.id,
          pk: item?.node?.product?.pk,
          title: item?.node?.product?.title,
          quantity: item?.node?.quantity,
          price: item?.node?.product?.finalPrice ?? 0,
          total: parseFloat(item?.node?.quantity) * parseFloat(item?.node?.product?.finalPrice ?? 0) ?? 0,
          tax: parseFloat(item?.node?.quantity) * parseFloat(item?.node?.product?.taxAmount),
          taxRate: { rate: item?.node?.product?.tax?.rate ?? 0, isIncluded: item?.node?.product?.tax?.isIncluded },
          isSelected: false,
          isDeleted: false,
          modifier: modItems,
          idToModifyKitchenOrder: item?.node?.pk,
          isModified: false,
          note: item?.node?.note,
        });
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar(itemToCart),
          },
        });
        let tableItems = {
          id: KitchenCardItemData?.kitchenOrder?.table?.id ?? "",
          status: KitchenCardItemData?.kitchenOrder?.table?.status ?? "",
          pk: KitchenCardItemData?.kitchenOrder?.table?.pk ?? "",
          title: KitchenCardItemData?.kitchenOrder?.table?.title ?? "",
          currentKitchenOrder: KitchenCardItemData?.kitchenOrder ?? "",
        };
        tableCache.writeQuery({
          query: GET_TABLE_ITEMS,
          data: {
            tableItems: tableItemsVar(tableItems),
          },
        });
        let orderTypeList = {
          pk: KitchenCardItemData?.kitchenOrder?.orderMethod ? KitchenCardItemData?.kitchenOrder?.orderMethod.pk : "",
          title: KitchenCardItemData?.kitchenOrder?.orderMethod ? KitchenCardItemData?.kitchenOrder?.orderMethod.title : "",
        };
        orderTypeCache.writeQuery({
          query: GET_ORDER_TYPE,
          data: {
            orderType: orderTypeVar(orderTypeList),
          },
        });
        let customerItems = {
          id: KitchenCardItemData?.kitchenOrder?.customer?.id ?? "",
          name: KitchenCardItemData?.kitchenOrder?.customer?.user?.fullName ?? "",
          pk: KitchenCardItemData?.kitchenOrder?.customer?.pk ?? "",
        };
        customerCache.writeQuery({
          query: GET_CUSTOMER_ITEMS,
          data: {
            customerItems: CustomerItemsVar(customerItems),
          },
        });
        let kitchenOrderItems = {
          kitchenOrderPk: KitchenCardItemData?.kitchenOrder,
        };
        modifiedKitchenOrderCache.writeQuery({
          query: GET_MODIFIED_KITCHEN_CARD_ID,
          data: {
            modifiedKitchenOrderId:
              modifiedKitchenOrderItemVar(kitchenOrderItems),
          },
        });
      },
    );
  };

  //display Kitchen Order Lists
  useEffect(() => {
    if (KitchenCardItemData?.kitchenOrder?.kitchenOrderItems?.edges?.length) {
      handleKitchenCardItem();
    }
  }, [KitchenCardItemData]);
  //Product list handleChange
  const handleModifierChange = (event: any, index: number) => {
    let editedProductList = cartItems.map((item: any, itemIndex: number) =>
      itemIndex === index
        ? {
          ...item,
          isSelected: true,
        }
        : {
          ...item,
          isSelected: false,
        },
    );
    cache.writeQuery({
      query: GET_CART_ITEMS,
      data: {
        cartItems: cartItemsVar([...editedProductList]),
      },
    });
    event.stopPropagation();
  };
  //remove product from cartItems
  const handleRemoveProductFromCart = (
    event: any,
    index: number,
    selectedItem: any,
  ) => {
    let previousObject: any = cartItems.find(
      (item: any, itemIndex: number) =>
        item.id === selectedItem.id &&
        itemIndex === index &&
        item.isSelected === true,
    );
    if (previousObject) {
      setLocalKey("disableRemotePrint", "false");
      let newItemList: any = cartItems;
      let foundIndex = cartItems.findIndex(
        (item: any, itemIndex: number) =>
          item.id === selectedItem.id && itemIndex === index,
      );
      newItemList[foundIndex].quantity = -previousObject.quantity;
      newItemList[foundIndex].isDeleted = true;
      newItemList[foundIndex].total = 0;
      newItemList[foundIndex].tax = 0;
      newItemList[foundIndex].isModified = true;
      newItemList[foundIndex].discount = 0;
      newItemList[foundIndex].isSelected = false;
      newItemList[foundIndex].modifier = [];
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...newItemList]),
        },
      });
    } else {
      toast.info("Product must be selected to delete");
    }

    event.stopPropagation();
  };

  //remove modifier from cartItems
  const handleRemoveModifierFromCart = (
    event: any,
    selectedModifierItem: any,
  ) => {
    let selectedItem: any = cartItems?.find((x: any) => x?.isSelected === true);
    let prevModifierList: any = selectedItem?.modifier;
    let previousItemToDelete = prevModifierList?.find(
      (item: any) => item?.randId === selectedModifierItem?.randId,
    );
    if (previousItemToDelete) {
      setLocalKey("disableRemotePrint", "false");
      let productInCartList: any = cartItems;
      let perviousModifierItemList: any = prevModifierList;
      let itemIndex = prevModifierList?.findIndex(
        (item: any) => item?.id === selectedModifierItem?.id,
      );
      perviousModifierItemList[itemIndex].isDeleted = true;
      perviousModifierItemList[itemIndex].quantity =
        -previousItemToDelete.quantity;
      perviousModifierItemList[itemIndex].total = 0;
      perviousModifierItemList[itemIndex].tax = 0;
      selectedItem.modifier = perviousModifierItemList;
      let selectedItemIndex = cartItems.findIndex(
        (x: any) => x.isSelected === true,
      );
      productInCartList[selectedItemIndex].isModified = true;
      productInCartList[selectedItemIndex] = selectedItem;
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([...productInCartList]),
        },
      });

    } else {
      toast.info("Please Select a Product First");
    }

    event.stopPropagation();
  };
  const messagesEndRef: any = React.createRef();

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [cartItems]);

  const handleDiscountDelete = (cartItem: any) => {
    if (cartItem.isSelected) {
      cartItem.discount = ""
      cartItem.discounttype = ""
      setLocalKey("disableRemotePrint", "false")
    } else {
      toast.info("Please Select the Product First")
    }
  }
  const handleSurchargeDelete = (cartItem: any) => {
    if (cartItem.isSelected) {
      cartItem.surcharge = ""
      cartItem.surchargetype = ""
      setLocalKey("disableRemotePrint", "false")
    } else {
      toast.info("Please Select the Product First")
    }
  }

  return (
    <>
      <table
        cellSpacing={0}
        cellPadding={0}
        style={{
          position: "relative",
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
          className="bg-[#4338CA] text-white text-[12px] text-left"
        >
          <tr>
            <th className=" w-[75%] p-2 border-r-2  border-[#FFFFFF42]  ">
              Items
            </th>
            <th className="w-[15%] p-2 border-r-2  border-[#FFFFFF42]">
              Total
            </th>
            <th className="w-[10%] p-2 border-r-2  border-[#FFFFFF42]">
              Acn
            </th>
          </tr>
        </thead>
        <tbody className="posTable">
          {cartItems?.map(
            (cartItem: any, index: number) =>
              cartItem?.isDeleted === false && (
                <React.Fragment key={index}>
                  <tr
                    onClick={(e) => handleModifierChange(e, index)}
                    style={{
                      backgroundColor: cartItem.isSelected ? "#4338CA" : "",
                    }}
                    className={`${cartItem.isSelected ? "text-white" : "text-black"} items-center cursor-pointer`}
                  >
                    <PosScreenPurchaseList
                      cartItem={cartItem}
                      index={index}
                      handleRemoveProductFromCart={handleRemoveProductFromCart}
                      onRightClick={onRightClick}
                    // ref={messagesEndRef}
                    />
                  </tr>
                  <React.Fragment>
                    {cartItem?.modifier?.map(
                      (modifierItem: any, index: number) =>
                        modifierItem?.isDeleted === false &&
                        parseInt(modifierItem.quantity) > 0 && (
                          <React.Fragment key={index}>
                            <tr
                              style={{
                                backgroundColor: cartItem.isSelected ? "#4338CA" : "",
                              }}
                              className={`${cartItem.isSelected ? "text-white" : "bg-[#ffffff]"} items-center font-semibold italic`}>
                              <td className="relative">
                                <span
                                  title={modifierItem.title}
                                  // style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                                  // className={`mr-4 ml-2 absolute bottom-0 w-full text-left`}
                                  className={`ml-2`}
                                >
                                  <span className="font-bold text-xs italic">--<span
                                    className={`${cartItem.isSelected ? "text-white" : ""}`}>{parseFloat(modifierItem.quantity).toFixed(2)}</span>
                                    <span className="p-1">X</span>
                                    <span
                                      className="p-1">{parseFloat(modifierItem.price).toFixed(2)}</span></span> {modifierItem.title}
                                </span>

                              </td>
                              <td>
                                <span className="ml-4">
                                  {parseFloat(modifierItem?.total).toFixed(2)}
                                </span>
                              </td>
                              <td>
                                <span className="flex items-center ml-4 justify-center cursor-pointer">
                                  <span onClick={(e) =>
                                    handleRemoveModifierFromCart(
                                      e,
                                      modifierItem,
                                    )
                                  }>
                                    <DeleteIcon
                                      fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"} />
                                  </span>
                                </span>

                              </td>
                            </tr>
                          </React.Fragment>
                        ),
                    )}
                    {cartItem.discount === "0.00" || !cartItem.discount || cartItem.discount === "" ? "" : <>
                      <React.Fragment key={index}>
                        <tr
                          style={{
                            backgroundColor: cartItem.isSelected ? "#4338CA" : "",
                          }}
                          className={`${cartItem.isSelected ? "text-white" : "bg-[#ffffff]"} font-semibold italic`}>
                          <td className="relative">
                            <span
                              // style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                              // className={`mr-4 ml-2 absolute bottom-0 w-full text-left`}
                              className={`ml-2`}
                            >
                              <td>
                                {cartItem?.discounttype === "discountPer" ?
                                  <span className="ml-5">
                                    --ITEM DISCOUNT({cartItem.discountTot}%)
                                  </span> :
                                  <span className="ml-5">
                                    --ITEM DISCOUNT(Amt)
                                  </span>}
                              </td>

                            </span>
                          </td>
                          <td>
                            <span className="ml-4">
                              -{parseFloat(cartItem.discount).toFixed(2)}
                              {/* <Welcome cartItem={cartItem} /> */}
                            </span>
                          </td>
                          <td>
                            <span className="flex items-center ml-4 justify-center cursor-pointer" onClick={() => handleDiscountDelete(cartItem)}>
                              <DeleteIcon
                                fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"} />
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                      {setLocalKey("disableRemotePrint", "true")}
                    </>
                    }
                    {cartItem.surcharge === "0.00" || !cartItem.surcharge || cartItem.surcharge === "" ? "" : <>
                      <React.Fragment key={index}>
                        <tr
                          style={{
                            backgroundColor: cartItem.isSelected ? "#4338CA" : "",
                          }}
                          className={`${cartItem.isSelected ? "text-white" : "bg-[#ffffff]"} font-semibold italic`}>
                          <td className="relative">
                            <span
                              // style={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                              // className={`mr-4 ml-2 absolute bottom-0 w-full text-left`}
                              className={`ml-2`}
                            >
                              <td>
                                {cartItem?.surchargeType === "surchargePer" ?
                                  <span className="ml-5">
                                    --ITEM SURCHARGE({cartItem.surchargeTot}%)
                                  </span> :
                                  <span className="ml-5">
                                    --ITEM SURCHARGE(Amt)
                                  </span>}
                              </td>

                            </span>
                          </td>
                          <td>
                            <span className="ml-4">
                              {parseFloat(cartItem.surcharge).toFixed(2)}
                              {/* <Welcome cartItem={cartItem} /> */}
                            </span>
                          </td>
                          <td>
                            <span className="flex items-center ml-4 justify-center cursor-pointer" onClick={() => handleSurchargeDelete(cartItem)}>
                              <DeleteIcon
                                fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"} />
                            </span>
                          </td>
                        </tr>
                      </React.Fragment>
                      {setLocalKey("disableRemotePrint", "true")}
                    </>
                    }
                  </React.Fragment>
                  {cartItem?.note && (
                    <tr
                      style={{
                        backgroundColor: cartItem.isSelected ? "#4338CA" : "#c7e7c7",
                        color: cartItem.isSelected ? "#FFFFFF" : "",
                      }}
                    >
                      <td colSpan={6}>
                        <span className="ml-2 ">
                          Note: {`${cartItem.note}`}
                        </span>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ),
          )}
          <tr ref={messagesEndRef} />
        </tbody>
      </table >
      <Modal
        title={"Update Modifier"} open={openProductWithModCategory} setOpen={setOpenProductWithModCategory}
        size="max-w-7xl"
        children={
          <UpdatePosScreenProWithModCat
            item={productSingle}
            setOpen={setOpenProductWithModCategory}
            open={openProductWithModCategory}
            productSingleItems={productSingleItems}
          />
        }
      />
    </>
  );
};
export default PosScreenTable;
