import { useEffect, useState } from "react";
import PosScreenProductForm from "./PosScreenTableSection/PosScreenProductForm";
import Modal from "../../../reusable/Modal";
import EditIcon from "../../../../assets/components/Edit";

type Props = {
  cartItem?: any;
  handleRemoveProductFromCart?: any;
  index?: any;
  ref?: any;
  onRightClick?: any;
};
export default function PosScreenPurchaseList(props: Props) {
  const { cartItem, handleRemoveProductFromCart, index, onRightClick } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <td
        // ref={ref}
        style={{
          borderRight: "solid white thin",
          padding: "2px",
          width: "70%",
          position: "relative",
          fontWeight: 'bold',
          fontSize: '16px'
        }}
        onContextMenu={() => onRightClick(cartItem)}
      >
        <span title={cartItem.title}>
          <span className="font-semibold text-xs italic">
            <span
              className={`${cartItem.isSelected ? "text-white" : ""} text-lg`}
            >
              {parseFloat(cartItem.quantity).toFixed(3)}
            </span>
            <span className="p-1">X</span>
            <span className="p-1 font-bold">{parseFloat(cartItem.price).toFixed(2)}</span>
          </span>
          {cartItem.title}
        </span>
      </td>
      <td
        style={{
          borderRight: "solid white thin",
          padding: "2px",
          width: "15%",
          fontWeight: "bold"
        }}
      >
        {parseFloat(cartItem.total).toFixed(2)}
      </td>
      <td
        style={{
          borderRight: "solid white thin",
          padding: "2px",
          width: "10%",
        }}
      >
        <span className="flex justify-center items-center gap-1">
          <span className="cursor-pointer" onClick={() => setOpen(true)}>
            <EditIcon fillColor={cartItem.isSelected ? "#FFFFFF" : "#323232"} />
          </span>
        </span>
      </td>

      <Modal
        open={open}
        setOpen={setOpen}
        title={"Update Product"}
        children={
          <PosScreenProductForm
            cartItem={cartItem}
            setOpen={setOpen}
            itemToDeleteIndex={index}
            handleRemoveProductFromCart={handleRemoveProductFromCart}
          />
        }
      />
    </>
  );
}
