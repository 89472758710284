import { useMutation } from '@apollo/client'
import React from 'react'
import { TOGGLE_IS_SELECTED_TABLE } from '../pages/mutations'
import { toast } from 'react-toastify'
import { getLocalKey, setLocalKey } from '../helpers/sessionKey'



const useHandleToggleSelectedTable = () => {
  const [handleTableSelected] = useMutation(TOGGLE_IS_SELECTED_TABLE, {})
  const handleSelectTableToggle = async (isSelected: boolean, objectId: string) => {
    const localKey = getLocalKey('tableItemSelected')
    if (localKey) {
      await handleTableSelected({
        variables: {
          isSelected: false,
          objectId: localKey
        }
      })
    }
    await handleTableSelected({
      variables: {
        isSelected: isSelected,
        objectId: objectId
      }
    })
    setLocalKey('tableItemSelected', objectId)
    // if (response?.data?.toggleIsSelected?.success) {
    //   toast.success(response?.data?.toggleIsSelected?.message)
    // }
  }
  return { handleSelectTableToggle }
}
export default useHandleToggleSelectedTable;