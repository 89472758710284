import { gql } from "@apollo/client";
export const POS_SCREEN_COMPOUND_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $search
      category: $categoryList
      compound_Isnull: false
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          barcodeContent
          isModifier
          productStocks {
            availableQuantity
          }
          plu
          isActive

          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image

          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRODUCT = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $search: String
    $categoryList: [ID]
    $isModifier: Boolean
    $compound_Isnull: Boolean
    $isOpenProduct: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $search
      category: $categoryList
      isModifier: $isModifier
      compound_Isnull: $compound_Isnull
      isOpenProduct: $isOpenProduct
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          title
          plu
          isModifier
          productStocks {
            availableQuantity
          }
          barcodeContent
          isActive
          tax {
            id
            pk
            title
            rate
          }
          isWeightable
          unit {
            title
          }
          finalPrice
          costPrice
          sellingPrice
          image
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const PRODUCT_LIST = gql`
  query products(
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $title: String
    $isModifier: Boolean
    $compound_Isnull: Boolean
    $categoryList: [ID]
    $isOpenProduct: Boolean
  ) {
    products(
      first: $first
      after: $after
      before: $before
      offset: $offset
      title: $title
      isModifier: $isModifier
      category: $categoryList
      isOpenProduct: $isOpenProduct
      compound_Isnull: $compound_Isnull
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
      edges {
        node {
          pk
          id
          title
          barcodeContent
          isModifier
          tax {
            id
            pk
            title
            rate
          }
          finalPrice
          image
          productStocks {
            availableQuantity
            threshold
          }
          category {
            edges {
              node {
                id
                pk
                name
              }
            }
          }
        }
      }
    }
  }
`;
export const POS_SCREEN_SINGLE_PRODUCT = gql`
  query product($id: ID!) {
    product(id: $id) {
      pk
      id
      title
      plu
      productStocks {
        availableQuantity
      }
      isScalable
      barcodeContent
      isActive
      isModifierCategoryGrid
      isOpenPriceProduct
      tax {
        id
        pk
        title
        isIncluded
        rate
      }
      isWeightable
      printOrder {
        pk
        name
      }
      shortDesc
      longDesc
      finalPrice
      sellingPrice
      costPrice
      image
      unit {
        id
        pk
        title
      }
      compound {
        title
        pk
        id
        finalPrice
      }
      printer {
        edges {
          node {
            pk
            id
            name
          }
        }
      }
      modifierCategories {
        edges {
          node {
            id
            pk
            category {
              name
              pk
            }
          }
        }
      }
      category {
        edges {
          node {
            id
            pk
            name
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRODUCT_CATEGORY = gql`
  query QUERY_CATEGORY($first: Int, $level: Int, $status: String) {
    categories(first: $first, level: $level, status: $status) {
      edges {
        node {
          id
          pk
          name
          status
          parent {
            id
            pk
            name
          }
          children(first: 100) {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const POS_SCREEN_PRINTER_LIST = gql`
  query printers($first: Int) {
    printers(first: $first) {
      edges {
        node {
          id
          pk
          name
          department
          location
          port
        }
      }
    }
  }
`;
