import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import CrossIcon from "../../../assets/cross.svg"
import DIcon from "../../../assets/d.svg";


type Props = {
  isOpen?: any;
  onConfirm?: any;
  title?: any;
  subTitle?: any;
  isHoldAlert?: any;
  closeModal?: any;
  setIsOpen?: any;
  notes?: boolean;
  setNote?: any;
};

const ConfirmAlert = (props: Props) => {
  const {
    isOpen,
    setIsOpen,
    onConfirm,
    title,
    subTitle,
    notes,
    setNote
  } = props;

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div className="flex items-end justify-center min-h-screen pt-2 px-4 text-center sm:block sm:p-0" >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-[40%] overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="w-full bg-primary rounded-t-sm p-1 mb-6">
                  <div className="w-full flex justify-between ml-4 mr-2 ">
                    <div className="w-full flex gap-3">
                      <img className="w-6 h-6 m-0 p-1 hover:bg-indigo-800" src={DIcon} alt="" />
                      <Dialog.Title as="h4" className="w-full text-lg font-semibold text-white text-center">
                        {title}
                      </Dialog.Title>
                    </div>
                    <img src={CrossIcon} alt="" onClick={() => setIsOpen(false)} className="mr-6 cursor-pointer" />
                  </div></div>


                <p className="text-md text-black font-normal ">{subTitle}</p>
                {notes ? <><p className="w-full text-left ml-24 mt-5">Reason:</p>
                  <textarea onChange={(e: any) => setNote(e?.target?.value)} className="w-[70%] focus:ring-0 mb-5 border border-black focus:outline-none p-2" /> </> : ""}
                <div className="my-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-[#4338CA] rounded-md"
                    onClick={() => {
                      setIsOpen(false);
                      onConfirm();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center ml-4 px-4 py-2 text-sm font-medium text-white bg-[#D32F2F] rounded-md"
                    onClick={() => setIsOpen(false)}
                  >
                    No</button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog >
      </Transition >
    </>
  );
};
export default ConfirmAlert;
