
type Props = {
  fillColor: string;
}

const EditIcon = (props: Props) => {
  const { fillColor } = props;
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_182_4231)">
        <path d="M11.6667 12.5H2.50008V3.33333H8.00841L8.84175 2.5H2.50008C2.27907 2.5 2.06711 2.5878 1.91083 2.74408C1.75455 2.90036 1.66675 3.11232 1.66675 3.33333V12.5C1.66675 12.721 1.75455 12.933 1.91083 13.0893C2.06711 13.2455 2.27907 13.3333 2.50008 13.3333H11.6667C11.8878 13.3333 12.0997 13.2455 12.256 13.0893C12.4123 12.933 12.5001 12.721 12.5001 12.5V6.25L11.6667 7.08333V12.5Z" fill={fillColor} />
        <path d="M13.9708 2.43333L12.5666 1.02916C12.5043 0.966677 12.4303 0.917099 12.3488 0.883271C12.2672 0.849444 12.1799 0.832031 12.0916 0.832031C12.0034 0.832031 11.916 0.849444 11.8345 0.883271C11.753 0.917099 11.6789 0.966677 11.6166 1.02916L5.90411 6.775L5.44161 8.77916C5.4219 8.87631 5.42398 8.97662 5.44767 9.07288C5.47137 9.16913 5.51611 9.25893 5.57866 9.33583C5.64121 9.41273 5.72003 9.47481 5.80945 9.5176C5.89886 9.5604 5.99665 9.58285 6.09578 9.58333C6.14701 9.58897 6.19871 9.58897 6.24994 9.58333L8.27078 9.1375L13.9708 3.38333C14.0333 3.32101 14.0828 3.24698 14.1167 3.16547C14.1505 3.08396 14.1679 2.99658 14.1679 2.90833C14.1679 2.82008 14.1505 2.7327 14.1167 2.65119C14.0828 2.56968 14.0333 2.49565 13.9708 2.43333ZM7.83744 8.36666L6.31244 8.70416L6.66661 7.19166L10.9666 2.8625L12.1416 4.0375L7.83744 8.36666ZM12.6124 3.56666L11.4374 2.39166L12.0833 1.73333L13.2666 2.91666L12.6124 3.56666Z" fill={fillColor} />
      </g>
      <defs>
        <clipPath id="clip0_182_4231">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default EditIcon