import { useState } from "react";
import { noteCache, noteVar } from "../../../../localstate/Cache";
import { GET_NOTE_ITEMS } from "../../../../localstate/Cart";

type Props = {
  setOpenNotePopup?: any;
  noteItems?: string;
};
const PosScreenNote = (props: Props) => {
  const { setOpenNotePopup, noteItems } = props;
  const [note, setNote] = useState(noteItems);

  const handleNote = () => {
    setOpenNotePopup(false);
    noteCache.writeQuery({
      query: GET_NOTE_ITEMS,
      data: {
        noteItems: noteVar(note),
      },
    });
  };

  return (
    <div>
      <textarea
        onChange={(e: any) => setNote(e.target.value)}
        className="w-full h-[80px] border-solid border-2 border-indigo-600"
        value={note}
      />
      <button
        onClick={handleNote}
        className={`w-[20%] h-[50px] rounded-md text-white mr-2 mt-2
            bg-[#4338CA]`}
      >
        Add Note
      </button>
    </div>
  );
};

export default PosScreenNote;
