import { gql } from "@apollo/client";
const GET_SEARCH_SELETCED_ITEMS = gql`
  query GetSearchSelectedItems {
    searchSelectedItems @client
  }
`;
const GET_SEARCH_ITEMS = gql`
  query GetSearchItems {
    searchItems @client
  }
`;
const GET_CART_ITEMS = gql`
  query GetCartItems {
    cartItems @client
  }
`;

const GET_PERMISSION_TYPE = gql`
  query GetPermissionType {
    permissionTypes @client
  }
`;
const GET_ORDER_PRINT_ITEMS = gql`
  query GetOrderPrintItems {
    orderPrintItems @client
  }
`;
const GET_TABLE_ITEMS = gql`
  query GetTableItems {
    tableItems @client
  }
`;
const GET_ORDER_TYPE = gql`
  query GetOrderType {
    orderType @client
  }
`;

const GET_REPORT_POPUP_OPEN = gql`
  query GetReportPopupOpen {
    reportPopup @client
  }
`;

const GET_SPLIT_PAY = gql`
  query GetSplitPay {
    orderType @client
  }
`;
const GET_CUSTOMER_ITEMS = gql`
  query GetCustomerItems {
    customerItems @client
  }
`;
const GET_KITCHEN_CARD_ID = gql`
  query GetKitchenOrderId {
    kitchenOrderId @client
  }
`;
const GET_MODIFIED_KITCHEN_CARD_ID = gql`
  query GetModifiedKitchenOrderId {
    modifiedKitchenOrderId @client
  }
`;

const GET_HOLD_KITCHEN_CARD_ID = gql`
  query GetHoldKitchenOrderId {
    holdKitchenOrderId @client
  }
`;
const GET_KITCHEN_ALERT = gql`
  query GetKitchenAlert {
    kitchenAlert @client
  }
`;
const GET_TOTAL_CALCULATION = gql`
  query GetTotalCalculation {
    totalCalculationItems @client
  }
`;
const GET_NOTE_ITEMS = gql`
  query GetNoteItems {
    noteItems @client
  }
`;

const GET_MYORDERS = gql`
  query GetMyOrderItems {
    myOrderItems @client
  }
`;

const GET_QR_CART = gql`
  query GetQrCart {
    qrCart @client
  }
`;
const GET_DISABLE_FOCUS = gql`
  query getDisableFocusCache {
    disableFocus @client
  }
`;
const GET_OPEN_TABLE = gql`
  query GetOpenTable {
    openTable @client
  }
`;


export {
  GET_SEARCH_ITEMS,
  GET_CART_ITEMS,
  GET_PERMISSION_TYPE,
  GET_KITCHEN_CARD_ID,
  GET_HOLD_KITCHEN_CARD_ID,
  GET_TOTAL_CALCULATION,
  GET_TABLE_ITEMS,
  GET_ORDER_TYPE,
  GET_SPLIT_PAY,
  GET_CUSTOMER_ITEMS,
  GET_NOTE_ITEMS,
  GET_MODIFIED_KITCHEN_CARD_ID,
  GET_ORDER_PRINT_ITEMS,
  GET_SEARCH_SELETCED_ITEMS,
  GET_MYORDERS,
  GET_QR_CART,
  GET_OPEN_TABLE,
  GET_REPORT_POPUP_OPEN, GET_DISABLE_FOCUS,
  GET_KITCHEN_ALERT
};

