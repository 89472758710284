
type Props = {
  fillColor: string;
}

const DeleteIcon = (props: Props) => {
  const { fillColor } = props;
  return (
    <svg width="15" height="15" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5 2.125C11.6658 2.125 11.8247 2.19085 11.9419 2.30806C12.0592 2.42527 12.125 2.58424 12.125 2.75C12.125 2.91576 12.0592 3.07473 11.9419 3.19194C11.8247 3.30915 11.6658 3.375 11.5 3.375H10.875L10.8731 3.41937L10.29 11.5887C10.2676 11.9041 10.1264 12.1993 9.89507 12.4147C9.66371 12.6302 9.35929 12.75 9.04313 12.75H3.95625C3.64009 12.75 3.33567 12.6302 3.1043 12.4147C2.87294 12.1993 2.73182 11.9041 2.70937 11.5887L2.12625 3.42C2.1253 3.40502 2.12489 3.39001 2.125 3.375H1.5C1.33424 3.375 1.17527 3.30915 1.05806 3.19194C0.940848 3.07473 0.875 2.91576 0.875 2.75C0.875 2.58424 0.940848 2.42527 1.05806 2.30806C1.17527 2.19085 1.33424 2.125 1.5 2.125H11.5ZM7.75 0.25C7.91576 0.25 8.07473 0.315848 8.19194 0.433058C8.30915 0.550268 8.375 0.70924 8.375 0.875C8.375 1.04076 8.30915 1.19973 8.19194 1.31694C8.07473 1.43415 7.91576 1.5 7.75 1.5H5.25C5.08424 1.5 4.92527 1.43415 4.80806 1.31694C4.69085 1.19973 4.625 1.04076 4.625 0.875C4.625 0.70924 4.69085 0.550268 4.80806 0.433058C4.92527 0.315848 5.08424 0.25 5.25 0.25H7.75Z" fill={fillColor} />
    </svg>
  )
}

export default DeleteIcon