import DataLoadSpinner from '../../reusable/Spinner/DataLoadSpinner'
import randomString from '../../../utils/randomString';


type Props = {
  setSelectedTableArray: any
  selectedTableArray: any
  tableId: string
  tableData: any
  isTableLoading: boolean
  handleMergeTable: any
}

const PosScreenMergeTable = (props: Props) => {
  const { selectedTableArray, setSelectedTableArray, tableId, tableData, isTableLoading, handleMergeTable } = props

  const handleTableClick = (pk: number) => {
    if (selectedTableArray.includes(pk.toString())) {
      setSelectedTableArray((prev: any) => prev.filter((elem: string) => elem !== pk.toString()))
    } else {
      setSelectedTableArray((prev: any) => [...prev, pk.toString()])
    }
  }


  return (
    <>
      {isTableLoading ? (
        <DataLoadSpinner />
      ) : (
        <>
          <div className="h-[520px] overflow-y-scroll">
              <div className="grid grid-rows-7 grid-cols-7 gap-2 p-2">
                {tableData?.floor?.tablesByFloor?.edges?.filter((item: any) =>
                  (item?.node?.isMergedTable === false && (item?.node?.pk).toString() !== tableId))
                  .map((elem: any) =>
                  (
                    <div
                      key={elem?.node?.pk}
                      className={`flex text-center border-2 bg-[#efefef] ${selectedTableArray.includes((elem?.node?.pk).toString()) ? "border-[#00BFFF]" : "border-[#efefef]"}
                   p-3 min-h-full max-h-[60px] cursor-pointer rounded-lg items-center shadow-sm text-gray-800`}
                      onClick={() => handleTableClick(elem?.node?.pk)}
                    >
                      <div className="mx-auto ">
                        <p className="flex">
                          {elem?.node?.title}
                          <br />
                          0/{elem?.node?.capacity}
                        </p>
                      </div>

                    </div>
                  ))
                }
              </div>
            </div>

          <div className='text-right mt-3'>
            <button
              onClick={() => handleMergeTable()}
              className={`w-[10%] h-[50px] text-white  bg-[#4338CA] rounded mr-5`}
            >
              Merge
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default PosScreenMergeTable