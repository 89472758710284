import { useLazyQuery, useMutation, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { SEARCH_PRODUCT_ITEMS } from "../../../pages/PosScreen/queries";
import { POS_SCREEN_PRINTER_LIST, POS_SCREEN_PRODUCT_CATEGORY, POS_SCREEN_SINGLE_PRODUCT } from "../../../pages/Product/queries";
import { QUERY_TAX, QUERY_UNIT } from "../../../pages/queries";
import {
  cache,
  cartItemsVar,
  searchItemsVar,
  searchSelectedCache,
  searchSelectedItemsVar,
  tableItemsVar,
} from "../../localstate/Cache";
import { GET_CART_ITEMS, GET_SEARCH_SELETCED_ITEMS } from "../../localstate/Cart";
import DataLoadSpinner from "../../reusable/Spinner/DataLoadSpinner";
import PosScreenCartSection from "./PosScreenCartSection/PosScreenCartSection";
import PosScreenCategoryTab from "./PosScreenCategoryTab";
import PosScreenSearchProductTab from "./PosScreenSearchProductTab";
import { toast } from "react-toastify";
import Modal from "../../reusable/Modal";
import AddProductImage from '../../../assets/addproductwhite.svg'
import PrimaryButton from "../../reusable/Buttons/PrimaryButton";
import { getLocalKey, setLocalKey } from "../../../helpers/sessionKey";
import Spinner from "../../reusable/Spinner";
import useHandleToggleSelectedTable from "../../../customhooks/useHandleToggleSelectedTable";
import { POS_SCREEN_CREATE_PRODUCT } from "../../../pages/Product/mutation";
import TextField from "../../reusable/TextField";
import { useForm } from "react-hook-form";
import PriceField from "../../reusable/PriceField";
import MultipleSelectField from "../../reusable/MultipleSelectField";
import OpenProductForm from "../OpenProductForm";

const PosScreen = (props: any) => {
  const {
    isLoading,
    categoriesData,
    handleNextPage,
    handlePreviousPage,
    handleCategoryClick,
    handleCategoryRefetch,
    handleProductRefetch,
    productsData,
    posScreenProductByCategoryDataLoading,
    productByCatId,
    noOfColumns,
  } = props;
  const searchItems: any = useReactiveVar(searchItemsVar);
  const cartItems: [] = useReactiveVar(cartItemsVar);
  const tableItems: any = useReactiveVar(tableItemsVar);


  //Search Query for products
  const [
    getSearchProduct,
    { loading: searchProductLoading, data: searchProductData },
  ] = useLazyQuery(SEARCH_PRODUCT_ITEMS);

  useEffect(() => {
    // wait 500ms before executing search
    let timeoutID = setTimeout(async () => {
      // do not search if input is empty
      if (searchItems.searchInput) {
        getSearchProduct({
          variables: {
            search:
              searchItems?.name === "searchProduct"
                ? searchItems.searchInput
                : "",
            barcode:
              searchItems?.name === "searchBarcode"
                ? searchItems.searchInput
                : "",
            plu:
              searchItems?.name === "searchPlu" ? searchItems.searchInput : "",

          },
        });
      }
    }, 200);

    // CLEANUP: clear current timer
    return () => {
      clearTimeout(timeoutID);
    };
  }, [searchItems]);

  useEffect(() => {
    if (searchProductData?.products) {
      const searchProductDataItems: any = {
        index: 0,
        data: searchProductData ?? {},
      };
      searchSelectedCache.writeQuery({
        query: GET_SEARCH_SELETCED_ITEMS,
        data: {
          searchSelectedItems: searchSelectedItemsVar(searchProductDataItems),
        },
      });
    }
  }, [searchProductData]);
  const [addQuickProductPopup, setAddQuickProductPopup] = useState<boolean>(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [productCreate, { loading: loadingCreateProduct }] = useMutation(POS_SCREEN_CREATE_PRODUCT, {});
  const { register, setValue, getValues, handleSubmit, formState: { errors } } = useForm({})

  const handleQuickProduct = async (values: any) => {
    if (isLoadingButton) { return; }
    setIsLoadingButton(true);
    setAddQuickProductPopup(false);
    setLocalKey("disableRemotePrint", "false");
    const response = await productCreate({
      variables: {
        title: values?.title,
        sellingPrice: values?.price,
        printers: values?.printer ?? [],
        isOpenProduct: true,
        plu: "",
        modifierCategories: [],
        category: [],
        unit: null,
        isActive: true,
        tax: null,
        isModifier: false,
        isWeightable: false,
        printOrder: null,
        isModifierCategoryGrid: false,
        isOpenPriceProduct: false,
        image: null,
        costPrice: "",
        barcodeContent: "",
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      let productList = {
        id: response?.data?.createProduct?.product?.id,
        pk: response?.data?.createProduct?.product?.pk,
        title: response?.data?.createProduct?.product?.title,
        quantity: parseFloat("1").toFixed(2),
        price: response?.data?.createProduct?.product?.sellingPrice ?? 0,
        total: response?.data?.createProduct?.product?.sellingPrice ?? 0,
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        tax: "0.00",
        taxRate: {
          isIncluded: true,
          rate: "0.00"
        }

      }
      let nonPreviousItemList = cartItems?.map(
        (item: any, index: number) => ({
          ...item,
          isSelected: false,
        }),
      );
      cache.writeQuery({
        query: GET_CART_ITEMS,
        data: {
          cartItems: cartItemsVar([
            ...nonPreviousItemList,
            productList]),
        },
      });
      toast.success("Product Created successfully");

    } else {
      toast.error("Product Failed to Create");

    }
    setIsLoadingButton(false)

  }

  //Initial Lists

  const [categoryDropdown, setCategoryDropdown] = useState([]);
  const [taxTypeDropdown, setTaxTypeDropdown] = useState([]);
  const [printerListsDropdown, setPrinterListsDropdown] = useState([]);
  const [modifierCategoryDropdown, setModifierCategoryDropdown] = useState([])
  const [isModifier, setIsModifier] = useState<boolean>(false)

  //Product Categories
  const { data: categoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: isModifier ? "modifier" : "visible"
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    categoryData?.categories?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    ));
    setCategoryDropdown(dropdownFormat);
  }, [categoryData]);

  //Unit Type
  const { data: unitData } = useQuery(QUERY_UNIT, {
    variables: {
      first: 100,
    },
  });
  const [unitDropdown, setUnitDropdown] = useState([{
    id: unitData?.units?.[0]?.node.pk,
    title: unitData?.units?.[0]?.node.title,
  }]);

  useEffect(() => {
    const dropdownFormat: any = [];
    unitData?.units?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.title,
      }),
    );
    setUnitDropdown(dropdownFormat);
  }, [unitData]);



  //Tax List
  const { data: taxData } = useQuery(QUERY_TAX, {
    variables: {
      first: 100,
      offset: 0,
      title_Icontains: "",
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    taxData?.taxes?.edges?.map((elem: any) => (
      dropdownFormat.push(
        {
          id: elem?.node?.pk,
          title: elem?.node?.title,
          rate: elem?.node?.rate,
          isIncluded: elem?.node?.isIncluded
        })
    ));
    dropdownFormat.push({ id: 0, title: "None", rate: 0, isIncluded: true })
    setTaxTypeDropdown(dropdownFormat);
  }, [taxData]);
  //Printer Lists
  const { data: printerListsData } = useQuery(POS_SCREEN_PRINTER_LIST, {
    variables: {
      first: 10,
    }
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    printerListsData?.printers?.edges.map((elem: any) =>
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.department,
      }),
    );
    setPrinterListsDropdown(dropdownFormat);
  }, [printerListsData]);

  //Modifier Category
  const { data: modifierCategoryData } = useQuery(POS_SCREEN_PRODUCT_CATEGORY, {
    variables: {
      first: 100,
      level: 0,
      status: "modifier"
    },
  });
  useEffect(() => {
    const dropdownFormat: any = [];
    modifierCategoryData?.categories?.edges?.map((elem: any) => (
      dropdownFormat.push({
        id: elem?.node?.pk,
        title: elem?.node?.name,
      })
    ));
    setModifierCategoryDropdown(dropdownFormat);
  }, [modifierCategoryData]);

  const [promoProducts, setPromProducts] = useState<any>([])
  const [promotionInfo, setPromotionInfo] = useState<any>([])

  const { data: singleProductData } = useQuery(POS_SCREEN_SINGLE_PRODUCT, {
    variables: {
      id: promotionInfo?.id ?? "",
    },
  });
  let cartItemLength: any = cartItems.filter((item: any) => item?.quantity === 1)
  let cartItemLength1: any = cartItems.filter((item: any) => item?.quantity > 1)

  const obj: any = cartItemLength.reduce((acc: any, cur: any, index: any) => {
    acc[cur.id] = { index: cur };
    return acc

  }, {});

  const output = Object.values(obj).sort((a: any, b: any) => a.index - b.index).map(({ index: val }: any) => val)

  var unique = cartItemLength.filter((v: any, i: any, a: any) => a.findIndex((v2: any) => (v2?.modifier?.length < 1 && v2.id === v.id && v2.quantity === v.quantity)) !== i)


  const handlePromoItem = () => {

    let keys: any = cartItemLength.map((el: any) => el?.modifier?.length < 1 && el.title)
    if (promoProducts?.length && promoProducts.every((item: any) => keys.includes(item))) {

      let productInCartList = {
        id: singleProductData?.product?.id,
        pk: singleProductData?.product?.pk,
        contentTypeId: singleProductData?.product?.contentTypeId,
        title: singleProductData?.product?.title,
        quantity: 1,
        price: singleProductData?.product?.finalPrice,
        total: singleProductData?.product?.finalPrice,
        tax: singleProductData?.product?.tax ?? "0.00",
        taxRate: { rate: singleProductData?.product?.tax?.rate ?? 0, isIncluded: singleProductData?.product?.tax?.isIncluded },
        isSelected: false,
        isDeleted: false,
        modifier: [],
        isModified: true,
      };

      const itemsThatMAtchPromoCodes: any = output.filter((cartItem: any) => !cartItem?.modifier?.length && !promoProducts.includes(cartItem?.title))

      singleProductData?.product?.id &&
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([
              ...cartItemLength1,
              ...itemsThatMAtchPromoCodes,
              ...unique,
              productInCartList,
            ]),
          },
        })
      toast.info(`${promotionInfo?.title} Promotion found!`);

    }
  }
  useEffect(() => {
    let cartItemLength: any = cartItems.filter((item: any) => item?.modifier?.length < 1 && item?.quantity > 0)
    if (cartItemLength?.length)
      handlePromoItem()

  }, [cartItems])
  return (
    <div className="relative w-full h-full flex gap-8 ">

      {searchItems.searchInput !== "" && searchProductData !== undefined ? (
        searchProductLoading ? (
          <DataLoadSpinner />
        ) : (
          <div className="w-full h-full flex-[70%]">

            <PosScreenSearchProductTab
              searchProductData={searchProductData}
              categoryDropdown={categoryDropdown}
              taxTypeDropdown={taxTypeDropdown}
              unitDropdown={unitDropdown}
              printerListsDropdown={printerListsDropdown}
              modifierCategoryDropdown={modifierCategoryDropdown}
              cartItems={cartItems}
              setPromotionInfo={setPromotionInfo}
              setPromProducts={setPromProducts}
              noOfColumns={noOfColumns}
            />
          </div>
        )
      ) : (
        <div className="relative w-full h-full flex-[70%]">
          <button
            className="absolute bottom-5 z-10 right-0 font-bold p-3 rounded-full bg-blue-500 shadow-2xl"
            style={{ boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)' }}
            onClick={() => { setAddQuickProductPopup(true) }}
          >
            <img src={AddProductImage} alt="" className="w-[35px] h-[35px]" />
          </button>
          {isLoading ? (
            // <DataLoadSpinner />
            ""
          ) : (
            <PosScreenCategoryTab
              isLoading={isLoading}
              categoriesData={categoriesData}
              handleNextPage={handleNextPage}
              handlePreviousPage={handlePreviousPage}
              handleCategoryClick={handleCategoryClick}
              handleCategoryRefetch={handleCategoryRefetch}
              handleProductRefetch={handleProductRefetch}
              posScreenProductByCategoryDataLoading={
                posScreenProductByCategoryDataLoading
              }
              productsData={productsData}
              productByCatId={productByCatId}
              noOfColumns={noOfColumns}
              categoryDropdown={categoryDropdown}
              taxTypeDropdown={taxTypeDropdown}
              unitDropdown={unitDropdown}
              setIsModifier={setIsModifier}
              printerListsDropdown={printerListsDropdown}
              modifierCategoryDropdown={modifierCategoryDropdown}
            />
          )}
        </div>
      )}
      <div className=" w-full h-full flex-[30%] mx-0">
        <PosScreenCartSection />
      </div>
      <Modal title={"Add Open Product"} open={addQuickProductPopup} setOpen={setAddQuickProductPopup} size="max-w-sm">
        <OpenProductForm printerListsDropdown={printerListsDropdown ?? []}
          setValue={setValue}
          getValues={getValues}
          register={register}
          errors={errors}
          isLoadingButton={isLoadingButton}
          loadingCreateProduct={loadingCreateProduct}
          handleQuickProduct={handleQuickProduct}
          handleSubmit={handleSubmit}
        />
      </Modal>


    </div >
  );

};
export default PosScreen;
