
type Props = {
  tableId: string
  tableData: any
  isTableLoading: boolean
  handleSplitTable: any
  quantity: number
  setQuantity: (value: number) => void
}

const PosScreenSplitTable = (props: Props) => {
  const {
    tableId,
    tableData,
    isTableLoading,
    handleSplitTable,
    quantity,
    setQuantity
  } = props
  return (
    <>
      <div className=''>
      <input
        id={""}
        type="text"
        name={"name"}
        className="relative peer bg-gray-200 p-2 rounded w-full text-gray-700 focus: outline-none border-b-2 border-gray-300 focus:border-purple-600 transition duration-500"
        placeholder={ " Enter Quantity"}
        value={quantity}
        disabled={false}
        onChange={(e: any) => setQuantity(e.target.value)}
        autoComplete="off"
      />
      </div>
      <div className='text-right mt-3'>
        <button
          onClick={() => handleSplitTable(tableId,quantity)}
          className={`w-[20%] h-[50px] text-white  bg-[#4338CA] rounded mr-0`}
        >
          Split
        </button>
      </div>
    </>
  )
}

export default PosScreenSplitTable