import {useQuery} from "@apollo/client";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import {POS_SCREEN_ORDERED_ITEMS_LIST} from "../queries";
import usePaginationHook from "../../../customhooks/usePaginationHook";

const PosScreenOrderComponent: any = Loadable({
  loader: () => import("../../../components/Orders/OrderList/OrderList"),
  loading: DataLoadSpinner,
});

const PosScreenOrder = () => {
  const {offset, setOffset, pageSize, setPageSize} = usePaginationHook()
  
  const {loading: ordersDataLoading, data: ordersData} = useQuery(
    POS_SCREEN_ORDERED_ITEMS_LIST,
    {
      fetchPolicy: "no-cache",
      variables: {
        offset: offset,
        first: pageSize
      }
    },
  );
  

  return (
    <div className="w-full">
      <PosScreenOrderComponent
        isLoading={ordersDataLoading}
        data={ordersData?.orders}
        isPosScreenKitchenOrder={true}
        setPageSize={setPageSize}
        pageSize={pageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
};

export default PosScreenOrder;
