import TextField from '../../reusable/TextField'
import DynamicDropDown from '../../reusable/DynamicDropDown'
import MultipleSelectField from '../../reusable/MultipleSelectField'
import PriceField from '../../reusable/PriceField'

const ModifierForm = (props: any) => {
  const { register, getValues, categoryDropdown, setValue, errors, unitDropdown, taxTypeDropdown } = props
  return (
    <div className="mb-4 p-4 bg-slate-100">
      <div className="grid grid-cols-1 gap-4">
        <TextField register={register} name={"title"} label={"Title"} />
        <MultipleSelectField
          name={"category"}
          label={"Select on or more category"}
          titleLabel={"Category"}
          values={getValues("category")}
          errors={errors}
          setFieldValue={setValue}
          options={categoryDropdown}
        />
        <div className="w-full flex justify-between gap-10">
          <DynamicDropDown name={"unit"} label={"Unit"} setFieldValue={setValue} values={getValues("unit")} list={unitDropdown} />
          <DynamicDropDown name={"taxType"} label={"Taxable Type"} setFieldValue={setValue} values={getValues("taxType")} list={taxTypeDropdown} />
        </div>
        <div className="w-full flex justify-between gap-10">
          <PriceField name={"costPrice"} label={"Cost Price"} setFieldValue={setValue} values={getValues("costPrice")} />
          <PriceField name={"sellingPrice"} label={"Selling Price"} setFieldValue={setValue} values={getValues("sellingPrice")} />
        </div>
      </div>
    </div>
  )
}

export default ModifierForm