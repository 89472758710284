import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import apolloClient from "./apolloClient";
import Router from "./router";
import React from "react";


const App = () => {
  document.addEventListener('contextmenu', event => {
    event.preventDefault();
  })
  return (
    <>
      <ApolloProvider client={apolloClient}>
        <Router />
      </ApolloProvider>
      <ToastContainer autoClose={800} />
    </>
  );
};

export default App;
