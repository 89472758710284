import React from 'react'
import TabPanel from '../../../../reusable/TabPanel/TabPanel'
import OverAllDiscountForm from './OverAllDiscountForm';
import IndividualDiscountForm from './IndividualDiscountForm';
import { useReactiveVar } from '@apollo/client';
import { cartItemsVar } from '../../../../localstate/Cache';

type Props = {
  selectDiscountType?: any;
  setSelectDiscountType?: any;
  open?: any;
  setOpen?: any;
  totalAmtCalculations?: any;
  selectedtitle?: any;
}

const DiscountForm = (props: Props) => {
  const { selectDiscountType, setSelectDiscountType, setOpen, totalAmtCalculations } = props;
  const cartItems: any = useReactiveVar(cartItemsVar);

  let cartValue: any = cartItems?.findIndex((cartItem: any, index: number) => cartItem?.isSelected === true);
  return (
    <>
      {cartValue > -1 ? <>
        <div className="absolute top-0 text-white left-[40%] text-lg">{"Discount"}</div>

        <TabPanel tabTitle={["Individual Discount", "OverAll Discount"]} tabPanels={[<>
          <IndividualDiscountForm
            selectDiscountType={selectDiscountType}
            setSelectDiscountType={setSelectDiscountType}
            setOpen={setOpen}
            totalAmtCalculations={totalAmtCalculations}
            cartValue={cartValue}
            cartItems={cartItems}
          />
        </>,
        <>
          <OverAllDiscountForm
            selectDiscountType={selectDiscountType}
            setSelectDiscountType={setSelectDiscountType}
            setOpen={setOpen}
            totalAmtCalculations={totalAmtCalculations}
          />
        </>
        ]
        } />
      </> : <>
        <div className="absolute top-0 text-white left-[40%] text-lg">{"OverAll Discount"}</div>
        <OverAllDiscountForm
          selectDiscountType={selectDiscountType}
          setSelectDiscountType={setSelectDiscountType}
          setOpen={setOpen}
          totalAmtCalculations={totalAmtCalculations}
        />
      </>

      }


    </>
  )
}

export default DiscountForm