
type Props = {
  input: string,
  setInput: any,
  children: any,
  name: string
}

const ChangeModalForm = (props: Props) => {
  const { input, setInput, children, name } = props;


  const onChangeInput = (event: any) => {
    event.preventDefault();
    setInput(event.target.value);
    // event.stopPropagation();
  };
  return (
    <div>
      <div >
        <label className="block w-full px-1 text-left  text-sm">Search Item:</label>
        <input
          id={""}
          className="block w-full h-[38px] bg-[#FFFFFF2D] px-4 py-2 m-1 text-left text-gray-800 rounded-sm border-2 placeholder-gray-800 focus:outline-gray-800  sm:text-sm"
          placeholder={"Enter Item..."}
          type="search"
          name={name}
          value={input}
          onKeyUp={(e) => onChangeInput(e)}
          onChange={(e) => onChangeInput(e)}
          autoComplete={"off"}
        />
      </div>
      {children}
    </div>
  )
}

export default ChangeModalForm