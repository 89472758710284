import { BackspaceIcon } from "@heroicons/react/solid";
import { useState } from "react";

export const fastCashList = [
  { id: Math.random().toString(36).substr(2, 5), label: "5C", value: 0.05 },
  { id: Math.random().toString(36).substr(2, 5), label: "10C", value: 0.1 },
  { id: Math.random().toString(36).substr(2, 5), label: "20C", value: 0.2 },
  { id: Math.random().toString(36).substr(2, 5), label: "50C", value: 0.5 },
  { id: Math.random().toString(36).substr(2, 5), label: "$1", value: 1 },
  { id: Math.random().toString(36).substr(2, 5), label: "$2", value: 2 },
  { id: Math.random().toString(36).substr(2, 5), label: "$5", value: 5 },
  { id: Math.random().toString(36).substr(2, 5), label: "$10", value: 10 },
  { id: Math.random().toString(36).substr(2, 5), label: "$20", value: 20 },
  { id: Math.random().toString(36).substr(2, 5), label: "$50", value: 50 },
  { id: Math.random().toString(36).substr(2, 5), label: "$100", value: 100 },
];
export const numberPad = [
  { id: Math.random().toString(36).substr(2, 5), label: "1", value: "1" },
  { id: Math.random().toString(36).substr(2, 5), label: "2", value: "2" },
  { id: Math.random().toString(36).substr(2, 5), label: "3", value: "3" },
  {
    id: Math.random().toString(36).substr(2, 5),
    label: "Clear",
    value: "clear",
  },
  { id: Math.random().toString(36).substr(2, 5), label: "4", value: "4" },
  { id: Math.random().toString(36).substr(2, 5), label: "5", value: "5" },
  { id: Math.random().toString(36).substr(2, 5), label: "6", value: "6" },
  {
    id: Math.random().toString(36).substr(2, 5),
    label: <BackspaceIcon className="w-5 h-5" />,
    value: "backspace",
  },
  { id: Math.random().toString(36).substr(2, 5), label: "7", value: "7" },
  { id: Math.random().toString(36).substr(2, 5), label: "8", value: "8" },
  { id: Math.random().toString(36).substr(2, 5), label: "9", value: "9" },
];
const numberWithDot = /^[0-9]*\.?[0-9]*$/; //regx for only number with one dot
type Props = {
  openPayPopup?: any;
  setOpenPayPopup?: any;
  totalAmtCalculations?: any;
  enteredNumber?: any;
  setEnteredNumber?: any;
  paymentOrderId?: any;
  openPayPrintBillPopup?: any;
  setOpenPayPrintBillPopup?: any;
  selectedCustomer?: any;
};
const

  PayForm = (props: Props) => {
    const {

      totalAmtCalculations,
      enteredNumber,
      setEnteredNumber,
      selectedCustomer,

    } = props;


    const [isActiveFastCash, setIsActiveFastCash] = useState<boolean>(false)

    const handleEnteredNumber = (e: any) => {
      const { value } = e?.target;
      setIsActiveFastCash(false);

      isActiveFastCash ? setEnteredNumber(0) : setEnteredNumber(value);
      if (enteredNumber == 0) {
        setEnteredNumber(enteredNumber + parseInt(value))
      }
      if (!e.target.value || e.target.value === "") {
        setEnteredNumber(0);
      }

    };
    //For Topup fast cash
    const topPopAmount = (amount: number) => {
      setIsActiveFastCash(true)
      const enteredAmt: any = isActiveFastCash ? (amount) + parseFloat(enteredNumber) : (amount);
      if (enteredNumber !== "" && enteredNumber > 0) {
        const newAmt = eval(enteredAmt);
        setEnteredNumber(newAmt.toFixed(2));
      } else {
        setEnteredNumber(enteredAmt.toFixed(2));
      }
    };

    const handleCalculate = (data: string) => {
      switch (data) {
        case "5C":
          topPopAmount(0.05);
          break;
        case "10C":
          topPopAmount(0.1);
          break;
        case "20C":
          topPopAmount(0.2);
          break;
        case "50C":
          topPopAmount(0.5);
          break;
        case "$1":
          topPopAmount(1.0);
          break;
        case "$2":
          topPopAmount(2.0);
          break;
        case "$5":
          topPopAmount(5.0);
          break;
        case "$10":
          topPopAmount(10.0);
          break;
        case "$20":
          topPopAmount(20.0);
          break;
        case "$50":
          topPopAmount(50.0);
          break;
        case "$100":
          topPopAmount(100.0);
          break;

        case "backspace":
          if (enteredNumber?.length - 1) {
            setEnteredNumber(
              enteredNumber
                ?.toString()
                .substring(0, enteredNumber?.toString().length - 1),
            );
          } else {
            setEnteredNumber(0)
          }
          break;
        case "clear":
          setEnteredNumber(0);
          break;
        case "calculate":
          // setEnteredNumber(data)
          break;
        default:
          // setEnteredNumber( data);
          setIsActiveFastCash(false)
          if (enteredNumber === 0) {
            setEnteredNumber(data)
          }
          else if (numberWithDot.test(enteredNumber)) {
            isActiveFastCash ? setEnteredNumber(data) : setEnteredNumber(enteredNumber + data);
          }
      }
    };

    return (
      <>
        <div className="w-full flex justify-end">
          <div className="h-50 text-center mt-0 mb-2">
          </div>
        </div>
        <div className="w-full grid grid-cols-3 gap-3 text-left mb-6">
          <div>
            <p className="py-1">Total</p>
            <input
              className="w-full p-2 items-center border-2 border-solid border-black-800 rounded-sm"
              type="text"
              value={totalAmtCalculations?.grossAmount}
              disabled
            />
          </div>
          <div>
            <p className="py-1">Tender</p>
            <input
              className=" w-full p-2 items-center border-2 border-solid border-black-800 rounded-sm"
              type="text"
              value={enteredNumber}
              onChange={(e) => handleEnteredNumber(e)}
              name={"enteredNumber"}
              autoComplete={"off"}
              autoFocus
            // onKeyDown={(e) => handleEnteredNumber(e)}
            />
          </div>
          <div>
            <p className="py-1">Change</p>
            <input
              className="w-full p-2 items-center border-2 border-solid border-black-800 rounded-sm"
              type="text"
              value={parseFloat(enteredNumber) >= totalAmtCalculations?.grossAmount
                ? (
                  parseFloat(enteredNumber) - totalAmtCalculations?.grossAmount
                ).toFixed(2)
                : 0}
              disabled
            />
          </div>
        </div>

        <div className="w-full flex mb-6 text-center">
          <div className="col-span-4 w-[60%] mr-2">
            <div className="grid grid-cols-4 gap-1">
              {numberPad.map((item: any) => (
                <div
                  key={item.id}
                  className="col-span-1 w-full h-30 p-2 cursor-pointer bg-[#e0e0e0] text-[18px] rounded-md items-center justify-center"
                  onClick={() => handleCalculate(item.value)}
                >
                  <span>{item.label}</span>
                </div>
              ))}
              <div
                className="col-span-1 h-30 p-2 cursor-pointer bg-[#e0e0e0] text-[18px] rounded-md font-normal items-center justify-center"
                onClick={() => handleCalculate("0")}
              >
                <span>0</span>
              </div>
              <div
                className="col-span-1 w-full p-2 cursor-pointer bg-[#e0e0e0] text-[18px] rounded-md font-normal items-center justify-center"
                onClick={() => handleCalculate(".")}
              >
                <span>.</span>
              </div>
              <div
                className="col-span-1 w-full p-2 cursor-pointer bg-[#e0e0e0] text-[18px] rounded-md font-normal items-center justify-center"
                onClick={() => handleCalculate("00")}
              >
                <span>00</span>
              </div>
              <div
                className="col-span-2 w-full p-2 cursor-pointer bg-[#e0e0e0] text-[18px] rounded-md font-normal items-center justify-center"
                onClick={() => handleCalculate("calculate")}
              >
                <span>Enter</span>
              </div>
            </div>
          </div>
          <div className="col-span-2 w-[40%]">
            <div className="grid grid-cols-3  gap-1">
              {fastCashList.map((item) => (
                <div
                  key={item.id}
                  className="col-span-1 w-full p-2 cursor-pointer bg-[#e0e0e0] text-[18px] rounded-md font-normal items-center justify-center"
                  onClick={() => handleCalculate(item.label)}
                >
                  <span key={item.id}>{item.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

export default PayForm;
