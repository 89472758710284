
import { useState, useMemo, useEffect, Fragment } from "react";
import _ from "lodash";
import RGL, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import useContextMenu from "../../../customhooks/useContext";
import { useMutation } from "@apollo/client";
import { POS_SCREEN_TABLE_UPDATE_FLOOR_LAYOUT } from "../../../pages/PosScreen/queries";
import { GET_RESTAURANT_FLOOR } from "../../../pages/queries";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";

const ReactGridLayout = WidthProvider(RGL);

const DropDrag = (props: any) => {
  const { layout: databaseLayouts, tables: floorTables, floorPk, floorID } = props;
  const { handleTable, handleUnmergeOption, handleSwapOption, handleMergeOption, handleSplitOption, handleDisableSplitOption, updateTablePopup, setUpdateTablePopup } = props;
  const [updateFloorLayout] = useMutation(POS_SCREEN_TABLE_UPDATE_FLOOR_LAYOUT, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorID,
        },
      },
    ],
  })

  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);
  const calculatedLayout = useMemo(() => _.map(_.range(0, floorTables.length), function (item, i) {
    var y = Math.ceil(Math.random() * 4) + 1;
    return {
      x: (_.random(0, floorTables.length) * 2) % 50,
      y: Math.floor(i / 6) * y,
      w: 2,
      h: y,
      i: i.toString(),
    };
  }), [floorTables.length]);
  const [layouts, setLayouts] = useState(calculatedLayout);
  useEffect(() => {
    if (!databaseLayouts) {
      setLayouts([...calculatedLayout]);
    } else {
      const parsedLayouts = JSON.parse(databaseLayouts || []);
      const reconciledLayouts = calculatedLayout.map((calcItem) => {
        const existingItem = parsedLayouts.find((dbItem: any) => dbItem.i === calcItem.i);
        return existingItem ? { ...existingItem } : { ...calcItem };
      });
      setLayouts([...reconciledLayouts]);
    }
  }, [databaseLayouts, calculatedLayout, floorPk]);
  const onLayoutChange = (layout: any) => {
    setLayouts((prevLayouts) => {
      const updatedLayouts = layout.map((item: any) => {
        const existingItem = prevLayouts.find((prevItem) => prevItem.i === item.i);
        return existingItem ? { ...existingItem, ...item } : item;
      });
      return [...updatedLayouts];
    });
  };

  const generateDOM = () => {
    return _.map(layouts, function (l, i) {
      const isClickedTable = i === clickedIndex;
      return (
        <div
          key={i}
          className={`${floorTables[i]?.node?.status === "OCCUPIED" ? "bg-[#4338CA] text-white " : "bg-[#efefef] text-gray-800"} relative flex justify-between cursor-pointer`}
          onContextMenu={(e) => {
            if (e.type === 'contextmenu') {
              console.log(e);
              e.preventDefault();
              setClicked(true);
              setClickedIndex(i);
              setPoints({
                x: e.pageX,
                y: e.pageY,
              });
            }
          }}
        >
          <p className='absolute w-full h-full text-left left-2'
            onClick={() => { updateTablePopup ? null : handleTable(floorTables[i]) }}
          >{floorTables[i]?.node?.title}</p>
          {clicked && isClickedTable && (
            <Menu as={Fragment}   >
              <Menu.Items
                static
                className='z-10 p-2 bg-white border rounded shadow'
                style={{
                  width: "70px",
                  height: `${floorTables[i]?.node?.status === "OCCUPIED" ? 'p-4' : "70px"}` && '80px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                {
                  floorTables[i]?.node?.isMergedTable ?
                    <Menu.Item as="div">
                      <button
                        className={`  text-gray-800 flex justify-center w-full  rounded-md p-2 text-sm hover:bg-slate-200`}
                        data-table-id={floorTables[i]?.node?.pk}
                        onClick={(e) => handleUnmergeOption(e)}
                      >
                        Unmerge
                      </button>
                    </Menu.Item> :
                    null
                }

                {
                  floorTables[i]?.node?.isSubTableVisible ?
                    <Menu.Item as="div">
                      <button
                        className={`  text-gray-800 flex justify-center w-full  rounded-md p-2 text-sm hover:bg-slate-200`}
                        data-table-id={floorTables[i]?.node?.pk}
                        onClick={(e) => handleDisableSplitOption(e)}
                      >
                        Disable Split
                      </button>
                    </Menu.Item> :
                    null
                }

                {
                  floorTables[i]?.node?.status === "OCCUPIED" ?
                    <span>
                      <Menu.Item as="div">
                        <button
                          className={`  text-gray-800 flex justify-center w-full  rounded-md p-1 text-sm hover:bg-slate-200`}
                          data-table-id={floorTables[i]?.node?.pk}
                          onClick={(e) => handleSwapOption(e)}
                        >
                          Swap
                        </button>
                      </Menu.Item>
                    </span>
                    : null
                }

                {!floorTables[i]?.node?.isMergedTable && !floorTables[i]?.node?.isSubTableVisible ?

                  <Menu.Item as="div">
                    <button
                      className={`  text-gray-800 flex justify-center w-full  rounded-md p-1 text-sm hover:bg-slate-200`}
                      data-table-id={floorTables[i]?.node?.pk}
                      onClick={(e) => handleMergeOption(e)}
                    >
                      Merge
                    </button>
                  </Menu.Item> : null
                }
                {!floorTables[i]?.node?.isMergedTable && !floorTables[i]?.node?.isSubTableVisible ?
                  <Menu.Item as="div">
                    <button
                      className={` text-gray-800 flex justify-center w-full  rounded-md p-1 text-sm hover:bg-slate-200`}
                      data-table-id={floorTables[i]?.node?.pk}
                      onClick={(e) => handleSplitOption(e)}
                    >
                      Split
                    </button>
                  </Menu.Item>
                  : null}
              </Menu.Items>
            </Menu>
          )}
        </div>
      );
    });
  };


  const handleSaveLayout = async () => {
    const response: any = await updateFloorLayout({
      variables: {
        id: floorPk,
        layout: JSON.stringify(layouts)
      }
    })
    if (response?.data?.updateFloorLayout?.success) {
      toast.success(response?.data?.updateFloorLayout?.messages)
      setUpdateTablePopup(false);
    } else {
      toast.error(response?.data?.updateFloorLayout?.messages)
    }
  }

  return (
    <>
      <div className="mb-4" >
        <ReactGridLayout
          {...props}
          style={{ background: "white" }}
          layout={layouts}
          compactType={null}
          useCSSTransforms={false}
          onLayoutChange={onLayoutChange}
          isDraggable={updateTablePopup}
          isResizable={updateTablePopup}
        >
          {generateDOM()}
        </ReactGridLayout>


      </div>
      <div className="flex flex-wrap gap-4 absolute bottom-0 right-8 p-2">
        <button className={`${updateTablePopup ? 'bg-gray-200' : 'bg-[#4338CA] text-white'} p-2 rounded`} onClick={() => setUpdateTablePopup(false)}>Tables</button>
        {updateTablePopup ?
          <button className={" p-2 bg-[#4338CA] text-white rounded"} onClick={handleSaveLayout}> Save Layout</button>
          :
          <button className={" p-2 bg-gray-200 rounded"} onClick={() => setUpdateTablePopup(true)}>Update Layout</button>
        }
      </div>
    </>
  );
};

export default DropDrag;

DropDrag.defaultProps = {
  className: "layout",
  rowHeight: 30,
  onLayoutChange: (layout: any, layouts: any) => { },
  cols: 12,
  isDraggable: false
};
