import React from 'react'
import { Route, Routes } from "react-router-dom";
import PosScreenLayouts from "../layouts/PosScreenLayout";
import DashboardNotFound from '../pages/Error/DashboardNotFound';
import PosTypeScreen from '../pages/PosScreen';
import PosScreenHoldCard from '../pages/PosScreen/PosScreenHoldCard/PosScreenHoldCard';
import PosScreenTransactions from '../pages/PosScreen/PosScreenTransactions/PosScreenTransactions';
import PosScreenKitchenOrder from '../pages/PosScreen/PosScreenKitchenOrder/PosScreenKitchenOrder';
import PosScreenOrder from '../pages/PosScreen/PosScreenOrder/PosScreenOrder';
import PosScreenCustomer from '../pages/PosScreen/PosScreenCustomer/PosScreenCustomer';
import PosScreenHoldCardDetail from "../pages/PosScreen/PosScreenHoldCard/PosScreenHoldCartDetail";
import PosScreenOrderDetail from '../pages/PosScreen/PosScreenOrder/PosScreenOrderDetail';


const Router = () => {
  return (
    <Routes>

      <Route
        element={
          <PosScreenLayouts />
        }
      >
        <Route path="/*" element={<DashboardNotFound />} />
        <Route path={"/"} element={<PosTypeScreen />} />
        <Route path={"/pos-screen-hold-card"} element={<PosScreenHoldCard />} />
        <Route
          path={"/pos-screen-transaction"}
          element={<PosScreenTransactions />}
        />
        <Route
          path={"/pos-screen-kitchen-card"}
          element={<PosScreenKitchenOrder />}
        />
        <Route
          path={"/pos-screen-hold-card/detail/:id"}
          element={<PosScreenHoldCardDetail />}
        />
         <Route
          path={"/orders/detail/:id"}
          element={<PosScreenOrderDetail />}
        />
        <Route path={"/pos-screen-order"} element={<PosScreenOrder />} />
        <Route path={"/pos-screen-customers"} element={<PosScreenCustomer />} />

      </Route>
    </Routes>
  )
}

export default Router