import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import ClockInClockOutIcon from "../assets/clockinclockout.svg";
import AccountIcon from "../assets/account.svg";
import DualScreenIcon from "../assets/dualscreen.svg";
import OrderIcon from "../assets/order.svg";
import TransactionIcon from "../assets/transaction.svg";
import CustomerIcon from "../assets/customer.svg";
import RemoteOrderIcon from "../assets/remoteorder.svg";
import CartIcon from "../assets/cart.svg";

import React, { Fragment, useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import { QUERY_SETTINGS } from "../pages/Settings/graphql";
import MiddleNav from "../components/NavigationBar/MiddleNav";
import SearchBar from "../components/NavigationBar/SearchBar";
import { removeLocalKey } from "../helpers/sessionKey";

import isElectron from "../helpers/checkElectron";
import ConfirmAlert from "../components/reusable/ConfirmAlert";

import { toast } from "react-toastify";
import CustomHeaderLayout from "./CustomHeaderLayout";
import {
  CHECK_DATA_SYNC,
  GET_TERMINAL,
  START_DATA_SYNC,
} from "../pages/queries";
import Modal from "../components/reusable/Modal";
import PrimaryButton from "../components/reusable/Buttons/PrimaryButton";
import SelectTerminal from "./SelectTerminal";
import DIcon from "../assets/d.svg";

const PosScreenLayout = () => {


  const navigation = [
    {
      name: "Pos",
      href: "/",
      icon: (
        <img
          alt=""
          src={DualScreenIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Hold Cart",
      href: "/pos-screen-hold-card",
      icon: (
        <img
          alt=""
          src={CartIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Remote Orders",
      href: "/pos-screen-kitchen-card",
      icon: (
        <img
          alt=""
          src={RemoteOrderIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Orders",
      href: "/pos-screen-order",
      icon: (
        <img
          alt=""
          src={OrderIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Transactions",
      href: "/pos-screen-transaction",
      icon: (
        <img
          alt=""
          src={TransactionIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
    {
      name: "Customers",
      href: "/pos-screen-customers",
      icon: (
        <img
          alt=""
          src={CustomerIcon}
          className="m-auto h-5 w-5 text-white"
          aria-hidden="true"
        />
      ),
      current: false,
    },
  ];

  //Receipt
  // Disable websocket
  // //Websocket








  // Disable websockets
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [openClockList, setOpenClockList] = useState(false);

  const { data: basicSetting } = useQuery(QUERY_SETTINGS);
  const navigate = useNavigate();
  const handleDashboardRoute = () => {
    navigate("/");
  };
  const handleLogout = () => {
    removeLocalKey("apollo-cache-persist")
    navigate("/login");
    removeLocalKey("token");
  };
  const location = useLocation();

  const handleClockInOut = async () => {

  };

  //Data Sync
  const [isDataSyncPopupOpen, setIsDataSyncPopupOpen] = useState(false);

  const [dataSyncUpdate] = useMutation(CHECK_DATA_SYNC, {
    fetchPolicy: "network-only",
  });

  const handleStartDataSync = async (values: any) => {


  };


  return (
    <>
      <div
        className={`${isElectron() ? "h-[94vh]" : "h-screen"
          } font-serif text-sm  flex overflow-hidden bg-gray-100`}
      >
        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-[50px] bg-[#4338CA] shadow">
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <Link to={window.location.origin}>
                  <img
                    className="h-6 w-auto m-3  cursor-pointer"
                    src={DIcon}
                    alt={basicSetting?.basicWebsiteConfigurations?.title}
                  />
                </Link>
              </div>
              <div className="flex items-center">
                {location.pathname === "/" && (
                  <div className="relative w-full flex gap-2 md:ml-0">
                    <SearchBar
                      name="searchProduct"
                      placeholder="Search Product"
                    />
                    <SearchBar
                      name="searchBarcode"
                      placeholder="Scan Barcode"
                    />
                    <SearchBar name="searchPlu" placeholder="Enter PLU" />
                    <SelectTerminal />
                  </div>
                )}
              </div>
            </div>
          </div>
          <main className="flex justify-between relative h-full overflow-y-hidden focus:outline-none">
            <div
              className={
                "flex h-full flex-col text-center items-center justify-evenly bg-[#4338CA] text-xs text-white"
              }
            >
              {location.pathname === "/" && (
                <MiddleNav selectedTerminal={""} />
              )}
              {navigation.map((item, index) => (
                <React.Fragment key={index}>
                  <Link to={item.href}>
                    {item?.icon}
                    {item?.name}
                  </Link>
                  <hr className="w-full text-[#921f1f42]" />
                </React.Fragment>
              ))}

              {/* {customerItems?.id &&
                <p className="cursor-pointer" onClick={() => navigate("/Loyality")}>
                  <img className="w-7 h-7 m-auto p-1 hover:bg-indigo-800" src={TableIcon} alt="" />
                  <p>Loyality</p>
                </p>
              } */}
            </div>

            <div className="flex-[92%] py-2 overflow-y-auto">
              <div className="w-[96%] h-full mx-auto px-0 sm:px-0 md:px-2">
                {/* {!clockInOutStatus?.clockInStatus && (
                  <div className="absolute bg-[#0808086b] top-0 bottom-0 left-0 right-0 z-[5]"></div>
                )} */}
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
      <Modal
        open={isDataSyncPopupOpen}
        setOpen={setIsDataSyncPopupOpen}
        children={
          <>
            <form onSubmit={handleStartDataSync}>
              <PrimaryButton text={"Confirm"} loading={false} />
            </form>
          </>
        }
        title={"Please Sync Your Data First??"}
      />
    </>
  );
};

export default PosScreenLayout;
