import React from 'react'
import TextField from '../reusable/TextField'
import PriceField from '../reusable/PriceField'
import MultipleSelectField from '../reusable/MultipleSelectField'
import Spinner from '../reusable/Spinner'
import PrimaryButton from '../reusable/Buttons/PrimaryButton'

const OpenProductForm = (props: any) => {
  const { printerListsDropdown, setValue, getValues, register, errors, isLoadingButton, loadingCreateProduct, handleQuickProduct, handleSubmit } = props
  return (
    <div className="w-full mb-4 p-4 bg-slate-100">
      <div className="grid grid-cols-1 gap-4">
        <TextField register={register} name={"title"} label={"Title"} values={"Misc Product"} />
        <PriceField name={"price"} label={"Price"} setFieldValue={setValue} values={getValues("price")} />
        {/* <MultipleSelectField
          name="printer"
          label={"Select on or more Printer"}
          titleLabel={"Printer"}
          values={getValues("printer")}
          errors={errors}
          setFieldValue={setValue}
          options={printerListsDropdown ?? []}
        /> */}
      </div>
      {isLoadingButton ? <Spinner /> :
        <PrimaryButton text={"Submit"} loading={loadingCreateProduct} disable={loadingCreateProduct} onClick={handleSubmit(handleQuickProduct)} />
      }
    </div>
  )
}

export default OpenProductForm