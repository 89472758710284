import { useReactiveVar } from '@apollo/client';
import React from 'react'
import { cartItemsVar } from '../../../../localstate/Cache';
import TabPanel from '../../../../reusable/TabPanel/TabPanel';
import IndividualSurchargeForm from './IndividualSurchargeForm';
import OverAllSurchargeForm from './OverAllSurchargeForm';

type Props = {
  setSelectSuchargeType?: any;
  open?: any;
  setOpen?: any;
  totalAmtCalculations?: any;
  selectedtitle?: any;
  selectSuchargeType?: any;
}

const SuchargeForm = (props: Props) => {
  const { selectSuchargeType, setSelectSuchargeType, setOpen, totalAmtCalculations } = props;
  const cartItems: any = useReactiveVar(cartItemsVar);

  let cartValue: any = cartItems?.findIndex((cartItem: any, index: number) => cartItem?.isSelected === true);
  return (
    <>
      {cartValue > -1 ? <>
        <div className="absolute top-0 text-white left-[40%] text-lg">{"Surcharge"}</div>

        <TabPanel tabTitle={["Individual Surcharge", "OverAll Surcharge"]} tabPanels={[<>
          <IndividualSurchargeForm
            selectSuchargeType={selectSuchargeType}
            setSelectSuchargeType={setSelectSuchargeType}
            setOpen={setOpen}
            totalAmtCalculations={totalAmtCalculations}
          />
        </>,
        <>
          <OverAllSurchargeForm
            selectSuchargeType={selectSuchargeType}
            setSelectSuchargeType={setSelectSuchargeType}
            setOpen={setOpen}
            totalAmtCalculations={totalAmtCalculations}
          />
        </>
        ]
        } />
      </> : <>
        <div className="absolute top-0 text-white left-[40%] text-lg">{"OverAll Surcharge"}</div>
        <OverAllSurchargeForm
          selectSuchargeType={selectSuchargeType}
          setSelectSuchargeType={setSelectSuchargeType}
          setOpen={setOpen}
          totalAmtCalculations={totalAmtCalculations}
        />
      </>

      }


    </>
  )
}

export default SuchargeForm