import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import Loadable from "react-loadable";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router";
import { POS_SCREEN_ORDERED_ITEMS } from "../queries";

const OrderDetailListComponent: any = Loadable({
  loader: () => import("../../../components/Orders/OrderList/DetailOrderList"),
  loading: DataLoadSpinner,
});
const PosScreenOrderDetail = () => {
  const id = useParams();
  const { loading: isLoading, data: orderItemsData } = useQuery(
    POS_SCREEN_ORDERED_ITEMS,
    {
      variables: id,
    },
  );
  return (
    <div className="w-full">
      <OrderDetailListComponent
        isLoading={isLoading}
        orderItemsData={orderItemsData?.order}
      />
    </div>
  );
};

export default PosScreenOrderDetail;
