import React from 'react'
import TextField from '../../reusable/TextField'
import MultipleSelectField from '../../reusable/MultipleSelectField'
import CheckBoxField from '../../reusable/CheckBoxField'
import DynamicDropDown from '../../reusable/DynamicDropDown'
import PriceField from '../../reusable/PriceField'

const ProductForm = (props: any) => {
  const { register, getValues, errors, setValue, categoryDropdown, unitDropdown, taxTypeDropdown, printerListsDropdown, modifierCategoryDropdown } = props
  console.log(getValues("modifierCategories"), "assas")
  console.log(modifierCategoryDropdown, "modifierCategoryDropdown")
  console.log(categoryDropdown, "categoryDropdown")
  return (
    <div className="w-full flex flex-wrap justify-between mb-4 p-4">
      <div className="w-full flex justify-between gap-10">
        <TextField register={register} name={"title"} label={"Title"} values={getValues("title")} />
        <MultipleSelectField
          name={"category"}
          label={"Select on or more category"}
          titleLabel={"Category"}
          values={getValues("category")}
          errors={errors}
          setFieldValue={setValue}
          options={categoryDropdown}
        />
      </div>
      <div className="w-full flex justify-between gap-10">
        <DynamicDropDown name={"unit"} label={"Unit"} setFieldValue={setValue} values={getValues("unit")} list={unitDropdown} />
        <DynamicDropDown name={"taxType"} label={"Taxable Type"} setFieldValue={setValue} values={getValues("taxType")} list={taxTypeDropdown} />
      </div>
      <div className="w-full flex justify-between mt-4">
        <CheckBoxField register={register} name={"isActive"} label={"Active"} values={getValues("isActive")} />
        <CheckBoxField register={register} name={"isWeightable"} label={"Weightable"} values={getValues("isWeightable")} />
        <CheckBoxField register={register} name={"isOpenPriceProduct"} label={"OpenPriceProduct"} values={getValues("isOpenPriceProduct")} />
        <CheckBoxField register={register} name={"isModifierCategoryGrid"} label={"ModifierCategoryGrid"} values={getValues("isModifierCategoryGrid")} />
      </div>
      <div className="w-full flex justify-between gap-10">
        <PriceField name={"costPrice"} label={"Cost Price"} setFieldValue={setValue} values={getValues("costPrice")} />
        <PriceField name={"sellingPrice"} label={"Selling Price"} setFieldValue={setValue} values={getValues("sellingPrice")} />
      </div>
      <MultipleSelectField
        name="printer"
        label={"Select on or more Printer"}
        titleLabel={"Printer"}
        values={getValues("printer")}
        errors={errors}
        setFieldValue={setValue}
        options={printerListsDropdown}
      />
      <MultipleSelectField
        name={"modifierCategories"}
        titleLabel={"Modifier Category"}
        label={"Select on or more category"}
        values={getValues("modifierCategories")}
        errors={errors}
        setFieldValue={setValue}
        options={modifierCategoryDropdown}
      />
      <div className="w-full flex justify-between gap-10">
        <TextField register={register} name={"barcode"} label={"BARCODE"} />
        <TextField register={register} name={"plu"} label={"PLU"} />
      </div>
    </div>
  )
}

export default ProductForm