import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  cache,
  cartItemsVar,
  searchCache,
  searchItemsVar,
  searchSelectedCache,
  searchSelectedItemsVar,
} from "../localstate/Cache";
import {
  GET_CART_ITEMS,
  GET_SEARCH_ITEMS,
  GET_SEARCH_SELETCED_ITEMS,
} from "../localstate/Cart";
import { toast } from "react-toastify";
import { SEARCH_PRODUCT_ITEMS } from "../../pages/PosScreen/queries";

const SearchBar = (props: any) => {
  const { name, placeholder } = props;
  const [input, setInput] = useState<any>("");
  const searchSelectedItems: any = useReactiveVar(searchSelectedItemsVar);
  const cartItems: any[] = useReactiveVar(cartItemsVar);

  const [getSearchProduct] = useLazyQuery(SEARCH_PRODUCT_ITEMS);


  const onChangeInput = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setInput({ [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const searchList = {
      searchInput: input[name] ?? "",
      name: name ?? "",
    };
    searchCache.writeQuery({
      query: GET_SEARCH_ITEMS,
      data: {
        searchItems: searchItemsVar(searchList),
      },
    });

  }, [input]);

  // Add product automatically on scanning barcode
  const handleScanBarcCode = async (e: any) => {
    e.preventDefault();
    const response = await getSearchProduct({
      variables: ({
        barcode: input?.searchBarcode ?? ""
      })
    })
    const selectedItems: any = response?.data?.products?.edges[0] ?? []
    if (!selectedItems?.node?.isModifier) {
      if (selectedItems?.node?.id) {
        let productInCartList = {
          id: selectedItems?.node?.id,
          pk: selectedItems?.node?.pk,
          contentTypeId: selectedItems?.node?.contentTypeId,
          title: selectedItems?.node?.title,
          quantity: 1,
          price: selectedItems?.node?.finalPrice ?? 0,
          total: selectedItems?.node?.finalPrice ?? 0,
          tax: selectedItems?.node?.taxAmount,
          isSelected: true,
          isDeleted: false,
          modifier: [],
          isModified: true,
        };
        let previousObject: any = cartItems.find(
          (x: any) =>
            x.id === selectedItems?.node?.id && x.isSelected === true,
        );

        if (previousObject && previousObject.isSelected) {
          let newItemList: any = cartItems;

          let foundIndex: any = cartItems.findIndex(
            (x: any) =>
              x.id === selectedItems?.node?.id && x.isSelected === true,
          );

          newItemList[foundIndex].quantity =
            previousObject.quantity >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;

          newItemList[foundIndex].total =
            selectedItems?.node?.finalPrice > 0 ? parseFloat(selectedItems?.node?.finalPrice) +
              parseFloat(previousObject.total) : 0;
          newItemList[foundIndex].tax = parseFloat(selectedItems?.node?.taxAmount) * parseFloat(previousObject.quantity);
          newItemList[foundIndex].isDeleted = false;
          newItemList[foundIndex].isSelected = true;
          newItemList[foundIndex].isModified = true;

          let updateNewItemList: any = newItemList.map(
            (item: any, index: number) =>
              !index === foundIndex
                ? {
                  ...item,
                  isSelected: false,
                }
                : item,
          );
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...updateNewItemList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
          setInput({ ["barcode"]: "" });
        } else {
          let nonPreviousItemList = cartItems?.map(
            (item: any, index: number) => ({
              ...item,
              isSelected: false,
            }),
          );
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([
                ...nonPreviousItemList,
                productInCartList,
              ]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
          setInput({ ["searchBarcode"]: "" });
        }
      }
    } else {
      let selectedItem: any = cartItems?.find(
        (x: any) => x?.isSelected === true,
      );
      let prevModifierList: any = selectedItem?.modifier;
      const previousObject: any = prevModifierList?.find(
        (x: any) => x?.id === selectedItems?.node?.id,
      );
      if (selectedItem) {
        if (previousObject) {
          let productInCartList: any = cartItems;
          let perviousModifierItemList: any = prevModifierList;
          let itemIndex: any = prevModifierList.findIndex(
            (obj: any) => obj.id === selectedItems?.node?.id,
          );
          perviousModifierItemList[itemIndex].quantity =
            parseInt(previousObject.quantity) >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          perviousModifierItemList[itemIndex].total =
            selectedItems?.node?.finalPrice > 0 ? parseFloat(selectedItems?.node?.finalPrice) +
              parseFloat(previousObject.total) : 0;
          perviousModifierItemList[itemIndex].tax = parseFloat(selectedItems?.node?.taxAmount) * parseFloat(previousObject.quantity);

          perviousModifierItemList[itemIndex].isDeleted = false;

          selectedItem.modifier = perviousModifierItemList;
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true,
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
          setInput({ ["searchBarcode"]: "" });

        } else {
          let productInCartList: any = cartItems;
          selectedItems?.node &&
            prevModifierList.push({
              id: selectedItems?.node?.id,
              pk: selectedItems?.node?.pk,
              contentTypeId: selectedItems?.node?.contentTypeId,
              title: selectedItems?.node?.title,
              price: selectedItems?.node?.finalPrice,
              quantity: 1,
              total: selectedItems?.node?.finalPrice,
              tax: selectedItems?.node?.taxAmount,
              isDeleted: false,
              randId: Math.random().toString(36).substr(2, 5),
            });
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true,
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          const searchList = {
            searchInput: "",
            name: "",
          };
          searchCache.writeQuery({
            query: GET_SEARCH_ITEMS,
            data: {
              searchItems: searchItemsVar(searchList),
            },
          });
          setInput({ ["searchBarcode"]: "" });
        }
      } else {
        if (selectedItems?.node) {
          toast.info("Please select product first");
        }
      }
    }
  }

  //Add product with keyboard left right top bottom movement and add to list on enter event
  const handleKeyDown = (e: any) => {
    switch (e.key) {
      case "Enter":
        e.preventDefault();
        const selectedItems: any =
          searchSelectedItems?.data?.products?.edges?.find(
            (item: any, index: number) => index === searchSelectedItems?.index,
          );
        if (!selectedItems?.node?.isModifier) {
          if (selectedItems?.node?.id) {
            let productInCartList = {
              id: selectedItems?.node?.id,
              pk: selectedItems?.node?.pk,
              contentTypeId: selectedItems?.node?.contentTypeId,
              title: selectedItems?.node?.title,
              quantity: 1,
              price: selectedItems?.node?.finalPrice ?? 0,
              total: selectedItems?.node?.finalPrice ?? 0,
              tax: selectedItems?.node?.taxAmount,
              isSelected: true,
              isDeleted: false,
              modifier: [],
              isModified: true,
            };
            let previousObject: any = cartItems.find(
              (x: any) =>
                x.id === selectedItems?.node?.id && x.isSelected === true,
            );

            if (previousObject && previousObject.isSelected) {
              let newItemList: any = cartItems;

              let foundIndex: any = cartItems.findIndex(
                (x: any) =>
                  x.id === selectedItems?.node?.id && x.isSelected === true,
              );

              newItemList[foundIndex].quantity =
                previousObject.quantity >= 0
                  ? parseInt(previousObject.quantity) + 1
                  : 1;

              newItemList[foundIndex].total =
                selectedItems?.node?.finalPrice > 0 ? parseFloat(selectedItems?.node?.finalPrice) +
                  parseFloat(previousObject.total) : 0;
              newItemList[foundIndex].tax = parseFloat(selectedItems?.node?.taxAmount) * parseFloat(previousObject.quantity);
              newItemList[foundIndex].isDeleted = false;
              newItemList[foundIndex].isSelected = true;
              newItemList[foundIndex].isModified = true;

              let updateNewItemList: any = newItemList.map(
                (item: any, index: number) =>
                  !index === foundIndex
                    ? {
                      ...item,
                      isSelected: false,
                    }
                    : item,
              );
              cache.writeQuery({
                query: GET_CART_ITEMS,
                data: {
                  cartItems: cartItemsVar([...updateNewItemList]),
                },
              });
              const searchList = {
                searchInput: "",
                name: "",
              };
              searchCache.writeQuery({
                query: GET_SEARCH_ITEMS,
                data: {
                  searchItems: searchItemsVar(searchList),
                },
              });
              setInput({ [e.target.name]: "" });
            } else {
              let nonPreviousItemList = cartItems?.map(
                (item: any, index: number) => ({
                  ...item,
                  isSelected: false,
                }),
              );
              cache.writeQuery({
                query: GET_CART_ITEMS,
                data: {
                  cartItems: cartItemsVar([
                    ...nonPreviousItemList,
                    productInCartList,
                  ]),
                },
              });
              const searchList = {
                searchInput: "",
                name: "",
              };
              searchCache.writeQuery({
                query: GET_SEARCH_ITEMS,
                data: {
                  searchItems: searchItemsVar(searchList),
                },
              });
              setInput({ [e.target.name]: "" });
            }
          }
        } else {
          let selectedItem: any = cartItems?.find(
            (x: any) => x?.isSelected === true,
          );
          let prevModifierList: any = selectedItem?.modifier;
          const previousObject: any = prevModifierList?.find(
            (x: any) => x?.id === selectedItems?.node?.id,
          );
          if (selectedItem) {
            if (previousObject) {
              let productInCartList: any = cartItems;
              let perviousModifierItemList: any = prevModifierList;
              let itemIndex: any = prevModifierList.findIndex(
                (obj: any) => obj.id === selectedItems?.node?.id,
              );
              perviousModifierItemList[itemIndex].quantity =
                parseInt(previousObject.quantity) >= 0
                  ? parseInt(previousObject.quantity) + 1
                  : 1;
              perviousModifierItemList[itemIndex].total =
                selectedItems?.node?.finalPrice > 0 ? parseFloat(selectedItems?.node?.finalPrice) +
                  parseFloat(previousObject.total) : 0;
              perviousModifierItemList[itemIndex].tax = parseFloat(selectedItems?.node?.taxAmount) * parseFloat(previousObject.quantity);

              perviousModifierItemList[itemIndex].isDeleted = false;

              selectedItem.modifier = perviousModifierItemList;
              let selectedItemIndex: any = cartItems.findIndex(
                (x: any) => x.isSelected === true,
              );
              productInCartList[selectedItemIndex].isModified = true;
              productInCartList[selectedItemIndex] = selectedItem;
              cache.writeQuery({
                query: GET_CART_ITEMS,
                data: {
                  cartItems: cartItemsVar([...productInCartList]),
                },
              });
              const searchList = {
                searchInput: "",
                name: "",
              };
              searchCache.writeQuery({
                query: GET_SEARCH_ITEMS,
                data: {
                  searchItems: searchItemsVar(searchList),
                },
              });
              setInput({ [e.target.name]: "" });

            } else {
              let productInCartList: any = cartItems;
              selectedItems?.node &&
                prevModifierList.push({
                  id: selectedItems?.node?.id,
                  pk: selectedItems?.node?.pk,
                  contentTypeId: selectedItems?.node?.contentTypeId,
                  title: selectedItems?.node?.title,
                  price: selectedItems?.node?.finalPrice,
                  quantity: 1,
                  total: selectedItems?.node?.finalPrice,
                  tax: selectedItems?.node?.taxAmount,
                  isDeleted: false,
                  randId: Math.random().toString(36).substr(2, 5),
                });
              let selectedItemIndex: any = cartItems.findIndex(
                (x: any) => x.isSelected === true,
              );
              productInCartList[selectedItemIndex].isModified = true;
              productInCartList[selectedItemIndex] = selectedItem;
              cache.writeQuery({
                query: GET_CART_ITEMS,
                data: {
                  cartItems: cartItemsVar([...productInCartList]),
                },
              });
              const searchList = {
                searchInput: "",
                name: "",
              };
              searchCache.writeQuery({
                query: GET_SEARCH_ITEMS,
                data: {
                  searchItems: searchItemsVar(searchList),
                },
              });
              setInput({ [e.target.name]: "" });
            }
          } else {
            if (selectedItems?.node) {
              toast.info("Please select product first");
            }
          }
        }

        // code block
        break;
      case "ArrowRight":
        e.preventDefault();

        if (
          searchSelectedItems?.data?.products?.edges?.length >
          searchSelectedItems?.index + 1
        ) {
          const searchData: any = {
            index: searchSelectedItems?.index + 1,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        } else {
          const searchData: any = {
            index: 0,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        }

        // code block
        break;
      case "ArrowLeft":
        // code block
        e.preventDefault();

        if (
          searchSelectedItems?.index > 0 &&
          searchSelectedItems?.data?.products?.edges?.length >
          searchSelectedItems?.index - 1
        ) {
          const searchData: any = {
            index: searchSelectedItems?.index - 1,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        } else {
          const searchData: any = {
            index: searchSelectedItems?.data?.products?.edges?.length - 1,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        }

        break;
      case "ArrowDown":
        // code block
        e.preventDefault();

        if (
          searchSelectedItems?.data?.products?.edges?.length - 7 >
          searchSelectedItems?.index
        ) {
          const searchData: any = {
            index: searchSelectedItems?.index + 7,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        } else {
          const searchData: any = {
            index: searchSelectedItems?.index,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        }
        break;
      case "ArrowUp":
        // code block
        e.preventDefault();
        if (
          searchSelectedItems?.index > 6 &&
          searchSelectedItems?.data?.products?.edges?.length >
          searchSelectedItems?.index
        ) {
          const searchData: any = {
            index: searchSelectedItems?.index - 7,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        } else {
          const searchData: any = {
            index: searchSelectedItems?.index,
            data: searchSelectedItems?.data,
          };
          searchSelectedCache.writeQuery({
            query: GET_SEARCH_SELETCED_ITEMS,
            data: {
              searchSelectedItems: searchSelectedItemsVar(searchData),
            },
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      {name === "searchBarcode" ?
        <form
          className=" w-full mr-2"
          onSubmit={handleScanBarcCode}
        >
          <input
            id={name}
            className="block w-full h-[32px] bg-[#FFFFFF2D] px-4 py-2 m-1 text-center rounded-[20px] border-transparent text-white placeholder-white focus:outline-none focus:placeholder-white focus:ring-0 focus:border-transparent sm:text-sm"
            placeholder={placeholder}
            type="search"
            name={name}
            value={input[name] || ""}
            onChange={(e) => onChangeInput(e)}
            autoComplete={"off"}
          />
        </form>
        :
        <input
          id={name}
          className="block w-full h-[32px] bg-[#FFFFFF2D] px-4 py-2 m-1 text-center rounded-[20px] border-transparent text-white placeholder-white focus:outline-none focus:placeholder-white focus:ring-0 focus:border-transparent sm:text-sm"
          placeholder={placeholder}
          type="search"
          name={name}
          value={input[name] || ""}
          onChange={(e) => onChangeInput(e)}
          autoComplete={"off"}
          onKeyDown={handleKeyDown}
        />
      }
    </>
  );
};

export default SearchBar;
