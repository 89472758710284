import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import Loadable from "react-loadable";
import { toast } from "react-toastify";
import {
  customerCache,
  CustomerItemsVar,
} from "../../../components/localstate/Cache";
import { GET_CUSTOMER_ITEMS } from "../../../components/localstate/Cart";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../../customhooks/usePaginationHook";

import {

  ADD_EXPENSES,
  MAKE_SUPPLIER_PAYMENT,
} from "../../mutations";
import {
  QUERY_SUPPLIER,
  QUERY_TRANSACTIONS_VIEW,
  QUERY_TRANSACTION_ACCOUNTS,
} from "../../queries";
import { QUERY_SETTINGS } from "../../Settings/graphql";
import AccessControl from "../../../helpers/accessControl";
// import { GET_CUSTOMER_CREDIT_AMOUNT } from "../Customer/grapql";
const TransactionsComponent: any = Loadable({
  loader: () =>
    import("../../../components/PosScreenTransaction/PosScreenTransaction"),
  loading: DataLoadSpinner,
});
const Transactions = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const [
    transactionStartDate,
    setTransactionStartDate,
  ] = useState(null);
  const [transactionEndDate,
    setTransactionEndDate,] = useState(null)
  const [selecteFilter, setSelecteFilter] = useState<any>("");
  const customerItems: any = useReactiveVar(CustomerItemsVar);
  const [selected, setSelected] = useState({
    id: "",
    pk: "",
    name: "",
  });



  // Used for customer cache


  const { data } = useQuery(QUERY_TRANSACTIONS_VIEW, {
    variables: {
      first: pageSize,
      offset: offset,
      createdGte: transactionStartDate ? moment(transactionStartDate).format('yyyy-MM-DDThh:mm:ss') : null,
      createdLte: transactionEndDate ? moment(transactionEndDate).format('yyyy-MM-DDThh:mm:ss') : null,
      account: selecteFilter ?? "",
      account_IsVisible: true,
    }
  });

  const { data: transactionAccountsData } = useQuery(
    QUERY_TRANSACTION_ACCOUNTS,
    {
      variables: {
        isVisible: true
      },
    }

  );

  // FOR TRANSACTION ACCOUNT DROP DOWN
  const transactionAccountsList: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) => {
    return transactionAccountsList.push({
      id: elem?.node?.pk,
      title: elem?.node?.name,
    });
  });

  //  FOR TRANSACTION ACCOUNT FILTER
  const transactionAccountsFilter: any = [];
  transactionAccountsData?.transactionAccounts?.edges?.map((elem: any) => {
    return transactionAccountsFilter.push({
      value: elem?.node?.id,
      label: elem?.node?.name,
    });
  });

  const [expenses, setExpenses] = useState<boolean>(false);
  const [openAcceptPayment, setOpenAcceptPayment] = useState<boolean>(false);
  const [makePayment, setMakePayment] = useState<boolean>(false);

  const { data: supplierData } = useQuery(QUERY_SUPPLIER, {
    variables: {
      first: 100,
    },
  });

  // SUPPLIER DROPDOWN FORMAT
  const supplierList: any = [];
  supplierData?.suppliers?.edges?.map((elem: any) => {
    return supplierList.push({
      id: elem?.node?.user?.pk,
      title: elem?.node?.user?.profile?.firstName,
    });
  });



  // CUSTOMER DROPDOWN FORMAT
  const customerList: any = [];

  const [makeSupplierPayment] = useMutation(MAKE_SUPPLIER_PAYMENT, {
    refetchQueries: ["transactions"],
  });

  const [addExpenses] = useMutation(ADD_EXPENSES, {
    refetchQueries: ["transactions"],
  });



  const handleAddExpenses = async (values: any, props: any) => {
    const response = await addExpenses({
      variables: {
        amount: values.amount,
        note: values.note,
        transactionSource: "pos",
      },
    });
    if (response?.data?.addExpense?.success) {
      setExpenses(false);
      toast.success(response?.data?.addExpense?.message);
    } else {

    }
  };



  const handlePayment = async (values: any, props: any) => {
    const response = await makeSupplierPayment({
      variables: {
        user: values.user,
        account: values.transactionAccounts,
        amount: values.amount,
        note: values.note,
      },
    });
    if (response?.data?.makePayment?.success) {
      setMakePayment(false);
      toast.success(response?.data?.makePayment?.message);
    } else {

    }
  };
  const { data: basicSettings } = useQuery(QUERY_SETTINGS);

  return (

    <div>
      <TransactionsComponent
        data={
          data?.transactions
        }
        setExpenses={setExpenses}
        expenses={expenses}
        openAcceptPayment={openAcceptPayment}
        setOpenAcceptPayment={setOpenAcceptPayment}
        setMakePayment={setMakePayment}
        makePayment={makePayment}
        handleAddExpenses={handleAddExpenses}
        handlePayment={handlePayment}
        supplierList={supplierList}
        customerList={customerList}
        setPageSize={setPageSize}
        pageSize={pageSize}
        setOffset={setOffset}
        offset={offset}
        transactionAccountsList={transactionAccountsList}
        transactionAccountsFilter={transactionAccountsFilter}
        selecteFilter={selecteFilter}
        setSelecteFilter={setSelecteFilter}
        transactionStartDate={transactionStartDate}
        setTransactionStartDate={setTransactionStartDate}
        transactionEndDate={transactionEndDate}
        setTransactionEndDate={setTransactionEndDate}
        customerItems={customerItems}
        selected={selected}
        setSelected={setSelected}
        selectedCustomer={""}
        calenderType={basicSettings?.basicWebsiteConfigurations?.calendarType}
      />
    </div>

  );
};

export default Transactions;
