import { useEffect, useState } from "react";

import Modal from "../reusable/Modal";
import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import {
  cartItemsVar,
  kitchenOrderItemId,
  openTableCache,
  openTableVar,
  orderTypeCache,
  orderTypeVar,
  tableCache,
  tableItemsVar,
} from "../localstate/Cache";
import { GET_ORDER_TYPE, GET_TABLE_ITEMS } from "../localstate/Cart";

import {
  CREATE_SUB_TABLE,
  DISABLE_SUB_TABLE,
  MEREGE_RESTAURANT_TABLE,
  SPLIT_RESTAURANT_TABLE,
  SWAP_RESTAURANT_TABLE,
} from "../../pages/mutations";
import { toast } from "react-toastify";
import TableIcon from "../../assets/table.svg";
import OrderType from "../../assets/orderType.svg";
import {
  DELIVER_METHODS,
  GET_RESTAURANT_FLOOR,
  QUERY_RESTAURANT_FLOOR,
} from "../../pages/queries";
import { useNavigate } from "react-router";
import { getLocalKey, setLocalKey } from "../../helpers/sessionKey";
import { useForm } from "react-hook-form";
import PosScreenTableList from "../PosScreenType/PosScreenTableList/PosScreenTableList";

type Props = {
  selectedTerminal: any;
};
const MiddleNav = (props: Props) => {
  const { selectedTerminal } = props;
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm({});
  const [openMergeTable, setOpenMergeTable] = useState<boolean>(false);
  const [openSwapTable, setOpenSwapTable] = useState<boolean>(false);
  const [openSplitTable, setOpenSplitTable] = useState<boolean>(false);
  const [openSplitSubTableList, setOpenSplitSubTableList] =
    useState<boolean>(false);

  //Floor
  const { data, loading: isFloorLoading } = useQuery(QUERY_RESTAURANT_FLOOR);
  const [floorId, setFloorId] = useState<string>(
    data?.floors?.edges[0]?.node?.id,
  );
  const [floorPk, setFloorPk] = useState<string>(
    data?.floors?.edges[0]?.node?.pk,
  );
  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  useEffect(() => {
    setFloorId(data?.floors?.edges[0]?.node?.id);
    setFloorPk(data?.floors?.edges[0]?.node?.pk);
  }, [data?.floors?.edges]);

  const [quantity, setQuantity] = useState<any>();

  const [tableId, setTableId] = useState<string>("");
  const [selectedTableArray, setSelectedTableArray] = useState<any>([]);
  const [selectedSwapTable, setSelectedSwapTable] = useState<string>("");

  const [openOrderTypeList, setOpenOrderTypeList] = useState<boolean>(false);

  const [kitchenPrintAlertPopup, setKitchenPrintAlertPopup] = useState(false);

  const [mergeTable] = useMutation(MEREGE_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [createSubTable] = useMutation(CREATE_SUB_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [disableSubTable] = useMutation(DISABLE_SUB_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [unmergeTable] = useMutation(SPLIT_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });

  const [swapTable] = useMutation(SWAP_RESTAURANT_TABLE, {
    refetchQueries: [
      {
        query: GET_RESTAURANT_FLOOR,
        variables: {
          id: floorId,
        },
      },
    ],
  });
  const cartItems: any[] = useReactiveVar(cartItemsVar);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const openTable: any = useReactiveVar(openTableVar);
  const openPopupKitchenPrintDisable: any =
    getLocalKey(`openPopupKitchenPrintDisable`) || false;

  const handleClearOrderType = () => {
    orderTypeCache.writeQuery({
      query: GET_ORDER_TYPE,
      data: {
        orderType: orderTypeVar({}),
      },
    });
  };
  let modItems: any = cartItems?.filter(
    (item: any) => item?.isModified === true,
  );
  const handleOpenTable = () => {
    if (
      modItems?.length &&
      selectedTerminal?.terminal?.terminalsetting?.compulsoryKitchenPrint ===
      true
    ) {
      setKitchenPrintAlertPopup(true);
    } else {
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(!openTable),
        },
      });
    }
  };
  const handleKitchenClear = (e: any) => {
    e.stopPropagation();
    setKitchenPrintAlertPopup(false);
  };

  const handleClearTable = () => {
    tableCache.writeQuery({
      query: GET_TABLE_ITEMS,
      data: {
        cartItems: tableItemsVar({}),
      },
    });
  };
 //Deliver Methods
 const { data: deliveryMethods } = useQuery(DELIVER_METHODS, {
  variables: {
    first: 98,
    offset: 0,
    title_Icontains: "",
  },
});
  const handleMergeTable = async () => {
    const response = await mergeTable({
      variables: {
        toBeMergeTableId: selectedTableArray,
      },
    });
    if (response.data?.mergeRestaurantTable?.success) {
      toast("Tables Merged Successfully");
      setSelectedTableArray([]);
      setOpenMergeTable(false);
    } else {
    }
  };

  const handleSwapTable = async () => {
    const response = await swapTable({
      variables: {
        fromTable: tableId,
        toTable: selectedSwapTable,
      },
    });
    if (response.data?.swapRestaurantTable?.success) {
      toast("Tables Swapped Successfully");
      setOpenSwapTable(false);
    } else {
    }
  };

  const handleSplitTable = async (tableId: any) => {
    const response = await unmergeTable({
      variables: {
        toBeSplitTableId: tableId,
      },
    });

    if (response.data?.splitRestaurantTable?.success) {
      toast("Tables Splited Successfully");
    } else if (response.data?.splitRestaurantTable?.errors) {
    } else {
      toast.info(response.data?.splitRestaurantTable?.messages);
    }
  };

  const handleSplitSubTable = async (tableId: any, quantity: any) => {
    const response = await createSubTable({
      variables: {
        id: tableId,
        quantity: parseInt(quantity),
      },
    });

    if (response.data?.createSubTable?.success) {
      toast("Tables Splited in Sub Table Successfully");
      setQuantity("");
      setOpenSplitTable(false);
    } else if (response.data?.createSubTable?.errors) {
    } else {
      toast.info(response.data?.createSubTable?.messages);
    }
  };
  const handleSplitDisableSubTable = async (tableId: any) => {
    const response = await disableSubTable({
      variables: {
        id: tableId,
      },
    });

    if (response.data?.disableSubTable?.success) {
      toast("Tables Splited in Sub Table Disabled Successfully");
    } else {
    }
  };

  //Deliver Methods


  const [customerPopup, setCustomerPopup] = useState<any>(false);

  const handleSelectOrderType = (item: any) => {
    setLocalKey("disableRemotePrint", "false");

    if (item?.node?.isRedirectToCustomers) {
      navigate("/pos-screen-customers", {
        state: "fromOrderTypeMustSelectCustomerCondition",
      });
    }

    const ordeTypeList = {
      pk: item?.node?.pk,
      title: item?.node?.title,
    };
    orderTypeCache.writeQuery({
      query: GET_ORDER_TYPE,
      data: {
        orderType: orderTypeVar(ordeTypeList),
      },
    });
    setOpenOrderTypeList(false);
  };
  const handleClick = (value: any) => {
  }
  return (
    <>
      <>
        <div className="relative my-[-20px] py-0">
          {tableItems?.title && (
            <>
              <span className="flex w-[min-content] p-1 bg-green-600 text-center text-white text-[10px]">
                {tableItems.title}
              </span>
              <span
                onClick={handleClearTable}
                className="flex absolute w-[20px] h-[20px] text-white text-center items-center justify-center top-[-12px] right-[-8px] bg-red-600 p-2 rounded-full cursor-pointer"
              >
                x
              </span>
            </>
          )}
          <button onClick={() => handleOpenTable()}>
            <img
              className="w-7 h-7 m-auto p-1 hover:bg-indigo-800"
              src={TableIcon}
              alt=""
            />
            <p>Tables</p>
          </button>
        </div>

        <hr className="w-full text-[#921f1f42]" />

        <div className="relative my-[-20px] py-0">
          {orderType?.title && (
            <>
              <span className="flex w-[min-content] p-1 bg-green-600 text-center text-white text-[10px]">
                {orderType.title}
              </span>
              <span
                onClick={handleClearOrderType}
                className="flex absolute w-[20px] h-[20px] text-white text-center items-center justify-center top-[-12px] right-[-8px] bg-red-600 p-2 rounded-full cursor-pointer"
              >
                x
              </span>
            </>
          )}
          <button onClick={() => setOpenOrderTypeList(true)}>
            <img
              className="w-7 h-7 m-auto p-1 hover:bg-indigo-800"
              src={OrderType}
              alt=""
            />
            <p>Order Type</p>
          </button>
        </div>

        <hr className="w-full text-[#921f1f42]" />
      </>
      <Modal
        open={openTable}
        setOpen={handleOpenTable}
        title={"Table List"}
        size={"max-w-7xl"}
        children={
          <PosScreenTableList
            setOpenTableList={handleOpenTable}
            openSplitTable={openSplitTable}
            setOpenSplitTable={setOpenSplitTable}
            openMergeTable={openMergeTable}
            setOpenMergeTable={setOpenMergeTable}
            selectedSwapTable={selectedSwapTable}
            setSelectedSwapTable={setSelectedSwapTable}
            openSwapTable={openSwapTable}
            setOpenSwapTable={setOpenSwapTable}
            selectedTableArray={selectedTableArray}
            setSelectedTableArray={setSelectedTableArray}
            setTableId={setTableId}
            tableId={tableId}
            handleMergeTable={handleMergeTable}
            handleSwapTable={handleSwapTable}
            handleSplitTable={handleSplitTable}
            handleSplitSubTable={handleSplitSubTable}
            quantity={quantity}
            setQuantity={setQuantity}
            handleSplitDisableSubTable={handleSplitDisableSubTable}
            openSplitSubTableList={openSplitSubTableList}
            setOpenSplitSubTableList={setOpenSplitSubTableList}
            floorId={floorId}
            setFloorId={setFloorId}
            setFloorPk={setFloorPk}
            floorPk={floorPk}
            isFloorLoading={isFloorLoading}
            data={data}
          />
        }
      />

      <Modal
        open={openOrderTypeList}
        setOpen={setOpenOrderTypeList}
        title={"Order Type List"}
        size={"max-w-7xl"}
        children={
          <div className="w-full p-0 m-0">
            <div className="grid grid-rows-auto grid-cols-4 gap-2 p-2">
              {deliveryMethods?.deliveryMethods?.edges?.map(
                (item: any, index: number) => (
                  <div
                    key={item?.node?.id}
                    className={`flex text-center min-h-full max-h-[60px] cursor-pointer rounded-lg items-center bg-[#efefef]`}
                  >
                    <div
                      className="flex py-3 w-full justify-center"
                      onClick={() => handleSelectOrderType(item)}
                    >
                      <p className="">
                        {item?.node?.title}
                        <br />
                      </p>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        }
      />

      <Modal
        open={customerPopup}
        setOpen={setCustomerPopup}
        title={"Select Customer"}
        size={"max-w-7xl"}
        children={"Customer Section"}
      />
      <Modal
        title={"Kitchen Print Alert !!"}
        open={kitchenPrintAlertPopup}
        setOpen={setKitchenPrintAlertPopup}
        children={
          <>
            <p className="p-3">Do You Want to Clear??</p>
            <button
              className="bg-primary text-white w-20 h-10 rounded mt-2"
              onClick={(e: any) => handleKitchenClear(e)}
            >
              Clear
            </button>
          </>
        }
      />
    </>
  );
};
export default MiddleNav;
