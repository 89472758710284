import React from 'react'
import randomString from "../../../utils/randomString";

type Props = {
  name: string
  label?: string
  values: any
  touched: any
  errors: any
  register: any;
  onChange?: any
};

const CheckBoxField: React.FC<any> = (props: Props) => {
  const { name, label, values, register } = props
  const id = randomString(10, "-text-field")
  return (
    <div className="w-full relative rounded flex items-center mb-4">
      <input
        id={id}
        type="checkbox"
        name={name}
        values={values}
        className='w-6 h-4'
        placeholder=" "
        {...register(name)}
      />
      <label
        htmlFor={id}
        className="text-gray-900 text-base"
      >
        {label}
      </label>
    </div>
  );
}

export default CheckBoxField
