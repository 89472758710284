import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";
import React, { useEffect, useState } from "react";
import { cache, cartItemsVar, orderTypeVar, tableItemsVar } from "../../localstate/Cache";
import { GET_CART_ITEMS } from "../../localstate/Cart";

import Modal from "../../reusable/Modal";
import { toast } from "react-toastify";
import PosScreenProWithModCat from "../PosScreenProWithModCat";
import ChangeModalForm from "./ChangeModalForm";

import PrimaryButton from "../../reusable/Buttons/PrimaryButton";

import {
  POS_SCREEN_PRODUCT,
  POS_SCREEN_SINGLE_PRODUCT,
} from "../../../pages/Product/queries";

import { useNavigate } from "react-router";
import ColorPicker from "../../reusable/ColorPicker";
import PosScreenProWithModCatTrue from "../PosScreenProWthModCatTrue";
import { getLocalKey, setLocalKey } from "../../../helpers/sessionKey";
import { QUERY_SETTINGS } from "../../../pages/Settings/graphql";
import WeightableProductQuantityForm from "./WeightableProductQuantityForm";
import OpenPriceProductPriceForm from "./OpenPriceProductPriceForm";
import ProductCard from "./ProductCard";
import {
  POS_PRODUCT_SOLD_OUT,
  POS_SCREEN_CREATE_PRODUCT,
  POS_SCREEN_UPDATE_PRODUCT,
} from "../../../pages/Product/mutation";
import {
  UPDATE_POS_SCREEN_PRODUCT,
  REMOVE_ASSIGNED_PRODUCT,
} from "../../../pages/PosScreen/mutation";
import {
  CURRENT_TERMINAL,
  usePosScreenProductByCategory,
  usePosScreenProductByCategoryWithIds,
} from "../../../pages/PosScreen/queries";
import { useForm } from "react-hook-form";
import ProductForm from "./ProductForm";
import ModifierForm from "./ModifierForm";

export default function PosScreenProductTab(props: any) {
  const {
    productsData,
    productByCatId,
    categoryDropdown,
    unitDropdown,
    taxTypeDropdown,
    printerListsDropdown,
    noOfColumns,
    setIsModifier,
    modifierCategoryDropdown,
    setOpenContextPopup,
    openContextPopup,
    handleProductRefetch,
  } = props;
  const { data: settingsData } = useQuery(QUERY_SETTINGS);
  const [productWithModCategoryList, setProductWithModCategoryList] = useState(
    [],
  );
  const { data: currentTerminalData } = useQuery(CURRENT_TERMINAL);
  const enableWightScale = currentTerminalData?.currentTerminal?.terminal?.terminalsetting?.isActiveScale
  const cartItems: [] = useReactiveVar(cartItemsVar);
  const navigate = useNavigate();
  const [coords, setCoords] = useState<any>({ x: 0, y: 0 });
  const [openProductWithModCategory, setOpenProductWithModCategory] =
    useState<boolean>(false);
  const [openProductWithModCategoryTrue, setOpenProductWithModCategoryTrue] =
    useState<boolean>(false);
  const [isWeightableProduct, setIsWeightableProduct] =
    useState<boolean>(false);
  const [item, setItem] = useState<any>();
  const [itemId, setItemId] = useState<string>("");
  const [scaleValues, setScaleValues] = useState();
  const [promoProducts, setPromProducts] = useState<any>([]);
  const [promotionInfo, setPromotionInfo] = useState<any>([]);
  const [productSingleValue, setProductSingleValue] = useState<any>();
  const { data: singleProductData } = useQuery(POS_SCREEN_SINGLE_PRODUCT, {
    variables: {
      id: itemId ?? "",
    },
  });
  console.log(singleProductData, "singleProductData")
  const [isOpenPriceProducts, setIsOpenPriceProducts] =
    useState<boolean>(false);
  const handleClick = (item: any) => {
    item?.promotionInfo?.length > 0 &&
      item?.promotionInfo?.map((promoItem: any, promoIndex: number) => {
        const nestedTitle = promoItem?.compound.map(
          (nestedItem: any, nestedIndex: number) => nestedItem?.title,
        );
        setPromProducts(nestedTitle);
        setPromotionInfo(promoItem);
      });
    if (item?.isWeightable) {
      if (!enableWightScale) {
        setItem(item);
        setIsWeightableProduct(true);
      } else {
        setItem(item);
      }
      setLocalKey("disableRemotePrint", "false");
    } else if (item?.isOpenPriceProduct) {
      setIsOpenPriceProducts(true);
      setItem(item);
    } else {
      let productInCartList = {
        id: item?.id,
        pk: item?.pk,
        title: item?.title,
        quantity: 1,
        price: item?.finalPrice ?? 0,
        total: item?.finalPrice ?? 0,
        tax: item?.taxAmount,
        taxRate: {
          rate: item?.tax?.rate ?? 0,
          isIncluded: item?.tax?.isIncluded,
        },
        isSelected: true,
        isDeleted: false,
        modifier: [],
        isModified: true,
        promoInfo: item?.promotionInfo ?? [],
        discount: "",
        modifierCategory: item?.modifierCategory,
        productId: productSingle,
      };
      let previousObject: any = cartItems.find(
        (x: any) => x.id === item?.id && x.isSelected === true,
      );

      if (previousObject && previousObject.isSelected) {
        let newItemList: any = cartItems;
        let foundIndex: any = cartItems.findIndex(
          (x: any) => x.id === item?.id && x.isSelected === true,
        );
        newItemList[foundIndex].quantity =
          previousObject.quantity >= 0
            ? parseInt(previousObject.quantity) + 1
            : 1;
        newItemList[foundIndex].tax =
          parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

        newItemList[foundIndex].total =
          item?.finalPrice > 0
            ? parseFloat(item?.finalPrice) * parseFloat(previousObject.quantity)
            : 0;

        newItemList[foundIndex].isDeleted = false;
        newItemList[foundIndex].isSelected = true;
        newItemList[foundIndex].isModified = true;

        let updateNewItemList: any = newItemList.map(
          (item: any, index: number) =>
            !index === foundIndex
              ? {
                ...item,
                isSelected: false,
              }
              : item,
        );
        setLocalKey("disableRemotePrint", "false");
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([...updateNewItemList]),
          },
        });
      } else {
        let nonPreviousItemList = cartItems?.map(
          (item: any, index: number) => ({
            ...item,
            isSelected: false,
          }),
        );
        setLocalKey("disableRemotePrint", "false");
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([
              ...nonPreviousItemList,
              productInCartList,
            ]),
          },
        });
      }
    }
  };

  let cartItemLength: any = cartItems.filter(
    (item: any) => item?.quantity === 1,
  );
  let cartItemLength1: any = cartItems.filter(
    (item: any) => item?.quantity > 1,
  );

  const obj: any = cartItemLength.reduce((acc: any, cur: any, index: any) => {
    acc[cur.id] = { index: cur };
    return acc;
  }, {});

  const output = Object.values(obj)
    .sort((a: any, b: any) => a.index - b.index)
    .map(({ index: val }: any) => val);

  var unique = cartItemLength.filter(
    (v: any, i: any, a: any) =>
      a.findIndex(
        (v2: any) =>
          v2?.modifier?.length < 1 &&
          v2.id === v.id &&
          v2.quantity === v.quantity,
      ) !== i,
  );

  const handlePromoItem = () => {
    let keys: any = cartItemLength.map(
      (el: any) => el?.modifier?.length < 1 && el.title,
    );
    if (
      promoProducts?.length &&
      promoProducts.every((item: any) => keys.includes(item))
    ) {
      let productInCartList = {
        id: singleProductData?.product?.id,
        pk: singleProductData?.product?.pk,
        title: singleProductData?.product?.title,
        quantity: 1,
        price: singleProductData?.product?.finalPrice,
        total: singleProductData?.product?.finalPrice,
        tax: singleProductData?.product?.tax ?? "0.00",
        taxRate: {
          rate: singleProductData?.product?.tax?.rate ?? 0,
          isIncluded: singleProductData?.product?.tax?.isIncluded,
        },
        isSelected: false,
        isDeleted: false,
        modifier: [],
        isModified: true,
        modifierCategory: item?.modifierCategory,
      };

      const itemsThatMAtchPromoCodes: any = output.filter(
        (cartItem: any) =>
          !cartItem?.modifier?.length &&
          !promoProducts.includes(cartItem?.title),
      );

      singleProductData?.product?.id &&
        cache.writeQuery({
          query: GET_CART_ITEMS,
          data: {
            cartItems: cartItemsVar([
              ...cartItemLength1,
              ...itemsThatMAtchPromoCodes,
              ...unique,
              productInCartList,
            ]),
          },
        });
      setLocalKey("disableRemotePrint", "false");
      toast.info(`${promotionInfo?.title} Promotion found!`);
    }
  };

  useEffect(() => {
    let cartItemLength: any = cartItems.filter(
      (item: any) => item?.modifier?.length < 1 && item?.quantity > 0,
    );
    if (cartItemLength?.length) handlePromoItem();
  }, [cartItems]);

  const handleModifierClick = (item: any) => {
    if (!item) {
      toast.info("Please select non-empty modifiers");
    } else {
      let selectedItem: any = cartItems?.find(
        (x: any) => x?.isSelected === true,
      );
      let prevModifierList: any = selectedItem?.modifier;
      const previousObject: any = prevModifierList?.find(
        (x: any) => x?.id === item?.id,
      );
      if (selectedItem) {
        if (previousObject) {
          let productInCartList: any = cartItems;
          let perviousModifierItemList: any = prevModifierList;
          let itemIndex: any = prevModifierList.findIndex(
            (obj: any) => obj.id === item?.id,
          );
          perviousModifierItemList[itemIndex].quantity =
            parseInt(previousObject.quantity) >= 0
              ? parseInt(previousObject.quantity) + 1
              : 1;
          perviousModifierItemList[itemIndex].total =
            item?.finalPrice > 0
              ? parseFloat(item?.finalPrice) + parseFloat(previousObject.total)
              : 0;
          perviousModifierItemList[itemIndex].tax =
            parseFloat(item?.taxAmount) * parseFloat(previousObject.quantity);

          perviousModifierItemList[itemIndex].isDeleted = false;
          perviousModifierItemList[itemIndex].isUpdated = true;

          selectedItem.modifier = perviousModifierItemList;
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true,
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          setLocalKey("disableRemotePrint", "false");
        } else {
          let productInCartList: any = cartItems;
          prevModifierList.push({
            id: item?.id,
            pk: item?.pk,
            title: item?.title,
            price: item?.finalPrice ?? 0,
            quantity: 1,
            total: item?.finalPrice ?? 0,
            tax: item?.taxAmount,
            taxRate: {
              rate: item?.tax?.rate ?? 0,
              isIncluded: item?.tax?.isIncluded,
            },
            isDeleted: false,
            randId: Math.random().toString(36).substr(2, 5),
          });
          let selectedItemIndex: any = cartItems.findIndex(
            (x: any) => x.isSelected === true,
          );
          productInCartList[selectedItemIndex].isModified = true;
          productInCartList[selectedItemIndex] = selectedItem;
          cache.writeQuery({
            query: GET_CART_ITEMS,
            data: {
              cartItems: cartItemsVar([...productInCartList]),
            },
          });
          setLocalKey("disableRemotePrint", "false");
        }
      } else {
        if (item) {
          toast.info("Please select product");
        } else {
          toast.info("Please select non-empty modifiers");
        }
      }
    }
  };

  const handleProductWithModCategory = (item: any) => {
    setOpenProductWithModCategory(true);
    setProductWithModCategoryList(item);
    item?.compound?.length > 0 &&
      item?.compound?.map((promoItem: any, promoIndex: number) => {
        const nestedTitle = promoItem?.compound.map(
          (nestedItem: any, nestedIndex: number) => nestedItem?.title,
        );
        setPromProducts(nestedTitle);
        setPromotionInfo(promoItem);
      });
  };

  const handleProductWithModCategoryTrue = (item: any) => {
    setOpenProductWithModCategoryTrue(true);
    setProductWithModCategoryList(item);

    item?.compound?.length > 0 &&
      item?.compound?.map((promoItem: any, promoIndex: number) => {
        const nestedTitle = promoItem?.compound.map(
          (nestedItem: any, nestedIndex: number) => nestedItem?.title,
        );
        setPromProducts(nestedTitle);
        setPromotionInfo(promoItem);
      });
  };

  //Change Context Category Name
  const [changePopup, setChangePopup] = useState<boolean>(false);
  const [changeModifierPopup, setChangeModifierPopup] =
    useState<boolean>(false);
  const [productSingle, setProductSingle] = useState();
  const [addProductPopup, setAddProductPopup] = useState<boolean>(false);
  const tableItems: any = useReactiveVar(tableItemsVar);
  const orderType: any = useReactiveVar(orderTypeVar);
  const [openMessagePopup, setOpenMessagePopup] = useState<boolean>(false);
  const [editProductPopup, setEditProductPopup] = useState<boolean>(false);
  const [editModifierPopup, setEditModifierPopup] = useState<boolean>(false);
  const [addModifierPopup, setAddModifierPopup] = useState<boolean>(false);
  const [stockAlertPopup, setStockAlertPopup] = useState<boolean>(false);

  const [input, setInput] = useState<string>("");
  const [productId, setProductId] = useState<string>("");
  const [itemPk, setItemPk] = useState<string>("");


  //Handle Change Product
  const [updatePosScreenProducts] = useMutation(UPDATE_POS_SCREEN_PRODUCT, {
    refetchQueries: [
      {
        query: usePosScreenProductByCategory,
        variables: {
          offset: 0,
          posScreenCategory: productByCatId,
        },
      },
    ],
  });
  const handleProductChange = async (proPk: string) => {
    const response = await updatePosScreenProducts({
      variables: {
        id: productId,
        product: proPk,
      },
    });
    if (response?.data?.assignProduct?.success) {
      setChangePopup(false);
      setChangeModifierPopup(false);
      await handleProductRefetch();
      toast.success("Product Changed");
      setInput("");
    }
  };
  console.log(productSingleValue, "productSingleValue")
  const cat = singleProductData?.product?.category?.edges?.map((catVal: any) => (catVal?.node?.pk))
  const modCat = singleProductData?.product?.modifierCategories?.edges?.map((catVal: any) => (catVal?.node?.category?.pk))
  const printer = singleProductData?.product?.printer?.edges?.map((catVal: any) => (catVal?.node?.pk))
  const unitValue = singleProductData?.product?.unit?.edges?.map((catVal: any) => (catVal?.node?.pk))
  const taxValue = singleProductData?.product?.taxType?.edges?.map((catVal: any) => (catVal?.node?.pk))
  console.log(modCat, cat)
  const { register, formState: { errors }, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      title: "",
      category: [],
      unit: unitDropdown[0]?.id,
      isActive: true,
      sellingPrice: "",
      costPrice: "",
      taxType: taxTypeDropdown[0]?.id,
      barcode: "",
      plu: "",
      printer: [],
      isWeightable: false,
      modifierCategories: [],
      isModifierCategoryGrid: false,
    }
  })
  useEffect(() => {
    if (singleProductData?.product && (editProductPopup || editModifierPopup)) {
      setValue("title", singleProductData?.product?.title);
      setValue("category", cat)
      setValue("unit", unitValue)
      setValue("isActive", singleProductData?.product?.isActive)
      setValue("sellingPrice", singleProductData?.product?.sellingPrice)
      setValue("costPrice", singleProductData?.product?.costPrice)
      setValue("taxType", taxValue)
      setValue("barcode", singleProductData?.product?.barcodeContent)
      setValue("plu", singleProductData?.product?.plu)
      setValue("printer", printer)
      setValue("isWeightable", singleProductData?.product?.isWeightable)
      setValue("modifierCategories", modCat)
      setValue("isModifierCategoryGrid", singleProductData?.product?.isModifierCategoryGrid)
      // Similarly, you can update other form fields as needed
    } else {
      setValue("title", "");
      setValue("category", [])
      setValue("unit", unitDropdown[0]?.id)
      setValue("isActive", false)
      setValue("sellingPrice", "")
      setValue("costPrice", "")
      setValue("taxType", taxTypeDropdown[0]?.id)
      setValue("barcode", "")
      setValue("plu", "")
      setValue("printer", [])
      setValue("isWeightable", false)
      setValue("modifierCategories", [])
      setValue("isModifierCategoryGrid", false)

    }
  }, [singleProductData, setValue, editProductPopup]);
  //Handle Quick Add Product

  const [productCreate, { loading: loadingCreateProduct }] = useMutation(
    POS_SCREEN_CREATE_PRODUCT,
    {
      refetchQueries: [
        {
          query: usePosScreenProductByCategory,
          variables: {
            offset: 0,
            posScreenCategory: productByCatId,
          },
        },
      ],
    },
  );
  const [productUpdate, { loading: loadingUpdateProduct }] = useMutation(
    POS_SCREEN_UPDATE_PRODUCT,
    {
      refetchQueries: [
        {
          query: usePosScreenProductByCategory,
          variables: {
            offset: 0,
            posScreenCategory: productByCatId,
          },
        },
      ],
    },
  );
  const handleUpdateProduct = async (values: any, props: any) => {
    setOpenContextPopup(false);
    const response = await productUpdate({
      variables: {
        id: itemPk.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType,
        isModifier: false,
        isWeightable: values?.isWeightable,
        printOrder: null,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer ?? [],
        modifierCategories: values?.modifierCategories ?? [],
        image: null,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcode,
        plu: values?.plu,
        shortDesc: "",
      },
    });
    if (response?.data?.updateProduct?.success) {
      toast.success("Product Updated successfully");
      setEditProductPopup(false);
    } else {
    }
  }
  const handleCreateProduct = async (values: any, props: any) => {
    setOpenContextPopup(false);
    const response = await productCreate({
      variables: {
        posScreenProductId: productId.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType,
        isModifier: false,
        isWeightable: values?.isWeightable,
        printOrder: null,
        isModifierCategoryGrid: values?.isModifierCategoryGrid,
        isOpenPriceProduct: values?.isOpenPriceProduct,
        printers: values?.printer ?? [],
        modifierCategories: values?.modifierCategories ?? [],
        image: null,
        sellingPrice: values?.sellingPrice,
        costPrice: values?.costPrice,
        barcodeContent: values?.barcode,
        plu: values?.plu,
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Product Created successfully");
      setAddProductPopup(false);
    } else {
    }
  };
  const handleCreateModifier = async (values: any, props: any) => {
    setOpenContextPopup(false);
    const response = await productCreate({
      variables: {
        posScreenProductId: productId.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType,
        isModifier: true,
        isWeightable: false,
        printOrder: null,
        isModifierCategoryGrid: false,
        isOpenPriceProduct: false,
        printers: [],
        modifierCategories: [],
        image: null,
        sellingPrice: values?.price,
        costPrice: "",
        barcodeContent: "",
        plu: "",
        shortDesc: "",
      },
    });
    if (response?.data?.createProduct?.success) {
      toast.success("Modifier Created successfully");
      setAddModifierPopup(false);
    } else {

    }
  };
  const handleUpdateModifier = async (values: any, props: any) => {
    setOpenContextPopup(false);
    const response = await productUpdate({
      variables: {
        id: itemPk.toString(),
        category: values?.category,
        title: values?.title,
        unit: values?.unit,
        isActive: values?.isActive,
        tax: values?.taxType,
        isModifier: true,
        isWeightable: false,
        printOrder: null,
        isModifierCategoryGrid: false,
        isOpenPriceProduct: false,
        printers: [],
        modifierCategories: [],
        image: null,
        sellingPrice: values?.price,
        costPrice: "",
        barcodeContent: "",
        plu: "",
        shortDesc: "",
      },
    });
    if (response?.data?.updateProduct?.success) {
      toast.success("Modifier Updated successfully");
      setEditModifierPopup(false);
    } else {

    }
  };

  const [toggleSoldOutProduct, { loading: toggleSoldoutProductLoading }] =
    useMutation(POS_PRODUCT_SOLD_OUT, {
      refetchQueries: [
        {
          query: usePosScreenProductByCategory,
          variables: {
            offset: 0,
            posScreenCategory: productByCatId,
          },
        },
      ],
    });
  const handleQuickAddProduct = (e: any) => {
    e.stopPropagation();
    setAddProductPopup(true);
    setIsModifier(false);
    setOpenContextPopup(false);
  };
  const handleQuickAddModifier = (e: any) => {
    e.stopPropagation();
    setAddModifierPopup(true);
    setIsModifier(true);
    setOpenContextPopup(false);
  };

  const handleChangeButton = (e: any) => {
    e.stopPropagation();
    setChangePopup(true);
    setOpenContextPopup(false);
  };
  const handleChangeModifierButton = (e: any) => {
    e.stopPropagation();
    setChangeModifierPopup(true);
    setOpenContextPopup(false);
  };

  //Handle Remove Assign Product
  const [removeAssignedProduct] = useMutation(REMOVE_ASSIGNED_PRODUCT, {
    refetchQueries: [
      {
        query: usePosScreenProductByCategory,
        variables: {
          offset: 0,
          posScreenCategory: productByCatId,
        },
      },
    ],
  });
  const handleRemoveAssignProduct = async (e: any) => {
    e.stopPropagation();
    const response = await removeAssignedProduct({
      variables: {
        id: productId,
      },
    });
    if (response?.data?.removeAssignedProduct?.success) {
      toast.success("Product Removed successfully");
      await handleProductRefetch();
    }
    setOpenContextPopup(false);
  };

  const handleEditProduct = async (e: any, product: any) => {
    console.log(product, "product")
    e.stopPropagation();
    setProductSingleValue(product)
    if (cartItems?.length > 0 || tableItems?.id || orderType?.pk) {
      setOpenMessagePopup(true)
    }
    else {
      if (!product) {
        toast.info("Product/modifier is not assigned");
        setOpenContextPopup(false);
        return;
      }
      if (!product.isModifier) {
        setEditProductPopup(true);
      } else {
        setEditModifierPopup(true)
      }
    }
    setOpenContextPopup(false);

  };

  const handleClickContext = (e: any, item: any) => {
    if (e.type === "click") {
    } else if (e.type === "contextmenu") {
      setItemPk(item?.node?.product?.pk)
      setItemId(item?.node?.product?.id)
      setProductId(item?.node?.pk);
      setOpenContextPopup(true);
    }
  };
  //Product Lists
  const { data: productsLists } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      first: 10,
      search: input,
      isOpenProduct: false,
      isModifier: false,

    },
  });
  const handleSoldOut = async (e: any, item: any) => {
    e.stopPropagation();
    const response = await toggleSoldOutProduct({
      variables: {
        objectId: item?.node?.product?.pk,
      },
    });
    if (response?.data?.toggleProduct?.success) {
      toast.success(response?.data?.toggleProduct?.message);
      setOpenContextPopup(false);
    } else {
      toast.error(response?.data?.toggleProduct?.message);
    }
  };

  //Modifier Lists
  const { data: modifIerLists } = useQuery(POS_SCREEN_PRODUCT, {
    variables: {
      first: 10,
      title_Icontains: input,
      isModifier: true,
    },
  });
  const [getSingleProduct] = useLazyQuery(usePosScreenProductByCategoryWithIds);
  const handleProductClick = async (item: any) => {
    console.log(item, "item")
    setLocalKey("productId", item?.node?.id);
    const response = await getSingleProduct({
      variables: {
        id: item?.node?.id,
      },
    });
    if (!response?.data?.posScreenProduct?.product) {
      return;
    }
    if (!response?.data?.posScreenProduct?.product?.isActive) {
      toast.error("Product is Sold Out");
      return;
    }
    const product = response?.data?.posScreenProduct?.product;
    const stock = product?.stock;
    const isStockMaintain =
      settingsData?.basicWebsiteConfigurations?.isStockMaintain;

    let action;

    if (
      stock?.threshold <= parseFloat(stock?.availableQuantity) &&
      isStockMaintain
    ) {
      action = "belowThreshold";
    } else if (
      stock?.threshold > parseFloat(stock?.availableQuantity) &&
      isStockMaintain
    ) {
      action = "aboveThreshold";
    } else {
      action = "default";
    }
    switch (action) {
      case "belowThreshold":
        if (!product?.isModifier) {
          if (
            product?.isModifierCategoryGrid &&
            product?.modifierCategories?.edges?.length
          ) {
            handleProductWithModCategoryTrue(product);
          } else if (product?.modifierCategories?.edges?.length) {
            handleProductWithModCategory(product);
          } else {
            handleClick(product);
          }
        } else {
          handleModifierClick(product);
        }
        break;

      case "aboveThreshold":
        setStockAlertPopup(true);
        if (!product?.isModifier) {
          if (
            product?.isModifierCategoryGrid &&
            product?.modifierCategories?.edges?.length
          ) {
            handleProductWithModCategoryTrue(product);
          } else if (product?.modifierCategories?.edges?.length) {
            handleProductWithModCategory(product);
          } else {
            handleClick(product);
          }
        } else {
          handleModifierClick(product);
        }
        break;

      case "default":
        if (!product?.isModifier) {
          if (
            product?.isModifierCategoryGrid &&
            product?.modifierCategories?.edges?.length
          ) {
            handleProductWithModCategoryTrue(product);
          } else if (product?.modifierCategories?.edges?.length) {
            handleProductWithModCategory(product);
          } else {
            handleClick(product);
          }
        } else {
          handleModifierClick(product);
        }
        break;

      default:
        break;
    }
  };
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);

  const handleQuickAddColor = (e: any) => {
    e.stopPropagation();
    setDisplayColorPicker(true);
    setOpenContextPopup(false);
  };
  const handleWindowMouseMove = (event: any) => {
    setCoords({
      x: event.clientX,
      y: event.clientY > 400 ? event.clientY - 280 : event.clientY,
    });
  };
  useEffect(() => {
    window.addEventListener("contextmenu", handleWindowMouseMove);
    return () => {
      window.removeEventListener("contextmenu", handleWindowMouseMove);
    };
  }, []);

  return (
    <>
      {openContextPopup ? (
        <div
          className="w-full h-full absolute z-10"
          onClick={() => setOpenContextPopup(false)}
        ></div>
      ) : null}
      {productsData?.posScreenProducts ? (
        <div
          className={`grid h-full px-4 grid-cols-${noOfColumns} grid-rows-7 gap-2`}
        >
          {productsData?.posScreenProducts?.edges?.map(
            (item: any, index: number) => (
              <React.Fragment key={index}>
                <div
                  className={` relative h-[80px] cursor-pointer rounded-lg border-2 border-solid  border-white `}
                  style={{
                    background: item?.node?.product?.title
                      ? item?.node?.color ??
                      `${!item?.node?.product?.isModifier
                        ? "#4A90E2"
                        : "#8B6BA6"
                      }`
                      : "#F3F4F6",
                  }}
                  onClick={() => {
                    handleProductClick(item);
                    setProductSingle(item?.node?.id);
                  }}
                  onContextMenu={(event: any) =>
                    handleClickContext(event, item)
                  }
                >
                  <ProductCard item={item} />
                  {item?.node?.pk === productId && openContextPopup && (
                    <div
                      className={
                        "w-full fixed p-1 cursor-pointer  bg-[#FFFFFF]  rounded-lg z-10"
                      }
                      style={{ width: "150px", top: `${coords?.y}px` }}
                    >
                      <div
                        onClick={(e) => handleChangeButton(e)}
                        className="hover:bg-[#4338CA]/50 pl-1  my-4"
                      >
                        Change Product
                      </div>
                      <div
                        onClick={(e) =>
                          handleEditProduct(e, item?.node?.product)
                        }
                        className="hover:bg-[#4338CA]/50 pl-1  my-4"
                      >
                        Edit
                      </div>
                      <div
                        onClick={(e) => handleChangeModifierButton(e)}
                        className="hover:bg-[#4338CA]/50 pl-1   my-4"
                      >
                        Change Modifier
                      </div>
                      <div
                        onClick={(e) => handleQuickAddProduct(e)}
                        className="hover:bg-[#4338CA]/50 pl-1  my-4"
                      >
                        Add Product
                      </div>
                      <div
                        onClick={(e) => handleQuickAddModifier(e)}
                        className="hover:bg-[#4338CA]/50  pl-1 my-4"
                      >
                        Add Modifier
                      </div>
                      <div
                        onClick={(e) => handleRemoveAssignProduct(e)}
                        className="hover:bg-[#4338CA]/50 pl-1 my-4"
                      >
                        Remove
                      </div>
                      <div
                        onClick={(e) => handleQuickAddColor(e)}
                        className="hover:bg-[#4338CA]/50 pl-1 my-4 "
                      >
                        Add Color
                      </div>
                      <div
                        onClick={(e) => handleSoldOut(e, item)}
                        className="hover:bg-[#4338CA]/50 pl-1 my-4 "
                      >
                        Toggle Sold Out
                      </div>
                    </div>
                  )}
                </div>
              </React.Fragment>
            ),
          )}
          <Modal
            title={"Select Modifier"}
            open={openProductWithModCategory}
            setOpen={setOpenProductWithModCategory}
            size="max-w-7xl"
            children={
              <PosScreenProWithModCat
                item={productWithModCategoryList}
                setOpen={setOpenProductWithModCategory}
                open={openProductWithModCategory}
                productSingle={productSingle}
              />
            }
          />

          <Modal
            title={"Select Modifier"}
            open={openProductWithModCategoryTrue}
            setOpen={setOpenProductWithModCategoryTrue}
            size="max-w-7xl"
            children={
              <PosScreenProWithModCatTrue
                item={productWithModCategoryList}
                setOpen={setOpenProductWithModCategoryTrue}
                open={openProductWithModCategoryTrue}
              />
            }
          />
        </div>
      ) : (
        <div
          className={`grid h-full px-4 grid-cols-${noOfColumns} grid-rows-7 gap-2 border-2`}
        >
          {productsData?.products?.edges?.map((item: any, index: number) => (
            <React.Fragment key={index}>
              <div
                className={
                  "relative cursor-pointer border-2 border-solid border-gray-300 rounded-lg "
                }
                style={{ background: item?.node?.color ?? "#F3F4F6" }}
                onClick={() => handleProductClick(item)}
                onMouseLeave={() => setOpenContextPopup(false)}
                onContextMenu={(event: any) => handleClickContext(event, item)}
              >
                {item?.node?.product?.media[0]?.media ? (
                  <>
                    <img
                      src={item?.node?.product?.media[0]?.media}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                    <span
                      title={item?.node?.product?.title}
                      className={`${!item?.node?.product?.isModifier ? "" : "text-red-500"
                        } textWithTwoLine absolute top-[20%] w-full bg-gray-200  text-left font-bold px-1`}
                    >
                      {item?.node?.product?.title}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      title={item?.node?.product?.title}
                      className={`${!item?.node?.product?.isModifier ? "" : "text-red-500"
                        } textWithTwoLine absolute top-[30%] w-full bg-gray-200  text-left p-2`}
                    >
                      {item?.node?.product?.title}
                    </span>
                  </>
                )}
                {item?.node?.pk === productId && openContextPopup && (
                  <div
                    className={
                      "w-full absolute cursor-pointer right-0 top-[80%] bg-[#FFFFFF] p-1 rounded-md z-10"
                    }
                  >
                    <div
                      onClick={(e) => handleChangeButton(e)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Change Product
                    </div>
                    <div
                      onClick={(e) => handleEditProduct(e, item?.node?.product)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Edit
                    </div>
                    <div
                      onClick={(e) => handleChangeModifierButton(e)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Change Modifier
                    </div>
                    <div
                      onClick={(e) => handleQuickAddProduct(e)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Add Product
                    </div>
                    <div
                      onClick={(e) => handleQuickAddModifier(e)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Add Modifier
                    </div>
                    <div
                      onClick={(e) => handleRemoveAssignProduct(e)}
                      className="hover:bg-[#4338CA]/50 pl-1 my-4"
                    >
                      Remove
                    </div>
                    <div
                      onClick={(e) => handleQuickAddColor(e)}
                      className="hover:bg-[#4338CA]/50 pl-1"
                    >
                      Add Color
                    </div>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
          <Modal
            title={"Add Product"}
            open={openProductWithModCategory}
            setOpen={setOpenProductWithModCategory}
            children={
              <PosScreenProWithModCat
                item={productWithModCategoryList}
                setOpen={setOpenProductWithModCategory}
                open={openProductWithModCategory}
                productSingle={productSingle}
              />
            }
          />
        </div>
      )}
      <Modal title={"Change Item"} open={changePopup} setOpen={setChangePopup}>
        <ChangeModalForm
          input={input}
          setInput={setInput}
          name={"searchProduct"}
          children={
            <ul className="block w-full text-left p-1 ml-1 h-[100px] overflow-y-scroll shadow-lg">
              {productsLists?.products?.edges?.map(
                (item: any, index: number) => (
                  <li
                    key={(index)}
                    className="hover:bg-[#4338CA] hover:text-white cursor-pointer"
                    onClick={() => handleProductChange(item?.node?.pk)}
                  >
                    {item?.node?.title}
                  </li>
                ),
              )}
            </ul>
          }
        />
      </Modal>
      <Modal
        title={"Change Item"}
        open={changeModifierPopup}
        setOpen={setChangeModifierPopup}
      >
        <ChangeModalForm
          input={input}
          setInput={setInput}
          name={"searchProduct"}
          children={
            <ul className="block w-full text-left p-1 ml-1 h-[100px] overflow-y-scroll shadow-lg">
              {modifIerLists?.products?.edges?.map(
                (item: any, index: number) => (
                  <li
                    key={index}
                    className="hover:bg-[#4338CA] hover:text-white cursor-pointer"
                    onClick={() => handleProductChange(item?.node?.pk)}
                  >
                    {item?.node?.title}
                  </li>
                ),
              )}
            </ul>
          }
        />
      </Modal>


      <Modal
        title={"Stock Alert"}
        open={stockAlertPopup}
        setOpen={setStockAlertPopup}
        size="max-w-sm"
        children={
          <>
            <p>Quantity is less than threshold value &#9785;</p>
          </>
        }
      />
      <Modal
        title={"Add Quantity"}
        open={isWeightableProduct}
        setOpen={setIsWeightableProduct}
        size="max-w-sm"
      >
        <WeightableProductQuantityForm
          item={item}
          cartItems={cartItems}
          setIsWeightableProduct={setIsWeightableProduct}
        />
      </Modal>
      <Modal open={editProductPopup} setOpen={setEditProductPopup} children={
        <>
          <ProductForm
            register={register}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            categoryDropdown={categoryDropdown}
            unitDropdown={unitDropdown}
            taxTypeDropdown={taxTypeDropdown}
            printerListsDropdown={printerListsDropdown}
            modifierCategoryDropdown={modifierCategoryDropdown} />

          <div className="flex flex-wrap justify-center items-center">
            <PrimaryButton
              text={"Update"}
              onClick={handleSubmit(handleUpdateProduct)}
              loading={loadingUpdateProduct ? true : false}
              disable={loadingUpdateProduct}
            />
          </div>
        </>
      } title={"Edit Product"} />
      <Modal open={addProductPopup} setOpen={setAddProductPopup} children={
        <>
          <ProductForm
            register={register}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            categoryDropdown={categoryDropdown}
            unitDropdown={unitDropdown}
            taxTypeDropdown={taxTypeDropdown}
            printerListsDropdown={printerListsDropdown}
            modifierCategoryDropdown={modifierCategoryDropdown}
          />

          <div className="flex flex-wrap justify-center items-center">
            <PrimaryButton
              text={"Submit"}
              onClick={handleSubmit(handleCreateProduct)}
              loading={loadingCreateProduct ? true : false}
              disable={loadingCreateProduct}
            />
          </div>
        </>
      } title={"Add Product"} />
      <Modal open={editModifierPopup} setOpen={setEditModifierPopup} children={
        <>
          <ModifierForm
            register={register}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            categoryDropdown={categoryDropdown}
            unitDropdown={unitDropdown}
            taxTypeDropdown={taxTypeDropdown}
          />

          <div className="flex flex-wrap justify-center items-center">
            <PrimaryButton
              text={"Submit"}
              onClick={handleSubmit(handleUpdateModifier)}
              loading={loadingUpdateProduct ? true : false}
              disable={loadingUpdateProduct}
            />
          </div>
        </>
      } title={"Edit Modifier"} />
      <Modal open={addModifierPopup} setOpen={setAddModifierPopup} children={
        <>
          <ModifierForm
            register={register}
            getValues={getValues}
            errors={errors}
            setValue={setValue}
            categoryDropdown={categoryDropdown}
            unitDropdown={unitDropdown}
            taxTypeDropdown={taxTypeDropdown}
          />

          <div className="flex flex-wrap justify-center items-center">
            <PrimaryButton
              text={"Submit"}
              onClick={handleSubmit(handleCreateModifier)}
              loading={loadingCreateProduct ? true : false}
              disable={loadingCreateProduct}
            />
          </div>
        </>
      } title={"Add Modifier"} />
      <Modal open={openMessagePopup} setOpen={setOpenMessagePopup} children={
        <>Please Clear PosScreen to Edit</>
      } title={"Items Present in Screen"} />
      <Modal
        title={"Add Price"}
        open={isOpenPriceProducts}
        setOpen={setIsOpenPriceProducts}
        size="max-w-sm"
      >

        <OpenPriceProductPriceForm
          item={item}
          cartItems={cartItems}
          setIsOpenPriceProducts={setIsOpenPriceProducts}
          scaleValues={scaleValues}
        />
      </Modal>

      <ColorPicker
        type="product"
        displayColorPicker={displayColorPicker}
        id={productId}
        productByCatId={productByCatId}
        setDisplayColorPicker={setDisplayColorPicker}
      />
    </>
  );
}
