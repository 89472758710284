import { useEffect, useState } from "react";

import ConfirmAlert from "../../../reusable/ConfirmAlert";
import Modal from "../../../reusable/Modal";

import DiscountForm from "./DiscountUI/DiscountForm";
import Pay from "./Pay/Pay";
import PosScreenNote from "./Note/PosScreenNote";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { POS_SCREEN_LAST_DOCKET } from "../../../../pages/PosScreen/queries";
import { QUERY_SETTINGS } from "../../../../pages/Settings/graphql";
import PayBillForm from "./Pay/PayBillForm";
import PosScreenButton from "../../../reusable/Buttons/PosScreenButton";
import Spinner from "../../../reusable/Spinner";
import AccessControl from "../../../../helpers/accessControl";
import {
  kitchenOrderItemId,
  noteCache,
  noteVar,
  permissionTypeCache,
  permissionTypeVar,
} from "../../../localstate/Cache";
import { GET_NOTE_ITEMS, GET_PERMISSION_TYPE } from "../../../localstate/Cart";
import { getLocalKey, setLocalKey } from "../../../../helpers/sessionKey";
import SuchargeForm from "./SuchargeUI/SuchargeForm";
import IndividualSurchargeForm from "./SuchargeUI/IndividualSurchargeForm";
import OverAllSurchargeForm from "./SuchargeUI/OverAllSurchargeForm";
import useHandleToggleSelectedTable from "../../../../customhooks/useHandleToggleSelectedTable";
import usePosScreenHandleClear from "../../../../customhooks/usePosScreenHandleClear";

type Props = {
  handleOrderProduct?: any;
  cartItems?: any;
  tableItems?: any;
  printKitchenBill?: any;
  setPrintKitchenBill?: any;
  holdCardItemData?: any;
  getHoldCardItem?: any;
  holdKitchenOrderItemPk?: any;
  handleHoldCard?: any;
  selectDiscountType?: any;
  setSelectDiscountType?: any;
  customerItems?: any;
  totalAmtCalculations?: any;
  noteItems?: any;
  KitchenCardItemData?: any;
  orderPrintItemsVarCache?: any;
  handleCancelCard?: any;
  loadingKitchenOrder?: boolean;
  loadingModifiedKitchenOrder?: boolean;
  permissionTypes: any;
  availablePointsCheckboxRef?: any;
  selectedCustomer?: any;
  disableRemotePrint?: any;
  setDisableRemotePrint?: any;
  RewardPointAmount?: any;
  setSelectSuchargeType?: any;
  selectSuchargeType?: any;
  setNote: any;
  selectedTerminal: any;
  chectked?: boolean;
  setChecked?: any;
};
export default function PosScreenQuickAccessTool(props: Props) {
  const {
    selectedTerminal,
    handleOrderProduct,
    RewardPointAmount,
    cartItems,
    KitchenCardItemData,
    handleHoldCard,
    selectDiscountType,
    selectSuchargeType,
    setSelectSuchargeType,
    setSelectDiscountType,
    tableItems,
    customerItems,
    totalAmtCalculations,
    noteItems,
    handleCancelCard,
    loadingKitchenOrder,
    loadingModifiedKitchenOrder,
    permissionTypes,
    availablePointsCheckboxRef,
    selectedCustomer,
    disableRemotePrint,
    setDisableRemotePrint,
    setNote,
    chectked,
    setChecked
  } = props;
  const kitchenOrdreItemId: any = useReactiveVar(kitchenOrderItemId);
  const [openHoldPopup, setOpenHoldPopup] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const { handleClearPosScreen } = usePosScreenHandleClear();
  const [openDiscountPopup, setOpenDiscountPopup] = useState(false);
  const [openSuchargePopup, setOpenSuchargePopup] = useState(false);

  const [openPayPopup, setOpenPayPopup] = useState(false);
  const [openPayKitchenPrintAlert, setOpenPayKitchenPrintAlert] =
    useState(false);

  const [openAddToCreditPopup, setOpenAddToCreditPopup] = useState(false);
  const [openNotePopup, setOpenNotePopup] = useState(false);
  const [openPayPrintBillPopup, setOpenPayPrintBillPopup] = useState(false);

  const { handleSelectTableToggle } = useHandleToggleSelectedTable();

  const [paymentOrderId, setPaymentOrderId] = useState("");

  const [enteredNumber, setEnteredNumber] = useState<number>(0);

  const [permPopup, setPermPopup] = useState(false);
  const [kitchenPrintAlertPopup, setKitchenPrintAlertPopup] = useState(false);
  const openPopupKitchenPrintDisable =
    selectedTerminal?.terminal?.terminalsetting?.compulsoryKitchenPrint;
  const [openBillPayPopup, setOpenBillPayPopup] = useState<boolean>(false);
  const handleKitchenClear = (e: any) => {
    setSelectDiscountType();
    handleClearPosScreen();
    e.stopPropagation();
    setKitchenPrintAlertPopup(false);
    setChecked(false);
  };
  let modItems: any = cartItems?.filter(
    (item: any) => item?.isModified === true,
  );
  //Clear cartItems List
  const handleClear = (e: any) => {
    if (modItems?.length) {
      setKitchenPrintAlertPopup(true);
    } else {
      setSelectSuchargeType();
      setSelectDiscountType();
      handleClearPosScreen()
      e.stopPropagation();
      setChecked(false);
    }
    if (tableItems?.pk) {
      handleSelectTableToggle(false, tableItems?.pk);
    }
  };
  const [getPrintLastDocket] = useLazyQuery(POS_SCREEN_LAST_DOCKET);
  const { data: basicSetting } = useQuery(QUERY_SETTINGS);

  const [isLoadingLastDocket, setIsLoadingLastDocket] = useState(false);
  //Electron
  const handleElectronPrintLastDocketReceipt = async (e: any) => {
    setIsLoadingLastDocket(true);
    e.stopPropagation();
    const { data: orderDetailsData } = await getPrintLastDocket({
      fetchPolicy: "network-only",
      nextFetchPolicy: "network-only",
    });
    setIsLoadingLastDocket(false);

  };
  const handleOrderCancel = () => {
    if (
      !!(
        kitchenOrdreItemId?.kitchenOrderPk?.id ||
        kitchenOrdreItemId?.kitchenCardItemId?.id
      )
    ) {
      handleCancelCard(
        kitchenOrdreItemId?.kitchenOrderPk?.pk ??
        kitchenOrdreItemId?.kitchenCardItemId?.pk,
      );
      setOpenHoldPopup(false);
    } else {
      setOpenHoldPopup(false);
    }
  };
  //Cart Items After Deleting Items
  let items: any = cartItems?.filter((item: any) => item?.isDeleted === false);
  const [permissionType, setPermissionType] = useState("");
  useEffect(() => {
    setPermissionType("");
    if (!openDiscountPopup || !openAddToCreditPopup) {
      permissionTypeCache.writeQuery({
        query: GET_PERMISSION_TYPE,
        data: {
          permissionTypes: permissionTypeVar(""),
        },
      });
    }
  }, [openDiscountPopup, openAddToCreditPopup, openCancelPopup]);
  const receiptPrintDefault = {
    netAmountForReceiptPrint: getLocalKey(`netAmount`) || false,
    amountAfterDiscountForReceiptPrint:
      getLocalKey(`amountAfterDiscount`) || false,
    taxAmountForReceiptPrint: getLocalKey(`taxAmount`) || false,
    serviceChargeForReceiptPrint: getLocalKey(`serviceCharge`) || false,
    grossAmountForReceiptPrint: getLocalKey(`grossAmount`) || false,
    surChargeForReceiptPrint: getLocalKey(`surCharge`) || false,
    eftPosChargeForReceiptPrint: getLocalKey(`eftposCharge`) || false,
  };
  const handleElectronPrintReceipts = (e: any) => {
    e.stopPropagation();

    noteCache.writeQuery({
      query: GET_NOTE_ITEMS,
      data: {
        noteItems: noteVar(""),
      },
    });
  };

  const handleRemotePay = () => {
    if (openPopupKitchenPrintDisable === true) {
      if (modItems?.length) {
        setOpenPayKitchenPrintAlert(true);
      } else {
        setOpenPayPopup(true);
      }
    } else {
      setOpenPayPopup(true);
    }
  };
  useEffect(() => {
    const disableRemoteKey = JSON.parse(
      getLocalKey("disableRemotePrint") || "false",
    );
    if (!disableRemoteKey) {
      setDisableRemotePrint(true);
    } else {
      setDisableRemotePrint(false);
    }
  }, [cartItems, customerItems, tableItems]);
  return (
    <>
      <div className="container pl-1 block">
        <div className="w-[100%] p-1 bg-[#ffffff] text-center mt-10px grid grid-rows-2 grid-cols-5 gap-3">
          <PosScreenButton
            disabled={items?.length}
            onClick={() => setOpenHoldPopup(true)}
            buttonTitle={"Hold Order"}
          />
          {permissionTypes === "can_void_transaction" ? (
            <PosScreenButton
              disabled={
                (kitchenOrdreItemId?.kitchenCardItemId ||
                  kitchenOrdreItemId?.kitchenOrderPk) &&
                !disableRemotePrint
              }
              onClick={() => setOpenCancelPopup(true)}
              buttonTitle={"Void Order"}
            />
          ) : (
            <AccessControl
              allowedPermissions={["can_void_transaction"]}
              renderNoAccess={
                <PosScreenButton
                  disabled={
                    (kitchenOrdreItemId?.kitchenCardItemId ||
                      kitchenOrdreItemId?.kitchenOrderPk) &&
                    !disableRemotePrint
                  }
                  onClick={() => {
                    setPermPopup(true);
                    setPermissionType("can_void_transaction");
                  }}
                  buttonTitle={"Void Order"}
                />
              }
              children={
                <PosScreenButton
                  disabled={
                    (kitchenOrdreItemId?.kitchenCardItemId ||
                      kitchenOrdreItemId?.kitchenOrderPk) &&
                    !disableRemotePrint
                  }
                  onClick={() => setOpenCancelPopup(true)}
                  buttonTitle={"Void Order"}
                />
              }
            />
          )}

          <PosScreenButton
            disabled={items?.length}
            onClick={handleRemotePay}
            buttonTitle={"Pay"}
          />
          {loadingKitchenOrder || loadingModifiedKitchenOrder ? (
            <Spinner />
          ) : (
            <PosScreenButton
              disabled={items?.length && disableRemotePrint}
              onClick={handleOrderProduct}
              buttonTitle={
                loadingKitchenOrder || loadingModifiedKitchenOrder ? (
                  <Spinner />
                ) : (
                  "Kitchen Print"
                )
              }
            />
          )}
          <PosScreenButton
            disabled={items?.length}
            onClick={(e: any) => handleClear(e)}
            buttonTitle={"Clear"}
          />
          {permissionTypes === "can_apply_discount" ? (
            <PosScreenButton
              disabled={items?.length}
              onClick={() => setOpenDiscountPopup(true)}
              buttonTitle={"Discount"}
            />
          ) : (
            <AccessControl
              allowedPermissions={["can_apply_discount"]}
              renderNoAccess={
                <PosScreenButton
                  disabled={items?.length}
                  onClick={() => {
                    setPermPopup(true);
                    setPermissionType("can_apply_discount");
                  }}
                  buttonTitle={"Discount"}
                />
              }
              children={
                <PosScreenButton
                  disabled={items?.length}
                  onClick={() => setOpenDiscountPopup(true)}
                  buttonTitle={"Discount"}
                />
              }
            />
          )}

          {isLoadingLastDocket ? (
            <Spinner />
          ) : (
            <button
              onClick={(e) => handleElectronPrintLastDocketReceipt(e)}
              className="flex bg-[#4338CA] items-center h-30px cursor-pointer p-8 mb-6px text-[12px] justify-center
                 rounded-lg text-white"
            >
              <span className="absolute w-[40px] z-100">Print Last Docket</span>
            </button>
          )}
          <PosScreenButton
            disabled={items?.length}
            onClick={() => setOpenNotePopup(true)}
            buttonTitle={"Note"}
          />
          <PosScreenButton
            disabled={items?.length}
            onClick={handleElectronPrintReceipts}
            buttonTitle={"Receipt Print"}
          />
          <PosScreenButton
            disabled={items?.length}
            onClick={() => setOpenSuchargePopup(true)}
            buttonTitle={"Sucharge"}
          />
        </div>
      </div>

      {/* Discount Form */}
      <Modal
        title={""}
        open={openDiscountPopup}
        setOpen={setOpenDiscountPopup}
        children={
          <DiscountForm
            selectDiscountType={selectDiscountType}
            setSelectDiscountType={setSelectDiscountType}
            open={openDiscountPopup}
            setOpen={setOpenDiscountPopup}
            totalAmtCalculations={totalAmtCalculations}
          />
        }
      />
      {/*Sucharge Form*/}
      <Modal
        title={""}
        open={openSuchargePopup}
        setOpen={setOpenSuchargePopup}
        children={
          <SuchargeForm
            selectSuchargeType={selectSuchargeType}
            setSelectSuchargeType={setSelectSuchargeType}
            open={openSuchargePopup}
            setOpen={setOpenSuchargePopup}
            totalAmtCalculations={totalAmtCalculations}
          />
        }
      />
      {/* Pay Form */}
      <Modal
        title={"Pay"}
        open={openPayPopup}
        setOpen={setOpenPayPopup}
        size="max-w-xl"
        children={
          <Pay
            cartItems={cartItems}
            enteredNumber={enteredNumber}
            setEnteredNumber={setEnteredNumber}
            setSelectSuchargeType={setSelectSuchargeType}
            openPayPopup={openPayPopup}
            setOpenPayPopup={setOpenPayPopup}
            tableItems={tableItems}
            customerItems={customerItems}
            totalAmtCalculations={totalAmtCalculations}
            noteItems={noteItems}
            openPayPrintBillPopup={openPayPrintBillPopup}
            setOpenPayPrintBillPopup={setOpenPayPrintBillPopup}
            paymentOrderId={paymentOrderId}
            setPaymentOrderId={setPaymentOrderId}
            setSelectDiscountType={setSelectDiscountType}
            basicSetting={basicSetting}
            receiptData={""}
            availablePointsCheckboxRef={availablePointsCheckboxRef}
            selectedCustomer={selectedCustomer}
            RewardPointAmount={RewardPointAmount}
            chectked={chectked}
            setChecked={setChecked}
          />
        }
      />

      {/* Split Bill Pay */}
      <Modal
        title={"Split Bill Pay"}
        open={openBillPayPopup}
        setOpen={setOpenBillPayPopup}
        size={"max-w-7xl"}
        children={
          <div className="w-full h-[80vh]">
            `'097'`
            {/* <SPlitBillPay
              cartItems={cartItems}
              open={openPayPopup}
              setOpen={setOpenPayPopup}
            /> */}
          </div>
        }
      />

      {/* Pay Bill Form */}
      <Modal
        title={"Print Bill"}
        open={openPayPrintBillPopup}
        setOpen={setOpenPayPrintBillPopup}
        children={
          <PayBillForm
            setOpen={setOpenPayPrintBillPopup}
            paymentOrderId={paymentOrderId}
          />
        }
      />
      {/* Hold Alert */}
      <ConfirmAlert
        title={"Alert!!"}
        subTitle={"Are you sure to hold this order??"}
        isOpen={openHoldPopup}
        onConfirm={handleHoldCard}
        notes={false}
        setIsOpen={setOpenHoldPopup}
      />
      <ConfirmAlert
        title={"Alert!!"}
        subTitle={"Are you sure to cancel this order??"}
        isOpen={openCancelPopup}
        onConfirm={() => handleOrderCancel()}
        setIsOpen={setOpenCancelPopup}
        setNote={setNote}
        notes={true}
      />

      {/* Add To Credit Alert */}

      {/* Note Form */}
      <Modal
        title={"Note"}
        open={openNotePopup}
        setOpen={setOpenNotePopup}
        children={
          <PosScreenNote
            setOpenNotePopup={setOpenNotePopup}
            noteItems={noteItems}
          />
        }
      />
      <Modal
        title={"Clear Alert !!"}
        open={kitchenPrintAlertPopup}
        setOpen={setKitchenPrintAlertPopup}
        children={
          <>
            <p className="p-3">Are You Sure You Want to Clear??</p>
            <button
              className="bg-primary text-white w-20 h-10 rounded mt-2"
              onClick={(e: any) => handleKitchenClear(e)}
            >
              Clear
            </button>
          </>
        }
      />
      <Modal
        title={"Kitchen Print Alert !!"}
        open={openPayKitchenPrintAlert}
        setOpen={setOpenPayKitchenPrintAlert}
        children={
          <>
            <p className="p-3">Please Make the Kitchen Print first!! </p>
            <p className="p-3">Compulsory Kitchen Print</p>
            <button
              className="bg-primary text-white w-32 h-10 rounded mt-2"
              onClick={() => {
                setOpenPayPopup(true);
                setOpenPayKitchenPrintAlert(false);
              }}
            >
              OpenAnyway
            </button>
          </>
        }
      />

      {/* Permission Grant Madal*/}

    </>
  );
}
