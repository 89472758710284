import { useQuery } from "@apollo/client";
import { MutableRefObject, useRef, useState } from "react";
import { POS_SCREEN_ORDERED_ITEMS } from "../../../../../pages/PosScreen/queries";
import {
  kitchenOrderItemId,
  kitchenOrdreCache,
  openTableCache,
  openTableVar,
} from "../../../../localstate/Cache";
import {
  GET_KITCHEN_CARD_ID,
  GET_ORDER_TYPE,
} from "../../../../localstate/Cart";
import { toast } from "react-toastify";
import { getLocalKey } from "../../../../../helpers/sessionKey";
import Spinner from "../../../../reusable/Spinner";

type Props = {
  setOpen?: any;
  paymentOrderId?: any;
};
const PayBillForm = (props: Props) => {
  const { setOpen, paymentOrderId } = props;
  const [printStart, setPrintStart] = useState(false);
  const { data: orderDetailsData } = useQuery(POS_SCREEN_ORDERED_ITEMS, {
    variables: {
      id: paymentOrderId,
    },
  });
  const handleAfterPrint = (e: any) => {
    e.stopPropagation();
    setOpen(false);
    const openPopupAfterPayment: any =
      getLocalKey(`openPopupAfterPayment`) || false;
    if (openPopupAfterPayment === "true") {
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(true),
        },
      });
    }
    return () => {
      kitchenOrdreCache.writeQuery({
        query: GET_KITCHEN_CARD_ID,
        data: {
          kitchenOrderItemId: kitchenOrderItemId({}),
        },
      });
    };
  };
  const componentRef: MutableRefObject<any> = useRef(null);

  const eftposChargeRequired: any = getLocalKey("eftposCharge");

  const handleBillPrint = () => {
    setPrintStart(true);
    
    setOpen(false);
    const openPopupAfterPayment: any =
      getLocalKey(`openPopupAfterPayment`) || false;
    if (openPopupAfterPayment === "true") {
      openTableCache.writeQuery({
        query: GET_ORDER_TYPE,
        data: {
          openTable: openTableVar(true),
        },
      });
    }
  };

  return (
    <div>
      <div className="flex justify-between mt-8">
        <button
          onClick={(e) => handleAfterPrint(e)}
          className="w-[48%] text-white bg-[#D32F2F] h-[50px]"
        >
          Cancel
        </button>
        {orderDetailsData ? (
          <button
            ref={componentRef}
            onClick={handleBillPrint}
            className="w-[48%] text-white h-[50px] bg-[#4338CA] rounded"
            disabled={printStart}
          >
            Print Bill
          </button>
        ) : (
          <button
            ref={componentRef}
            onClick={handleBillPrint}
            className="w-[48%] text-gray-900 h-[50px] bg-gray-100 rounded"
            disabled
          >
            Please Wait...
          </button>
        )}
      </div>
    </div>
  );
};

export default PayBillForm;
