import { useQuery } from "@apollo/client";
import Loadable from "react-loadable";
import DataLoadSpinner from "../../../components/reusable/Spinner/DataLoadSpinner";
import usePaginationHook from "../../../customhooks/usePaginationHook";
import { POS_KITCHEN_ORDER_LISTS } from "../../Orders/queries";

const PosScreenHoldCardComponent: any = Loadable({
  loader: () =>
  import("../../../components/PosScreenHoldCard/PosScreenHoldCard"),
  loading: DataLoadSpinner,
});

const PosScreenHoldCard = () => {
  const { offset, setOffset, pageSize, setPageSize } = usePaginationHook();

  const { loading, data: holdKitchenOrderData } = useQuery(
    POS_KITCHEN_ORDER_LISTS,
    {
      fetchPolicy: "no-cache",
      variables: {
        orderStatus: "hold",
        first: pageSize,
        offset: offset,
      },
    },
  );

  

  return (
    <div className="w-full">
      <PosScreenHoldCardComponent
        holdKitchenOrderData={holdKitchenOrderData?.kitchenOrders?.edges ?? []}
        isLoading={loading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        offset={offset}
        setOffset={setOffset}
      />
    </div>
  );
};

export default PosScreenHoldCard;
