import { gql } from "@apollo/client";

export const POS_KITCHEN_ORDER_LISTS = gql`
  query (
    $orderStatus: String
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $isBumped: Boolean
  ) {
    kitchenOrders(
      orderStatus: $orderStatus
      first: $first
      after: $after
      before: $before
      offset: $offset
      isBumped: $isBumped
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          orderStatus
          totalItems
          totalPrice
          getInvoiceNumber
          customer {
            pk
            id
            user {
              pk
              id
              fullName
            }
          }
          table {
            pk
            id
            title
            status
            isSelected
          }
          orderMethod {
            title
          }
          kitchenOrderItems {
            pk
            id
            quantity
            note
            isBumped
            modifier {
              id
              pk
              quantity
              product {
                id
                pk
                title
                finalPrice
                sellingPrice
                costPrice
              }
            }
            product {
              id
              pk
              title
              finalPrice
              sellingPrice
              costPrice
            }
          }
        }
      }
    }
  }
`;

export const POS_KITCHEN_ORDER_LIST = gql`
  query POS_KITCHEN_ORDER_LIST(
    $orderStatus: String
    $first: Int
    $after: String
    $before: String
    $offset: Int
    $isBumped: Boolean
  ) {
    kitchenOrders(
      orderStatus: $orderStatus
      first: $first
      after: $after
      before: $before
      offset: $offset
      isBumped: $isBumped
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          pk
          id
          created
          orderStatus
          totalItems
          totalPrice
          getInvoiceNumber
          customer {
            pk
            id
            user {
              pk
              id
              mobileNumber
              fullName
              profile {
                pk
                id
                email
              }
            }
          }
          table {
            pk
            id
            title
            capacity
            status
          }
          kitchenOrderItems {
            pk
            id
            quantity
            note
            isBumped
            modifier {
              id
              pk
              quantity
              product {
                id
                pk
                title
                finalPrice
                sellingPrice
                costPrice
              }
            }
            product {
              id
              pk
              title
            }
          }
        }
      }
    }
  }
`;
export const POS_SINGLE_KITCHEN_ORDER_LIST = gql`
  query ($id: ID!) {
    kitchenOrder(id: $id) {
      pk
      id
      created
      note
      customer {
        id
        pk
        user {
          id
          pk
          username
          email
          mobileNumber
          fullName
        }
      }
      table {
        id
        pk
        title
      }
      kitchenOrderItems {
        pk
        id
        quantity
        product {
          id
          pk
          title

          sellingPrice
          costPrice
        }
        modifier {
          id
          pk
          quantity
          product {
            id
            pk
            title

            costPrice
            sellingPrice
          }
        }
      }
    }
  }
`;
